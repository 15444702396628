import { Injectable } from '@angular/core';
import { Supplier } from 'common/types/supplier';
import { MyTranslateService } from 'common/services/translate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialog } from './confirm.dialog';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { ProjectFeatures } from 'common/types/types';

declare var window;

interface PopupOptions {
    id?: string;
    titleIcon?: string;
    title?: string;
    titleParams?: any;
    message?: string;
    messageParams?: any;
    cancelIcon?: string;
    cancelText?: string;
    okIcon?: string;
    okText?: string;
    extraIcon?: string;
    extraText?: string;
    width: number;
    disableClose?: boolean;
    handleCancel?: () => void;
    handleOk?: () => void;
    handleExtra?: () => void;
    cancelClickHandler?: () => void;
    okClickHandler?: () => void;
    extraClickHandler?: () => void;
}

@Injectable({ providedIn: 'root' })
export class PopupsController {
    private dialogs: Array<MatDialogRef<ConfirmDialog>> = [];

    constructor(private translate: MyTranslateService,
                private dialog: MatDialog,
                private store: Store<ApplicationState>) {}

    create(options: PopupOptions): void {
        const popup = Object.assign({}, options, {
            okText: options.okText ? options.okText : 'LABELS.OK',
            dialogs: this.dialogs
        });
        const dialogRef = this.dialog.open(ConfirmDialog, {
            panelClass: 'confirm-dialog',
            width: popup.width + 'px',
            data: popup,
            closeOnNavigation: false,
            disableClose: true
        });
        this.dialogs.push(dialogRef);
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe((button: string) => {
                this.handleDialogResult(button, popup);
                setTimeout(() => {
                    this.dialogs = this.dialogs.filter((dialog) => dialog.id !== dialogRef.id);
                }, 1);
            });
    }

    private handleDialogResult(button: string, popup: PopupOptions) {
        switch (button) {
            case 'OK':
                return popup.handleOk ? popup.handleOk() : null;
            case 'EXTRA':
                return popup.handleExtra ? popup.handleExtra() : null;
            default:
                return popup.handleCancel ? popup.handleCancel() : null;
        }
    }

    createWithOpCo(options: PopupOptions) {
        this.translate
            .getOnceWithOpCo([options.message, options.okText])
            .subscribe((translated: string) => {
                this.create(Object.assign({}, options, {
                    message: translated[options.message],
                    okText: translated[options.okText]
                }));
            });
    }

    createWithSupplier(options: PopupOptions, supplier: Supplier) {
        this.translate
            .getOnceWithSupplier(options.message, supplier)
            .subscribe((translatedMessage: string) => {
                this.create(Object.assign({}, options, {message: translatedMessage}));
            });
    }

    createWithAreaType(options: PopupOptions) {
        this.store
            .pipe(
                select((state) => state.settings.activeProject),
                map(project => project ? project.features : null),
                take(1)
            )
            .subscribe((features: ProjectFeatures) => {
                options.title = this.replaceAreaType(options.title, features);
                options.message = this.replaceAreaType(options.message, features);
                options.okText = this.replaceAreaType(options.okText, features);
                options.cancelText = this.replaceAreaType(options.cancelText, features);
                this.create(options);
            });
    }

    private replaceAreaType(text: string, features: any): string {
        if (features != null && features.sites) {
            return text.replace('.AREA_TYPE.', '.SITES.');
        } else if (features != null && features.docks) {
            return text.replace('.AREA_TYPE.', '.DOCKS.');
        } else {
            return text.replace('.AREA_TYPE.', '.ZONES.');
        }
    }

    hasOpenPopups(): boolean {
        return this.dialogs.length > 0;
    }
}
