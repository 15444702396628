import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Project } from 'common/types/types';
import { Storage } from 'common/services/storage';
import { BaseSidenav } from '../base-sidenav/base-sidenav.component';
import { cloneDeep } from 'lodash-es';
import { isSubcontractorOrganizationView, isSubcontractorProjectView } from '../../../view.utils';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { gaSendEvent } from '../../../services/window.service';
import '../../../../polyfills';

interface MenuItem {
    icon: string;
    label: string;
    routerLink: string;
    active?: boolean;
}

@Component({
    selector: 'subcontractor-sidenav',
    templateUrl: './subcontractor-sidenav.component.html',
    styleUrls: ['../base-sidenav/base-sidenav.component.scss']
})
export class SubcontractorSidenavComponent extends BaseSidenav {
    private adminOrganizationMenu: MenuItem[] = [
        {icon: 'fa fa-star', label: 'LABELS.PROJECTS', routerLink: '/subcontractor/projects'},
        {icon: 'fa fa-users', label: 'LABELS.MEMBERS', routerLink: '/subcontractor/members'}
    ];
    private userOrganizationMenu: MenuItem[] = [
        {icon: 'fa fa-star', label: 'LABELS.PROJECTS', routerLink: '/subcontractor/projects'}
    ];
    private adminProjectMenu: MenuItem[] = [
        {icon: 'fa fa-clipboard', label: 'LABELS.REQUESTS', routerLink: '/subcontractor/project/requests'},
        {icon: 'fa fa-list-ul', label: 'LABELS.ASSETS', routerLink: '/subcontractor/project/assets'},
        {icon: 'fa fa-map-marker', label: 'LABELS.LOCATIONS', routerLink: '/subcontractor/project/locations'}
    ];
    private userProjectMenu: MenuItem[] = [
        {icon: 'fa fa-list-ul', label: 'LABELS.ASSETS', routerLink: '/subcontractor/project/assets'},
        {icon: 'fa fa-map-marker', label: 'LABELS.LOCATIONS', routerLink: '/subcontractor/project/locations'}
    ];

    constructor(protected router: Router,
                protected storage: Storage) {
        super(router, storage);
    }

    protected initMenuLoader() {
        const navigationEnds$ = this.router.events
            .pipe(
                startWith(new NavigationEnd(0, this.router.url, null)),
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects ? event.urlAfterRedirects : event.url)
            );

        combineLatest(
            navigationEnds$,
            this.currentProject$,
        ).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(([url, project]) => {
            this.setView(url);
            this.setMenuItems(project);
            this.setActiveMenuItem(url);
        });
    }

    protected setView(url: string): void {
        if (url !== '/profile') {
            if (isSubcontractorOrganizationView(url)) {
                this.view = 'subcontractor-organization';
            } else if (isSubcontractorProjectView(url)) {
                this.view = 'subcontractor-project';
            } else {
                this.view = '';
            }
            this.storage.setItem('view', this.view);
        } else {
            this.view = this.storage.getItem('view');
        }
    }

    protected setMenuItems(project: Project): void {
        switch (this.view) {
            case 'subcontractor-organization':
                this.menuItems = this.hasRole(this.account, 'ROLE_SUBCONTRACTOR_ADMIN')
                    ? this.adminOrganizationMenu
                    : this.userOrganizationMenu;
                break;
            case 'subcontractor-project':
                this.menuItems = this.hasRole(this.account, 'ROLE_SUBCONTRACTOR_ADMIN')
                    ? this.menuItems = this.adminProjectMenu
                    : this.userProjectMenu;
                this.addPlanningMenuItem();
                this.addContactPageInMenuItems(project);
                break;
            default:
                this.menuItems = [];
                break;
        }
    }

    private addContactPageInMenuItems(project: Project) {
        if (project && (project.projectLeader != null || project.siteLeader != null)) {
            this.menuItems = cloneDeep(this.menuItems);
            this.menuItems.push({
                    icon: 'fa fa-address-book',
                    label: 'LABELS.CONTACT',
                    routerLink: '/subcontractor/project/contact'
                });
        }
    }

    private addPlanningMenuItem() {
        if (this.hasRole(this.account, 'ROLE_PLANNING_VIEWER')) {
            this.menuItems = cloneDeep(this.menuItems);
            this.menuItems = this.menuItems.filter((menuItem) => menuItem.label !== 'LABELS.LOCATIONS');
            this.menuItems.push({
                icon: 'fa fa-calendar',
                label: 'LABELS.PLANNING',
                routerLink: '/subcontractor/project/planning'
            });
            this.menuItems.push({
               icon: 'fa fa-map-marker',
                label: 'LABELS.LOCATIONS',
                routerLink: '/subcontractor/project/locations'
            });
        }
    }

    isSubcontractorAdmin() {
        return this.hasRole(this.account, 'ROLE_SUBCONTRACTOR_ADMIN');
    }

    projectSelectionChange(event) {
        this.emitCloseSideNav();
        if (event.value === 'all') {
            this.router.navigate(['/subcontractor/projects']);
        } else {
            this.projectSelected.emit(event.value);
            this.router.navigate(['/subcontractor/project/' + (this.isSubcontractorAdmin() ? 'requests' : 'assets')]);
        }
        gaSendEvent('SideNav', 'switch-project');
    }
}
