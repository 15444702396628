import { AbstractControl } from '@angular/forms';
import { cloneDeep, sortBy, isEqual, each, uniqBy, isMatch } from 'lodash-es';
import { Color } from '../types/color';
import * as moment from 'moment';

export function getPath(target): any[] {
    const path = [];
    let currentElem = target;
    while (currentElem) {
        path.push(currentElem);
        currentElem = currentElem.parentElement;
    }
    return path;
}

export function formatDate(date: string, format: string): string {
    return moment(date).format(format);
}

export function equalsTrimmed(obj1: string, obj2: string) {
    return isEqual(
        obj1 && obj1.trim() || '',
        obj2 && obj2.trim() || ''
    );
}

export function equals(obj1: any, obj2: any) {
    return isEqual(obj1, obj2);
}

export function match(obj1: any, obj2: any) {
    return isMatch(obj1, obj2);
}

export function removeArrayNulls(array: any[]): any {
    return array ? array.filter((item: any) => item != null) : array;
}

export function cloneAndRemoveNulls(object: any): any {
    return removeNulls(trimAllProperties(clone(object)));
}

export function clone(object: any): any {
    return cloneDeep(object);
}

export function removeNulls(object: any): any {
    removeNullsRecursively(object);
    return object;
}

function removeNullsRecursively(object: any) {
    for (const prop of Object.keys(object)) {
        if (prop === 'phoneNumber') {
            if (!object[prop] || !object[prop].nationalNumber) {
                delete object[prop];
            }
        } else if (object[prop] == null || object[prop] === ''
            || typeof object[prop] === 'object' && removeNullsRecursively(object[prop])) {
            delete object[prop];
        }
    }
    return Object.keys(object).length === 0;
}

export function trimAllProperties(object: any): any {
    for (const prop of Object.keys(object)) {
        if (object[prop]) {
            if (typeof object[prop] === 'string') {
                object[prop] = object[prop].trim();
            } else if (typeof object[prop] === 'object') {
                trimAllProperties(object[prop]);
            }
        }
    }
    return object;
}

export function markAsTouchedDeep(control: AbstractControl): void {
    control.markAsTouched();

    if (control.hasOwnProperty('controls')) {
        each((control as any).controls, (childControl) => {
            markAsTouchedDeep(childControl);
        });
    }
}

export function toLowerCase(object: string) {
    return object != null ? object.toLowerCase() : object;
}

export function hasElementInPathWithClass(path: any[], className: string): boolean {
    return path.find((el) => el.className && el.className.toString().includes(className)) != null;
}

export function hasElementInPathWithTagName(path: any[], tagName: string): boolean {
    return path.find((el) => el.tagName === tagName.toUpperCase()) != null;
}

export function getPageX(event) {
    return event.type && event.type.substr(0, 5) === 'touch' ? event.touches[0].pageX : event.pageX;
}

export function getPageY(event) {
    return event.type && event.type.substr(0, 5) === 'touch' ? event.touches[0].pageY : event.pageY;
}

export function sortAndMakeUnique(list: any[], by: any): any[] {
    return list
        && uniqBy(sortBy(list, by), by)
        || [];
}

export function formatCapacity(capacity: number): string {
    return capacity >= 1000
        ? ((capacity / 1000)
            .toFixed(2) + 'T')
            .replace('0T', 'T')
            .replace('.T', '.0T')
        : capacity + 'kg';
}

export function localeOrderBy(list: any[], properties: any | any[], orderPropertiesBy: any | any[]): any[] {
    return list && list.sort((item1: any, item2: any) => localeCompareProperties(item1, item2, properties, orderPropertiesBy));
}

export function localeCompareProperties(item1: any, item2: any, properties: any | any[], orderPropertiesBy?: any | any[]): number {
    const propsIsArray = Array.isArray(properties);
    properties = propsIsArray ? [...properties] : properties;
    orderPropertiesBy = Array.isArray(orderPropertiesBy) ? [...orderPropertiesBy] : orderPropertiesBy;
    const prop = propsIsArray ? properties.shift() : properties;
    const order = (orderPropertiesBy && Array.isArray(orderPropertiesBy) ? orderPropertiesBy.shift() : orderPropertiesBy) || 'asc';
    const value1 = getItemProperty(item1, prop);
    const value2 = getItemProperty(item2, prop);

    const result = localeCompare(value1, value2);
    return result === 0 && propsIsArray && properties.length > 0
        ? localeCompareProperties(item1, item2, properties, orderPropertiesBy)
        : result * (order === 'asc' ? 1 : -1);
}

export function getItemProperty(item: any, property: string | Function): any {
    if (typeof property === 'function') {
        return property(item);
    } else if (property.indexOf('.') === -1) {
        return item && item[property];
    } else {
        const parts = property.split('.');
        let result = item;
        while (result != null && parts.length > 0) {
            const part = parts.shift();
            result = result[part];
        }
        return result;
    }
}

export function localeCompare(value1: any, value2: any): number {
    if (value1 == null && value2 == null) {
        return 0;
    } else if (value1 == null) {
        return 1;
    } else if (value2 == null) {
        return -1;
    } else {
        return value1.toString().localeCompare(value2.toString(), undefined, {sensitivity: 'base', numeric: true});
    }
}

export function mapColorToString(color: Color): string {
    return color ? 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')' : null;
}
