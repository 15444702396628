import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApplicationState } from '../statemanagement/state/ApplicationState';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppCustomPreloader implements PreloadingStrategy {

    private authorities$ = this.store.pipe(
        select((state) => state.data.authentication
            && state.data.authentication.account
            && state.data.authentication.account.authorities),
        map((authorities: string[]) => authorities && authorities
            .filter((authority: string) => authority.includes('ROLE_', 0))),
        shareReplay(1)
    );

    constructor(private store: Store<ApplicationState>) {}

    preload(route: Route, load: () => Observable<any>): Observable<any> {
        if (route.data && route.data.noPreload) {
            return of(null);
        } else if (!route.data || !route.data.roles || route.data.roles.length === 0) {
            return load();
        } else {
            return this.authorities$
                .pipe(
                    map((authorities: string[]) => authorities && this.containsOne(authorities, route.data.roles)),
                    mergeMap((shouldLoad: boolean) => shouldLoad ? load() : of(null))
                );
        }
    }

    private containsOne(authorities: string[], roles: string[]): boolean {
        return authorities.find((authority: string) => roles.includes(authority)) != null;
    }
}
