import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { PlanningOrderLine, Request } from 'common/types/types';

export const ActionTypes = {
    PLANNING_SET_ALL: type<'DATA_PLANNING_SET_ALL'>('DATA_PLANNING_SET_ALL'),
    PLANNING_ADD: type<'DATA_PLANNING_ADD'>('DATA_PLANNING_ADD'),
    PLANNING_UPDATE: type<'DATA_PLANNING_UPDATE'>('DATA_PLANNING_UPDATE'),
    PLANNING_UPDATE_REQUEST: type<'DATA_PLANNING_UPDATE_REQUEST'>('DATA_PLANNING_UPDATE_REQUEST'),
    PLANNING_DELETE: type<'DATA_PLANNING_DELETE'>('DATA_PLANNING_DELETE'),
    PLANNING_DELETE_REQUEST: type<'DATA_PLANNING_DELETE_REQUEST'>('DATA_PLANNING_DELETE_REQUEST')
};

export class SetPlanning implements Action {
    type = ActionTypes.PLANNING_SET_ALL;
    payload: {planningItems: PlanningOrderLine[]};

    constructor(planningItems: PlanningOrderLine[]) {
        this.payload = {planningItems};
    }
}

export class AddToPlanning implements Action {
    type = ActionTypes.PLANNING_ADD;
    payload: {planningItem: PlanningOrderLine};

    constructor(planningItem: PlanningOrderLine) {
        this.payload = {planningItem};
    }
}

export class UpdatePlanning implements Action {
    type = ActionTypes.PLANNING_UPDATE;
    payload: {id: string, planningItem: PlanningOrderLine};

    constructor(id: string, planningItem: PlanningOrderLine) {
        this.payload = {id, planningItem};
    }
}

export class UpdatePlanningRequest implements Action {
    type = ActionTypes.PLANNING_UPDATE_REQUEST;
    payload: {planningItemId: string, request: any};

    constructor(planningItemId: string, request: any) {
        this.payload = {planningItemId, request};
    }
}

export class DeletePlanning implements Action {
    type = ActionTypes.PLANNING_DELETE;
    payload: {id: string};

    constructor(id: string) {
        this.payload = {id};
    }
}

export class DeletePlanningRequest implements Action {
    type = ActionTypes.PLANNING_DELETE_REQUEST;
    payload: {planningItemId: string, requestId: string};

    constructor(planningItemId: string, requestId: string) {
        this.payload = {planningItemId, requestId};
    }
}

export type Actions =
    SetPlanning
    | AddToPlanning
    | UpdatePlanning
    | UpdatePlanningRequest
    | DeletePlanning
    | DeletePlanningRequest;
