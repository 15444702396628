import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Project } from '../types/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class ProjectClosedGuard implements CanActivate {

    constructor(private router: Router,
                private store: Store<ApplicationState>) {
    }

    canActivate(): Observable<boolean> {
        return this.store.select((state) => state.settings.ready)
            .pipe(
                filter((ready) => ready),
                mergeMap(() => this.store.select((state) => state.settings.activeProject).pipe(map((project: Project) => {
                    if (project && project.status !== 'CLOSED') {
                        return true;
                    } else {
                        this.router.navigate(['/project/planning']);
                        return false;
                    }
                })))
            );
    }
}
