<div class="header">
    <ng-container *ngIf="(userOrganization$ | async) as organization">
        <ng-container *ngIf="view === 'subcontractor-organization'">
            <icon-or-bubble
                [details]="organization"
                [maxWidth]="collapsed || mobile ? 32 : 100"
                [border]="true"
            ></icon-or-bubble>
            <h1>{{ organization?.name }}</h1>
        </ng-container>
        <ng-container *ngIf="view === 'subcontractor-project'">
            <ng-container *ngVar="(currentProject$ | async) as project">
                <icon-or-bubble
                    [details]="project"
                    [maxWidth]="collapsed || mobile ? 32 : 100"
                    [border]="true"
                    (click)="navigate(['/subcontractor/projects'])"
                    style="cursor: pointer"
                ></icon-or-bubble>

                <h1 *ngIf="!mobile">{{ organization?.name }}</h1>

                <project-select
                    [mobile]="mobile"
                    [project]="project"
                    [recentProjects$]="recentList$"
                    (projectSelected)="projectSelectionChange($event)"
                ></project-select>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ul class="scroll">
    <li *ngFor="let item of menuItems"
        [class.active]="item.active"
        [matTooltip]="item.label | translate"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate([item.routerLink])"
    ><span class="icon"><i [class]="item.icon"></i></span><span class="text">{{ item.label | translate }}</span></li>
</ul>

<ul class="fixed" *ngIf="(settings$ | async) as settings">
    <li *ngIf="isSubcontractorAdmin()"
        [class.active]="isActive('/subcontractor/organization/settings') || isActive('/subcontractor/organization/edit')"
        [matTooltip]="'LABELS.ORGANIZATION_SETTINGS' | translate"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate([view === 'subcontractor-project' ? '/subcontractor/organization/settings' : '/subcontractor/organization/edit'])"
    >
        <span class="icon"><i class="fa fa-cog"></i></span><span class="text">{{ 'LABELS.ORGANIZATION_SETTINGS' | translate }}</span>
    </li>
    <li *ngIf="account"
        class="account"
        [class.active]="isActive('/profile')"
        [matTooltip]="account.firstName + ' ' + account.lastName"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate(['/profile'])"
    ><span class="icon"><icon-or-bubble
        [details]="account"
        [maxWidth]="30"
    ></icon-or-bubble></span><span class="text">{{ account.firstName }} {{ account.lastName }}</span></li>
    <li  [matTooltip]="'LABELS.FEEDBACK' | translate"
         [matTooltipDisabled]="!collapsed"
         matTooltipPosition="right"
         matTooltipClass="tooltip-sidenav"
         (click)="clickFeedback()">
        <span class="icon"><i class="fa fa-comments-o"></i></span><span class="text">{{ 'LABELS.HELP_FEEDBACK' | translate }}</span>
    </li>
</ul>

<i class="toggle-collapse fa fa-angle-double-left" (click)="emitToggleSideNav()" *ngIf="!mobile"></i>
