import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperDialog } from './image-cropper/image-cropper.dialog';
import { AddZoneDialog } from './add-zone/add-zone.dialog';
import { AddSubcontractorDialog } from './add-subcontractor/add-subcontractor.dialog';
import { AddMemberDialog } from './add-member/add-member.dialog';
import { CommonComponentsModule } from '../components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '../directives';
import { ColorPickerDialog } from './color-picker/color-picker.dialog';
import { AddSupplierDialog } from './add-supplier/add-supplier.dialog';
import { CommonPipesModule } from 'common/pipes';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CommonComponentsModule,
        CommonDirectivesModule,
        ImageCropperModule,
        CommonPipesModule
    ],
    declarations: [
        ImageCropperDialog,
        AddMemberDialog,
        AddSubcontractorDialog,
        AddZoneDialog,
        ColorPickerDialog,
        AddSupplierDialog
    ],
    exports: [
        ImageCropperDialog,
        AddMemberDialog,
        AddSubcontractorDialog,
        AddZoneDialog,
        ColorPickerDialog,
        AddSupplierDialog
    ]
})
export class CommonDialogsModule {}
