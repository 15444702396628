<div platform *ngIf="initApp && !maintenanceMode">
    <mat-sidenav-container
        [class.no-sidenav]="!showNavBar()"
        [class.enable-animation]="enableAnimation"
        [class.collapsed]="sideNavCollapsed"
        (backdropClick)="sideNavClosed()"
    >
        <mat-sidenav [mode]="isMobile ? 'over' : 'side'" [disableClose]="!isMobile" [opened]="!isMobile">
            <ng-container *ngIf="showNavBar()">
                <organization-sidenav
                    *hasRole="['ROLE_SUPER_ADMIN', 'ROLE_ORGANIZATION_ADMIN']"
                    [collapsed]="isMobile ? undefined : sideNavCollapsed"
                    [account]="account$ | async"
                    [projects$]="projects$"
                    [recentProjects$]="recentProjects$"
                    [recentOrganizations$]="recentOrganizations$"
                    [settings$]="settings$"
                    [orderCount$]="orderCount$"
                    [projectFeatures$]="projectFeatures$"
                    [mobile]="isMobile"
                    (projectSelected)="projectSelected($event)"
                    (organizationSelected)="organizationSelected($event)"
                    (toggleSideNav)="toggleSideNav()"
                    (closeSideNav)="sideNavClosed()"
                    (openFeedbackDialog)="openFeedbackDialog()"
                ></organization-sidenav>
                <subcontractor-sidenav
                    *hasRole="['ROLE_SUBCONTRACTOR_ADMIN', 'ROLE_SUBCONTRACTOR_USER']"
                    [collapsed]="isMobile ? undefined : sideNavCollapsed"
                    [account]="account$ | async"
                    [projects$]="projects$"
                    [recentProjects$]="recentProjects$"
                    [recentOrganizations$]="recentOrganizations$"
                    [settings$]="settings$"
                    [projectFeatures$]="projectFeatures$"
                    [mobile]="isMobile"
                    (projectSelected)="projectSelected($event)"
                    (organizationSelected)="organizationSelected($event)"
                    (closeSideNav)="sideNavClosed()"
                    (toggleSideNav)="toggleSideNav()"
                    (openFeedbackDialog)="openFeedbackDialog()"
                ></subcontractor-sidenav>
                <project-leader-sidenav
                    *hasRole="['ROLE_PROJECT_LEADER']"
                    [collapsed]="isMobile ? undefined : sideNavCollapsed"
                    [account]="account$ | async"
                    [projects$]="projects$"
                    [recentProjects$]="recentProjects$"
                    [recentOrganizations$]="recentOrganizations$"
                    [projectFeatures$]="projectFeatures$"
                    [settings$]="settings$"
                    [mobile]="isMobile"
                    (projectSelected)="projectSelected($event)"
                    (organizationSelected)="organizationSelected($event)"
                    (closeSideNav)="sideNavClosed()"
                    (toggleSideNav)="toggleSideNav()"
                    (openFeedbackDialog)="openFeedbackDialog()"
                ></project-leader-sidenav>
            </ng-container>
        </mat-sidenav>
        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<div class="full-page-message" *ngIf="maintenanceMode">
    <div class="message-wrapper">
        <h1>{{'LABELS.DOWN_FOR_MAINTENANCE' | translate}}</h1>
        <h2>{{'LABELS.WILL_BE_BACK_SHORTLY' | translate}}</h2>
    </div>
</div>
