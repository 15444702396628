import * as assetCategory from '../../actions/data/assetcategory';
import { AssetCategory } from 'common/types/assetcategory';

export function assetCategoriesReducer(state: AssetCategory[] = [], action: assetCategory.Actions): AssetCategory[] {
    if (action.type === assetCategory.ActionTypes.ASSET_CATEGORIES_SET_ALL) {
        return [...action.payload.assetCategories];
    } else {
        return state;
    }
}
