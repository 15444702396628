import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { Subcontractor } from 'common/types/types';

export const ActionTypes = {
    SUBCONTRACTORS_SET_ALL: type<'DATA_SUBCONTRACTORS_SET_ALL'>('DATA_SUBCONTRACTORS_SET_ALL'),
    SUBCONTRACTORS_UPDATE_ONE: type<'DATA_SUBCONTRACTORS_UPDATE_ONE'>('DATA_SUBCONTRACTORS_UPDATE_ONE'),
    SUBCONTRACTORS_ADD: type<'DATA_SUBCONTRACTORS_ADD'>('DATA_SUBCONTRACTORS_ADD')
};

export class SetAllSubcontractors implements Action {
    type = ActionTypes.SUBCONTRACTORS_SET_ALL;
    payload: { subcontractors: Subcontractor[] };

    constructor(subcontractors: Subcontractor[]) {
        this.payload = {subcontractors};
    }
}

export class UpdateSubcontractor implements Action {
    type = ActionTypes.SUBCONTRACTORS_UPDATE_ONE;
    payload: { id: string, subcontractor: Subcontractor };

    constructor(id: string, subcontractor: Subcontractor) {
        this.payload = {id, subcontractor};
    }
}

export class AddSubcontractor implements Action {
    type = ActionTypes.SUBCONTRACTORS_ADD;
    payload: { subcontractor: Subcontractor };

    constructor(subcontractor: Subcontractor) {
        this.payload = { subcontractor };
    }
}

export type Actions =
    SetAllSubcontractors
    | UpdateSubcontractor
    | AddSubcontractor;
