import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Storage } from 'common/services/storage';
import { Observable } from 'rxjs';

@Injectable()
export class LangHttpInterceptor implements HttpInterceptor {
    constructor(private storage: Storage) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userLang = this.storage.getItem('language');
        const lang = userLang != null ? userLang.replace('_', '-') : 'en';
        const newReq = req.clone({headers: req.headers.set('Accept-Language', lang)});
        return next.handle(newReq);
    }
}
