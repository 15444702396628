import { Component, Inject, OnDestroy, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { nativeWindow } from 'app/services/window.service';
import { ApplicationSandbox } from 'app/application.sandbox';
import { Platform } from 'common/services/platform';

@Component({
    selector: 'feedback-dialog',
    templateUrl: 'feedback.dialog.html',
    styleUrls: ['feedback.dialog.scss']
})
export class FeedbackDialog implements OnInit, OnDestroy {
    private goBack = false;
    private skipPop = false;
    private mobile = false;

    loading = false;
    showContent = true;

    constructor(private location: Location,
                private router: Router,
                private dialogRef: MatDialogRef<FeedbackDialog>,
                private applicationSandbox: ApplicationSandbox,
                private platform: Platform,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.mobile = !this.platform.isDesktop();
    }

    ngOnInit() {
        nativeWindow.history.pushState(null, 'Feedback', this.router.url);
    }

    close(): void {
        this.dialogRef.close();
        this.goBack = true;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event): void {
        if (!this.skipPop) {
            this.dialogRef.close(false);
        }
    }

    ngOnDestroy() {
        this.skipPop = true;
        if (this.goBack) {
            this.location.back();
        }
    }
}
