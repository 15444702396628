import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'button-row',
    templateUrl: './button-row.component.html',
    styleUrls: ['./button-row.component.scss']
})
export class ButtonRowComponent {
    @Input() leftButtonText: string;
    @Input() leftButtonIcon: string;
    @Input() leftButtonType = 'button';
    @Input() rightButtonText: string;
    @Input() rightButtonIcon: string;
    @Input() rightButtonType = 'button';

    @Output() clickLeftButton = new EventEmitter<void>();
    @Output() clickRightButton = new EventEmitter<void>();
}
