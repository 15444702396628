import { Injectable } from '@angular/core';
import { AuthHttp } from 'common/services/auth-http.service';
import { Zone } from 'common/types/types';
import { ZoneService } from '../data/zone.service';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { SetAllZones, UpdateZone } from 'statemanagement/actions/data/zone';
import { select, Store } from '@ngrx/store';
import { merge, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, share, take, tap } from 'rxjs/operators';
import { localeOrderBy } from 'common/utils/utils';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class ZoneCacheService extends ZoneService {
    private lastCacheMoment;

    constructor(protected http: AuthHttp,
                private store: Store<ApplicationState>) {
        super(http);
    }

    findAll(type = 'ORGANIZATION', forceReload = false): Observable<Zone[]> {
        const cachedZones$ = this.store.pipe(select((state) => state.data.zones));
        const loadNewZones$ = cachedZones$.pipe(
            take(1),
            filter((cachedZones: Zone[]) => forceReload || this.shouldReloadZone(cachedZones)),
            mergeMap((cachedZones: Zone[]) => type === 'PROJECT_LEADER' ? super.findAllForProjectLeader() : super.findAll()
                .pipe(
                    map((zones: Zone[]) => localeOrderBy(zones, 'name', 'asc')),
                    tap((zones: Zone[]) => this.store.dispatch(new SetAllZones(zones))),
                    tap(() => this.lastCacheMoment = moment()),
                    catchError((err) => cachedZones == null ? throwError(err) : of(cachedZones)),
                    share()
                )
            )
        );
        return merge(
            cachedZones$.pipe(filter((zones: Zone[]) => zones != null)),
            loadNewZones$
        );
    }

    findOne(id: string): Observable<Zone> {
        return super.findOne(id)
            .pipe(tap((zone: Zone) => this.store.dispatch(new UpdateZone(id, zone))));
    }

    private shouldReloadZone(zones: Zone[]): boolean {
        return zones == null
            || this.lastCacheMoment == null
            || this.lastCacheMoment.isBefore(moment().subtract(5, 'minutes'));
    }

    update(zoneId: string, oldZoneDetails: Zone, newZoneDetails: Zone): Observable<Zone> {
        return super.update(zoneId, oldZoneDetails, newZoneDetails)
            .pipe(tap((updatedZone: Zone) => updatedZone
                ? this.store.dispatch(new UpdateZone(zoneId, updatedZone))
                : null));
    }

    updateStatus(zoneId: string, status: string): Observable<Zone> {
        return super.updateStatus(zoneId, status)
            .pipe(tap((updatedZone: Zone) => updatedZone
                ? this.store.dispatch(new UpdateZone(updatedZone.id, updatedZone))
                : null));
    }
}
