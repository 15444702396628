<div [class]="'showbox ' + size">
    <div class="loader">
        <svg class="circular" viewBox="25 25 50 50">
            <circle
                [class.has-color]="useActionColor"
                [attr.stroke-width]="size === 'small' ? 2 : 4"
                stroke-miterlimit="10"
                class="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
            />
        </svg>
    </div>
</div>
