import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { AuthenticationResult } from '../../../user/types/AuthenticationResult';
import { Account } from '../../../user/types/Account';

export interface UserDetails {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    functionTitle?: string;
    phoneNumber?: {
        'countryCode': number;
        'nationalNumber': number;
    }
    icon?: string;
    language?: string;
    gender?: 'FEMALE' | 'MALE';
    organization?: {
        sector?: string;
    }
}

export const ActionTypes = {
    SET_AUTHENTICATION: type<'DATA_AUTHENTICATION_SET_AUTHENTICATION'>('DATA_AUTHENTICATION_SET_AUTHENTICATION'),
    SET_ACCOUNT: type<'DATA_AUTHENTICATION_SET_ACCOUNT'>('DATA_AUTHENTICATION_SET_ACCOUNT'),
    UPDATE_ACCOUNT: type<'DATA_AUTHENTICATION_UPDATE_ACCOUNT'>('DATA_AUTHENTICATION_UPDATE_ACCOUNT'),
    CLEAR_AUTHENTICATION: type<'DATA_AUTHENTICATION_CLEAR_AUTHENTICATION'>('DATA_AUTHENTICATION_CLEAR_AUTHENTICATION')
};

export class SetAuthentication implements Action {
    type = ActionTypes.SET_AUTHENTICATION;
    payload: { authenticationResult: AuthenticationResult };

    public constructor(authenticationResult: AuthenticationResult) {
        this.payload = {authenticationResult};
    }
}

export class SetAccount implements Action {
    type = ActionTypes.SET_ACCOUNT;
    payload: { account: Account };

    public constructor(account: Account) {
        this.payload = {account};
    }
}

export class UpdateAccount implements Action {
    type = ActionTypes.UPDATE_ACCOUNT;
    payload: { user: UserDetails };

    constructor(user: UserDetails) {
        this.payload = { user };
    }
}

export class ClearAuthentication implements Action {
    type = ActionTypes.CLEAR_AUTHENTICATION;

    public constructor() {}
}

export type Actions =
    SetAuthentication
    | SetAccount
    | ClearAuthentication
    | UpdateAccount;
