import { Injectable } from '@angular/core';
import { ActivityCacheService } from './activity-cache.service';
import { MemberCacheService } from './member-cache.service';
import { SubcontractorCacheService } from './subcontractor-cache.service';
import { ZoneCacheService } from './zone-cache.service';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { SetAllActivities } from 'statemanagement/actions/data/activity';
import { SetAllMembers } from 'statemanagement/actions/data/members';
import { SetAllZones } from 'statemanagement/actions/data/zone';
import { Store } from '@ngrx/store';
import { PopupsController } from '../../controllers/popups/popups.controller';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SetAllSubcontractors } from '../../../statemanagement/actions/data/subcontractor';

@Injectable({providedIn: 'root'})
export class CacheService {
    private catchErrorAndShowPopup = catchError((err) => {
        this.showReloadPopup();
        return throwError(err);
    });

    activities$ = this.activityCacheService.findAll().pipe(this.catchErrorAndShowPopup);
    members$ = this.memberCacheService.findAll().pipe(this.catchErrorAndShowPopup);
    subcontractors$ = this.subcontractorCacheService.findAll().pipe(this.catchErrorAndShowPopup);
    zones$ = this.zoneCacheService.findAll().pipe(this.catchErrorAndShowPopup);
    projectLeaderZones$ = this.zoneCacheService.findAll('PROJECT_LEADER').pipe(this.catchErrorAndShowPopup);

    constructor(private store: Store<ApplicationState>,
                private popupsCtrl: PopupsController,
                private activityCacheService: ActivityCacheService,
                private memberCacheService: MemberCacheService,
                private subcontractorCacheService: SubcontractorCacheService,
                private zoneCacheService: ZoneCacheService) {}

    resetCaches() {
        this.store.dispatch(new SetAllActivities(null));
        this.store.dispatch(new SetAllMembers(null));
        this.resetProjectCaches();
    }

    resetProjectCaches() {
        this.resetZonesCache();
        this.store.dispatch(new SetAllSubcontractors(null));
    }

    resetZonesCache() {
        this.store.dispatch(new SetAllZones(null));
    }

    showReloadPopup() {
        this.popupsCtrl.create({
            title: 'POPUPS.TECHNICAL_ERROR',
            message: 'POPUPS.SOMETHING_WENT_WRONG_PLEASE_RELOAD',
            okText: 'POPUPS.RELOAD',
            width: 300,
            disableClose: true,
            handleOk: () => {
                window.location.reload();
            }
        });
    }
}
