import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

@Directive({
    selector: '[hasRole]'
})
export class HasRoleDirective {
    @Input()
    set hasRole(roles: string | string[]) {
        this.updateView(roles);
    }

    constructor(private vcRef: ViewContainerRef,
                private templateRef: TemplateRef<any>,
                private store: Store<ApplicationState>) {}

    updateView(roles: string | string[]) {
        this.vcRef.clear();
        this.store.pipe(
                select((state) => state.data.authentication),
                map((authRes) => authRes.account && authRes.account.authorities ? authRes.account.authorities : []),
                take(1)
            )
            .subscribe((authorities: string[]) => {
                if (typeof roles === 'string' && authorities.includes(roles)
                    || (<string[]>roles).find((role: string) => authorities.includes(role))) {
                    this.vcRef.createEmbeddedView(this.templateRef);
                }
            });
    }
}
