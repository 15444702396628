import { Injectable } from '@angular/core';
import { ActivityService } from '../data/activity.service';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { SetAllActivities } from 'statemanagement/actions/data/activity';
import { Activity, ProjectFeatures } from 'common/types/types';
import { Store, select } from '@ngrx/store';
import { getActiveProjectFeatures } from 'statemanagement/selectors/settings.selectors';
import { Observable, merge, throwError, of } from 'rxjs';
import { filter, mergeMap, catchError, take, tap, share, map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class ActivityCacheService {
    private lastCacheMoment;

    constructor(private activityService: ActivityService,
                private store: Store<ApplicationState>) {}

    findAll(forceReload = false): Observable<Activity[]> {
        const cachedActivities$ = this.store.pipe(select((state) => state.data.activities));
        const loadNewActivities$ = cachedActivities$.pipe(
            take(1),
            filter((cachedActivities: Activity[]) => forceReload || this.shouldReloadActivities(cachedActivities)),
            mergeMap((cachedActivities: Activity[]) => this.activityService.findAll()
                .pipe(
                    tap((activities: Activity[]) => this.store.dispatch(new SetAllActivities(activities))),
                    tap(() => this.lastCacheMoment = moment()),
                    catchError((err) => cachedActivities == null ? throwError(err) : of(cachedActivities)),
                    share()
                )
            )
        );
        const hasActivities$ = this.store.pipe(
            getActiveProjectFeatures,
            map((projectFeatures: ProjectFeatures) => projectFeatures && projectFeatures.activities)
        );
        const activities$ = merge(
            cachedActivities$.pipe(filter((activities: Activity[]) => activities != null)),
            loadNewActivities$
        );

        return hasActivities$.pipe(
             mergeMap((hasActivities: boolean) => hasActivities
                 ? activities$
                 : of([])
             )
        );
    }

    private shouldReloadActivities(activities: Activity[]): boolean {
        return activities == null
            || this.lastCacheMoment == null
            || this.lastCacheMoment.isBefore(moment().subtract(30, 'minutes'));
    }
}
