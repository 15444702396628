import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ms } from 'common/microservices.enum';
import { AuthHttp } from 'common/services/auth-http.service';
import { Activity } from 'common/types/activity';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ActivityService {
    constructor(private http: AuthHttp) {}

    findAll(): Observable<Activity[]> {
        return this.http.get(Ms.MyAssetPlanner, '/activities', undefined, 'active')
            .pipe(map((res) => res && res.activities ? res.activities : []));
    }
}
