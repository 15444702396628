import { Injectable } from '@angular/core';
import { Organization } from '../types/types';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from '../../statemanagement/state/ApplicationState';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Supplier } from '../types/supplier';
import { map, mergeMap, take } from 'rxjs/operators';

@Injectable()
export class MyTranslateService {

    constructor(private store: Store<ApplicationState>, private translate: TranslateService) {}

    get(message: string | string[], params?): Observable<string | string[]> {
        return this.translate.get(message, params);
    }

    getOnce(message: string | string[], params?): Observable<string | string[]> {
        return this.get(message, params).pipe(take(1));
    }

    getWithOpCo(message: string | string[], params?): Observable<string | string[]> {
        return this.store.select((state) => state.settings.activeOrganization)
            .pipe(
                mergeMap((organization: Organization) => organization && organization.opCo
                    ? this.get('OPCOS.SHORT.' + organization.opCo)
                    : of(undefined)),
                mergeMap((opCoName = 'OpCo') => {
                    const interpolateParams = Object.assign({opco: opCoName}, params);
                    return this.translate.get(message, interpolateParams);
                })
            );
    }

    getOnceWithOpCo(message: string | string[], params?): Observable<string | string[]> {
        return this.getWithOpCo(message, params).pipe(take(1));
    }

    getWithSupplier(message: string | string[], supplier: Supplier, params?): Observable<string | string[]> {
        if (supplier && supplier.type && supplier.value) {
            return this.store.pipe(
                select((state) => state.data.suppliers),
                map((suppliers: Supplier[]) =>
                    suppliers.find((sup: Supplier) => sup.type === supplier.type && sup.value === supplier.value)),
                map((sup: Supplier) => sup ? sup.name : ''),
                mergeMap((supplierName: string) => {
                    const interpolateParams = Object.assign({supplier: supplierName}, params);
                    return this.translate.get(message, interpolateParams);
                })
            );
        } else {
            return this.translate.get(message);
        }
    }

    getOnceWithSupplier(message: string | string[], supplier: Supplier, params?): Observable<string | string[]> {
        return this.getWithSupplier(message, supplier, params).pipe(take(1));
    }

    setTranslation(lang: string, translations: Object, shouldMerge = true) {
        this.translate.setTranslation(lang, translations, shouldMerge);
    }
}
