import { ConfigService } from './config/config.service';

function _window(): any {
    return window;
}

export const nativeWindow = _window();
export const navigator = _window().navigator;

export function googleAnalytics(...args): any {
    if (ConfigService.get('env') === 'prod') {
        _window().ga(...args);
    }
}

export function gaSendEvent(category: string, action: string, label?: string): any {
    if (ConfigService.get('env') === 'prod') {
        _window().ga('send', 'event', category, action, label);
    }
}

export function reloadWindow() {
    nativeWindow.location.reload();
}
