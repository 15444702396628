<table class="button-row">
    <tr>
        <td *ngIf="leftButtonText != null">
            <button
                class="action secondary"
                [class.custom-icon]="leftButtonIcon != null"
                [type]="leftButtonType"
                (click)="clickLeftButton.emit()"
                (keydown.enter)="clickLeftButton.emit()"
            >
                <span class="button-content-wrapper">
                    <i *ngIf="leftButtonIcon != null" [class]="leftButtonIcon"></i><span class="text">{{leftButtonText}}</span>
                </span>
            </button>
        </td>
        <td *ngIf="rightButtonText != null">
            <button
                class="action primary"
                [class.custom-icon]="rightButtonIcon != null"
                [type]="rightButtonType"
                (click)="clickRightButton.emit()"
                (keydown.enter)="clickRightButton.emit()"
            >
                <span class="button-content-wrapper">
                    <i *ngIf="rightButtonIcon != null" [class]="rightButtonIcon"></i><span class="text">{{rightButtonText}}</span>
                </span>
            </button>
        </td>
    </tr>
</table>

<div class="button-row">
    <button
        *ngIf="leftButtonText != null"
        class="action secondary"
        [class.custom-icon]="leftButtonIcon != null"
        [type]="leftButtonType"
        (click)="clickLeftButton.emit()"
        (keydown.enter)="clickLeftButton.emit()"
    >
        <span class="button-content-wrapper">
            <i *ngIf="leftButtonIcon != null" [class]="leftButtonIcon"></i><span class="text">{{leftButtonText}}</span>
        </span>
    </button>
    <button
        *ngIf="rightButtonText != null"
        class="action primary"
        [class.custom-icon]="rightButtonIcon != null"
        [type]="rightButtonType"
        (click)="clickRightButton.emit()"
        (keydown.enter)="clickRightButton.emit()"
    >
        <span class="button-content-wrapper">
            <i *ngIf="rightButtonIcon != null" [class]="rightButtonIcon"></i><span
            class="text">{{rightButtonText}}</span>
        </span>
    </button>
</div>
