import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class IPService {
    constructor(private http: HttpClient) {}

   getIPInfo(): Observable<string> {
        return this.http.get('https://ipapi.co/json')
            .pipe(map((info: {country_code: string}) => info && info.country_code ? info.country_code : 'OTHER'));
   }
}
