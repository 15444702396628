import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { Zone } from 'common/types/types';

export const ActionTypes = {
    ZONES_SET_ALL: type<'DATA_ZONES_SET_ALL'>('DATA_ZONES_SET_ALL'),
    ZONES_UPDATE_LIST: type<'DATA_ZONES_UPDATE_LIST'>('DATA_ZONES_UPDATE_LIST'),
    ZONES_UPDATE_ONE: type<'DATA_ZONES_UPDATE_ONE'>('DATA_ZONES_UPDATE_ONE'),
    ZONES_ADD: type<'DATA_ZONES_ADD'>('DATA_ZONES_ADD')
};

export class SetAllZones implements Action {
    type = ActionTypes.ZONES_SET_ALL;
    payload: { zones: Zone[] };

    constructor(zones: Zone[]) {
        this.payload = {zones};
    }
}

export class UpdateZones implements Action {
    type = ActionTypes.ZONES_UPDATE_LIST;
    payload: { zones: Zone[] };

    constructor(zones: Zone[]) {
        this.payload = {zones};
    }
}

export class UpdateZone implements Action {
    type = ActionTypes.ZONES_UPDATE_ONE;
    payload: { id: string, zone: Zone };

    constructor(id: string, zone: Zone) {
        this.payload = {id, zone};
    }
}

export class AddZone implements Action {
    type = ActionTypes.ZONES_ADD;
    payload: { zone: Zone };

    constructor(zone: Zone) {
        this.payload = { zone };
    }
}

export type Actions =
    SetAllZones
    | UpdateZones
    | UpdateZone
    | AddZone;
