import {AuthenticationResult} from '../../../user/types/AuthenticationResult';
import * as authentication from '../../actions/data/autentication';

const initialState: AuthenticationResult = {
    isAuthenticated: false,
    token: null,
    refreshToken: null,
    account: null
};
export function authenticationReducer(state: AuthenticationResult = initialState,
                                      action: authentication.Actions): AuthenticationResult {
    switch (action.type) {
        case authentication.ActionTypes.SET_AUTHENTICATION:
            return Object.assign({}, action.payload.authenticationResult);
        case authentication.ActionTypes.SET_ACCOUNT:
            return Object.assign({}, state, {account: Object.assign({}, state.account, action.payload.account)});
        case authentication.ActionTypes.UPDATE_ACCOUNT:
            return Object.assign({}, state, {account: Object.assign({}, state.account, {
                        firstName: action.payload.user.firstName,
                        lastName: action.payload.user.lastName,
                        icon: action.payload.user.icon
                    })});
        case authentication.ActionTypes.CLEAR_AUTHENTICATION:
            return {
                isAuthenticated: false,
                token: null,
                refreshToken: null,
                account: null
            };
        default:
            return state;
    }
}
