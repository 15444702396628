import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Settings } from '../types/types';
import { AuthService } from './auth.service';
import { ConfigService } from 'app/services/config/config.service';
import { Ms } from '../microservices.enum';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

interface RequestOptions {
    headers?: HttpHeaders;
    params?: HttpParams;
    observe?: any;
    responseType?: any;
}

export type AddIdMethod = 'prefix' | 'replace' | 'headers';

@Injectable()
export class AuthHttp {

    constructor(private http: HttpClient,
                private store: Store<ApplicationState>,
                private authService: AuthService) {
    }

    get(microService: Ms, path: string, options: RequestOptions = {headers: new HttpHeaders()},
        addOrgId?: 'active' | 'user', addProjectId = false, addIdMethod: AddIdMethod = 'prefix'): Observable<any> {
        return this.executeRequest((settings: Settings, user: any) => {
            if (this.isOrganizationIdAndProjectIdValid(settings, addOrgId, addProjectId)) {
                options.headers = this.getHeaders(options.headers, settings, user.token, addOrgId, addProjectId);
                const uri = this.getURI(microService, settings, path, addOrgId, addProjectId, addIdMethod);
                return this.http.get(uri, options);
            } else {
                return throwError('No organization and/or project ID set: ' + path);
            }
        });
    }

    post(microService: Ms, path: string, body: any, options: RequestOptions = {headers: new HttpHeaders()},
         addOrgId?: 'active' | 'user', addProjectId = false, addIdMethod: AddIdMethod = 'prefix'): Observable<any> {
        return this.executeRequest((settings: Settings, user: any) => {
            if (this.isOrganizationIdAndProjectIdValid(settings, addOrgId, addProjectId)) {
                options.headers = this.getHeaders(options.headers, settings, user.token, addOrgId, addProjectId);
                const uri = this.getURI(microService, settings, path, addOrgId, addProjectId, addIdMethod);
                return this.http.post(uri, body, options);
            } else {
                return throwError('No organization and/or project ID set: ' + path);
            }
        });
    }

    put(microService: Ms, path: string, body: any, options: RequestOptions = {headers: new HttpHeaders()},
        addOrgId?: 'active' | 'user', addProjectId = false, addIdMethod: AddIdMethod = 'prefix'): Observable<any> {
        return this.executeRequest((settings: Settings, user: any) => {
            if (this.isOrganizationIdAndProjectIdValid(settings, addOrgId, addProjectId)) {
                options.headers = this.getHeaders(options.headers, settings, user.token, addOrgId, addProjectId);
                const uri = this.getURI(microService, settings, path, addOrgId, addProjectId, addIdMethod);
                return this.http.put(uri, body, options);
            } else {
                return throwError('No organization and/or project ID set: ' + path);
            }
        });
    }

    patch(microService: Ms, path: string, body: any, options: RequestOptions = {headers: new HttpHeaders()},
          addOrgId?: 'active' | 'user', addProjectId = false, addIdMethod: AddIdMethod = 'prefix'): Observable<any> {
        return this.executeRequest((settings: Settings, user: any) => {
            if (this.isOrganizationIdAndProjectIdValid(settings, addOrgId, addProjectId)) {
                options.headers = this.getHeaders(options.headers, settings, user.token, addOrgId, addProjectId);
                const uri = this.getURI(microService, settings, path, addOrgId, addProjectId, addIdMethod);
                return this.http.patch(uri, body, options);
            } else {
                return throwError('No organization and/or project ID set: ' + path);
            }
        });
    }

    delete(microService: Ms, path: string, options: RequestOptions = {headers: new HttpHeaders()},
           addOrgId?: 'active' | 'user', addProjectId = false, addIdMethod: AddIdMethod = 'prefix'): Observable<any> {
        return this.executeRequest((settings: Settings, user: any) => {
            if (this.isOrganizationIdAndProjectIdValid(settings, addOrgId, addProjectId)) {
                options.headers = this.getHeaders(options.headers, settings, user.token, addOrgId, addProjectId);
                const uri = this.getURI(microService, settings, path, addOrgId, addProjectId, addIdMethod);
                if (options.responseType == null) {
                    options.responseType = 'text';
                }
                return this.http.delete(uri, options);
            } else {
                return throwError('No organization and/or project ID set: ' + path);
            }
        });
    }

    eventSource(microService: Ms, path: string, eventTypes: string[], addOrgId?: 'active' | 'user', addProjectId = false,
                addIdMethod: AddIdMethod = 'prefix'): Observable<any> {
        return this.executeRequest((settings: Settings, user: any) => {
            if (this.isOrganizationIdAndProjectIdValid(settings, addOrgId, addProjectId)) {
                return Observable.create((observer) => {
                    if (typeof(EventSource) !== 'undefined') {
                        const eventSource = new EventSource(this.getURI(microService, settings, '/sse' + path, addOrgId, addProjectId, addIdMethod));
                        eventTypes.forEach((type: string) =>
                            eventSource.addEventListener(type, (message: any) => observer.next({
                                event: type,
                                data: JSON.parse(message.data)
                            }))
                        );
                        eventSource.onerror = (error) => observer.error(error);
                        return () => {
                            eventSource.close();
                        };
                    } else {
                        const protocol = window.location.protocol.replace('http', 'ws');
                        const host = window.location.host;
                        let ws = new WebSocket(`${protocol}//${host}/api/ws`);
                        ws.onopen = () => ws.send(JSON.stringify({
                            'planningType': 'project',
                            'planningId': settings.activeProject.id,
                            'type': 'subscribeToPlanning'
                        }));

                        ws.onmessage = (message) => {
                            let data = JSON.parse(message.data);
                            return observer.next({
                                event: data.type,
                                data: data.data
                            })
                        };

                        ws.onerror = (error) => observer.error(error);
                        return () => {
                            ws.close();
                        };
                    }
                });

            } else {
                return throwError('No organization and/or project ID set: ' + path);
            }
        });
    }

    private executeRequest(request: Function) {
        const checkToken = this.authService.isTokenExpired()
            ? this.authService.loginWithRefreshToken()
            : of(null);
        return checkToken
            .pipe(
                mergeMap(() => forkJoin(
                    this.store.select((state) => state.settings).pipe(take(1)),
                    this.store.select((state) => state.data.authentication).pipe(take(1))
                )),
                mergeMap(([settings, user]) => request(settings, user))
            );
    }

    private isOrganizationIdAndProjectIdValid(settings: Settings, addOrgId: 'active' | 'user', addProjectId: boolean): boolean {
        return settings
            && (addOrgId !== 'active' && addOrgId !== 'user'
                || addOrgId === 'active' && settings.activeOrganization != null
                || addOrgId === 'user' && settings.userOrganization != null)
            && (settings.activeProject != null || !addProjectId);
    }

    private getURI(microService: Ms, settings: Settings, path: string, addOrgId: 'active' | 'user', addProjectId: boolean, addIdMethod?: AddIdMethod): string {
        const apiUrl = this.getMsApiUrl(microService);
        switch (addIdMethod) {
            case 'replace':
                return apiUrl + this.getReplaceURI(settings, path, addOrgId);
            case 'headers':
                return apiUrl + path;
            default:
                return apiUrl + this.getPrefixURI(settings, path, addOrgId, addProjectId);
        }
    }

    private getMsApiUrl(microService: Ms) {
        let key = 'apiUrl';
        switch (microService) {
            case Ms.MyAssetPlanner:
                key = 'apiUrl';
                break;
            case Ms.Organizations:
                key = 'apiUrlOrg';
                break;
            case Ms.Reporting:
                key = 'apiUrlRep';
                break;
            case Ms.SSE:
                key = 'apiUrlSse';
                break;
        }
        return ConfigService.get(key);
    }

    private getReplaceURI(settings: Settings, path: string, addOrgId: 'active' | 'user'): string {
        return path
            .replace('<organizationId>', this.getOrganizationUrlId(settings, addOrgId))
            .replace('<projectId>', settings.activeProject.id);
    }

    private getPrefixURI(settings: Settings, path: string, addOrgId: 'active' | 'user', addProjectId: boolean): string {
        return this.getOrganizationUrlPart(settings, addOrgId)
            + (addProjectId ? '/projects/' + settings.activeProject.id : '')
            + path;
    }

    private getOrganizationUrlPart(settings: Settings, addOrgId: 'active' | 'user'): string {
        const orgId = this.getOrganizationUrlId(settings, addOrgId);
        return orgId ? '/organizations/' + orgId : '';
    }

    private getOrganizationUrlId(settings: Settings, addOrgId: 'active' | 'user'): string {
        switch (addOrgId) {
            case 'active':
                return settings.activeOrganization.id;
            case 'user':
                return settings.userOrganization.id;
            default:
                return null;
        }
    }

    private getHeaders(headers: HttpHeaders, settings: Settings, token: string, addOrgId: 'active' | 'user', addProjectId: boolean) {
        headers = headers
            .delete('Authorization')
            .append('Authorization', 'Bearer ' + token);

        if (['active', 'user'].includes(addOrgId)) {
            headers = headers.append('X-Organization-Id', this.getOrganizationUrlId(settings, addOrgId));
        }
        if (addProjectId) {
            headers = headers.append('X-Project-Id', settings.activeProject.id);
        }
        return headers;
    }
}
