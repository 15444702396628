import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ms } from 'common/microservices.enum';
import { AssetCategory, AssetSubCategory, Supplier } from 'common/types/types';
import { AuthHttp } from 'common/services/auth-http.service';
import { sortBy } from 'lodash-es';
import { map } from 'rxjs/operators';

@Injectable()
export class AssetCategoryService {
    constructor(private http: AuthHttp) {}

    findAll(): Observable<{suppliers: Supplier[], categories: AssetCategory[]}> {
        return this.http.get(Ms.MyAssetPlanner, '/categories', undefined, 'active')
            .pipe(
                map((res: any) => res && res.categories
                    ? Object.assign({}, res, {categories: this.sortCategories(res.categories)})
                    : res
                )
            );
    }

    private sortCategories(categories: AssetCategory[]) {
        return categories.map((category: AssetCategory) => {
            const sortedSubcats = sortBy(category.subcategories, ['name'])
                .map((subCat: AssetSubCategory) => {
                    if (subCat.specifications) {
                        const specs = sortBy(subCat.specifications, [
                            (s) => s.engineType ? s.engineType.description : null,
                            (s) => s.liftingHeight,
                            (s) => s.workingHeight,
                            (s) => s.liftingCapacity,
                            (s) => s.platformWidth,
                            (s) => s.wheelCount,
                            (s) => s.mastType ? s.mastType.description : null,
                            (s) => s.indoorOutdoorUse ? s.indoorOutdoorUse.description : null,
                            (s) => s.outriggers ? s.outriggers.description : null,
                            (s) => s.operatorPosition ? s.operatorPosition.description : null
                        ]);
                        return Object.assign({}, subCat, {specifications: specs});
                    } else {
                        return subCat;
                    }
                });
            return Object.assign({}, category, {subcategories: sortedSubcats});
        });
    }
}
