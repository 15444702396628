import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Ms } from 'common/microservices.enum';
import { Zone } from 'common/types/types';
import { AuthHttp } from 'common/services/auth-http.service';
import { Observable, of } from 'rxjs';
import { cloneAndRemoveNulls } from 'common/utils/utils';
import { map } from 'rxjs/operators';
import * as jsonpatch from 'fast-json-patch';

@Injectable({providedIn: 'root'})
export class ZoneService {

    constructor(protected http: AuthHttp) {}

    findAll(): Observable<Zone[]> {
        const headers = this.getAcceptHeaders();
        return this.http.get(Ms.MyAssetPlanner, '/zones', {headers}, 'active', true)
            .pipe(map((res: any) => res && res.zones ? res.zones : []));
    }

    findAllForProjectLeader(): Observable<Zone[]> {
        return this.http.get(Ms.MyAssetPlanner, '/projectleader/sites')
            .pipe(map((res: any) => res && res.zones ? res.zones : []));
    }

    findOne(id: string): Observable<Zone> {
        const headers = this.getAcceptHeaders();
        return this.http.get(Ms.MyAssetPlanner, '/zones/' + id, {headers}, 'active', true);
    }

    create(zone: Zone): Observable<string> {
        const headers = this.getContentTypeHeaders();
        return this.http.post(Ms.MyAssetPlanner, '/zones', cloneAndRemoveNulls(zone), {headers, observe: 'response'}, 'active', true)
            .pipe(map((res) => {
                const location = res.headers.get('Location');
                return location && location.substring(location.lastIndexOf('/') + 1);
            }));
    }

    update(zoneId: string, oldDetails: Zone, newDetails: Zone): Observable<any> {
        const operations = jsonpatch.compare(cloneAndRemoveNulls(oldDetails), cloneAndRemoveNulls(newDetails));
        const headers = this.getContentTypeHeaders();
        return operations.length > 0
            ? this.http.put(Ms.MyAssetPlanner, '/zones/' + zoneId, cloneAndRemoveNulls(newDetails), {headers}, 'active', true)
            : of(false);
    }

    updateStatus(zoneId: string, status: string): Observable<Zone> {
        const operations = [{op: 'replace', path: '/status', value: status}];
        const headers = this.getContentTypeHeaders();
        return this.http.patch(Ms.MyAssetPlanner, '/zones/' + zoneId, operations, {headers}, 'active', true);
    }

    private getAcceptHeaders(): HttpHeaders {
        return new HttpHeaders({
            Accept:  'application/vnd.tvh.zone.v1+json'
        });
    }

    private getContentTypeHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.zone.v1+json'
        });
    }
}
