import { Directive, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Directive({
    selector: 'app-base'
})
export class BaseComponent implements OnDestroy {
    readonly destroyed$ = new ReplaySubject<boolean>(1);

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
