import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Ms } from 'common/microservices.enum';
import { Organization } from 'common/types/types';
import { AuthHttp } from 'common/services/auth-http.service';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationService {

    constructor(private http: AuthHttp) {}

    findOne(id: string): Observable<Organization> {
        const headers = new HttpHeaders({
            Accept: 'application/vnd.tvh.organizations.v1+json'
        });
        return this.http.get(Ms.Organizations, '/organizations/' + id, {headers});
    }
}
