import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { RecentProjectsService } from './recent-projects.service';
import { Storage } from './storage';
import { TempStorage } from './temp-storage.service';
import { ClearAuthentication, SetAuthentication } from 'statemanagement/actions/data/autentication';
import { UpdateSettings } from 'statemanagement/actions/settings';
import { AuthenticationResult } from '../../user/types/AuthenticationResult';
import { Account } from '../../user/types/Account';
import { Project } from 'common/types/types';
import { Base64Url } from '../utils/base64url';
import { map, take } from 'rxjs/operators';
import { ConfigService } from '../../app/services/config/config.service';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient,
                private store: Store<ApplicationState>,
                private storage: Storage,
                private recentProjectsService: RecentProjectsService,
                private tempStorage: TempStorage) {
    }

    isRefreshTokenExpired() {
        const refreshToken = this.storage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
        if (refreshToken) {
            const parts = refreshToken.split('.');
            const claims = JSON.parse(Base64Url.decode(parts[1]));
            const now = new Date();
            return (+claims.exp * 1000) - (24 * 60 * 60 * 1000) < now.getTime();
        }
        return true;
    }

    isTokenExpired() {
        const token = this.storage.getItem('token');
        if (token) {
            const parts = token.split('.');
            const claims = JSON.parse(Base64Url.decode(parts[1]));
            const now = new Date();
            return (+claims.exp * 1000) - (30 * 1000) < now.getTime()
                || (+claims.iat * 1000) + (60 * 60 * 1000) < now.getTime();
        }
        return true;
    }

    loginWithRefreshToken(firstLogin = false): Observable<AuthenticationResult> {
        const refreshToken = this.storage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
        if (refreshToken) {
            const url = ConfigService.get('apiUrlOrg') + '/auth/token';
            const headers = new HttpHeaders({
                'Authorization': 'Bearer ' + refreshToken
            });
            return this.http.get(url, {headers})
                .pipe(map((result: AuthenticationResult) => this.handleAuth(result, firstLogin, true)));
        } else {
            return throwError(new Error('No refresh token present'));
        }
    }

    handleAuth(authenticationResult: AuthenticationResult, firstLogin = false, withRefreshToken = false, stayLoggedIn = true): AuthenticationResult {
        const parts = authenticationResult.token.split('.');
        const account = JSON.parse(Base64Url.decode(parts[1])) as Account;

        if (account.organization || this.isSuperAdmin(account)) {
            this.storage.setItem('userId', account.userId);
            this.storage.setItem('language', account.language);
            this.storage.setItem('token', authenticationResult.token);
            if (authenticationResult.refreshToken) {
                if (stayLoggedIn) {
                    this.storage.setItem('refreshToken', authenticationResult.refreshToken);
                } else {
                    sessionStorage.setItem('refreshToken', authenticationResult.refreshToken);
                }
            }
            Object.assign(authenticationResult, {account: {...account, organization: account.organization}, isAuthenticated: true});
            this.store.dispatch(new SetAuthentication(authenticationResult));
            this.tempStorage.setItem('superadmin', this.isSuperAdmin(account));
            if (firstLogin) {
                this.recentProjectsService.loadFromStorage();
                if (!this.isSuperAdmin(account) || withRefreshToken) {
                    this.loadMostRecentProject();
                }
            }
            if (this.isSuperAdmin(account)) {
                delete account.organization;
            }
            return authenticationResult;
        } else {
            this.store.dispatch(new ClearAuthentication());
            this.tempStorage.removeItem('superadmin');
            return null;
        }
    }

    private isSuperAdmin(account: Account): boolean {
        return account && account.authorities && account.authorities.indexOf('ROLE_SUPER_ADMIN') !== -1;
    }

    private loadMostRecentProject() {
        const mostRecentId = this.recentProjectsService.getMostRecent();
        this.store
            .select((state) => state.data.projects)
            .pipe(take(1))
            .subscribe((projects: Project[]) => {
                const currentProject = projects.find((project: Project) => project.id === mostRecentId);
                this.store.dispatch(new UpdateSettings({activeProject: currentProject}));
            });
    }
}
