import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app';
import { environment } from './environments/environment';
import * as moment from 'moment';

moment.updateLocale('en', {
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'MMMM D, YYYY',
        LLL: 'MMMM D, YYYY LT',
        LLLL: 'dddd, Do MMMM YYYY LT'
    }
});

// Overwrite momentjs default format.
(moment as any).defaultFormat = 'YYYY-MM-DDTHH:mmZ';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => console.log('error', err));
