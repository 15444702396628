<header>
    <h1><i *ngIf="data.titleIcon" class="icon-margin {{data.titleIcon}}"></i><span>{{data.title | translate:data.titleParams}}</span>
    </h1>
</header>

<div *ngIf="data.message" class="dialog-content"><p>{{data.message | translate:data.messageParams}}</p></div>

<div class="actions">
    <button-row
        [leftButtonText]="data.cancelText ? (data.cancelText | translate) : null"
        [leftButtonIcon]="data.cancelIcon"
        [rightButtonText]="(data.okText ? data.okText : 'POPUPS.OK') | translate"
        [rightButtonIcon]="data.okIcon"
        (clickLeftButton)="close('CANCEL')"
        (clickRightButton)="close('OK')"
    ></button-row>
    <button-row
        *ngIf="data.extraText != null"
        [rightButtonText]="data.extraText | translate"
        [rightButtonIcon]="data.extraIcon"
        (clickRightButton)="close('EXTRA')"
    ></button-row>
</div>
