import { Injectable } from '@angular/core';

@Injectable()
export class TempStorage {
    private keyValues = {};

    setItem(key: string, value) {
        this.keyValues[key] = value;
    }

    getItem(key: string): string | number | boolean {
        if (typeof this.keyValues[key] !== 'undefined' ) {
            return this.keyValues[key];
        } else {
            return null;
        }
    }

    getAndRemoveItem(key: string): string | number | boolean {
        const item = this.getItem(key);
        this.removeItem(key);
        return item;
    }

    removeItem(key: string) {
        this.keyValues[key] = undefined;
    }
}
