import {type} from '../../util/util';
import {Action} from '@ngrx/store';
import {AssetCategory} from 'common/types/types';

export const ActionTypes = {
    ASSET_CATEGORIES_SET_ALL: type<'DATA_ASSET_CATEGORIES_SET_ALL'>('DATA_ASSET_CATEGORIES_SET_ALL')
};

export class SetAllAssetCategories implements Action {
    type = ActionTypes.ASSET_CATEGORIES_SET_ALL;
    payload: {assetCategories: AssetCategory[]};

    constructor(assetCategories: AssetCategory[]) {
        this.payload = {assetCategories};
    }
}

export type Actions =
    SetAllAssetCategories;
