import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSandbox } from '../../application.sandbox';
import { Storage } from 'common/services/storage';
import { Project } from 'common/types/types';
import { PopupsController } from '../../controllers/popups/popups.controller';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Organization } from 'common/types/organization';
import { Platform } from 'common/services/platform';
import { Account } from 'user/types/Account';
import { DateAdapter } from '@angular/material/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { BaseComponent } from 'common/base.component';
import { FeedbackDialog } from 'shared/dialogs/feedback/dialog/feedback.dialog';
import { getDialogConfig } from 'common/dialog-config';
import {
    forkJoin,
    interval,
    of,
    Observable,
    Subject,
    ReplaySubject,
    iif,
    throwError
} from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, skip, take, takeUntil, tap } from 'rxjs/operators';
import { googleAnalytics, nativeWindow, reloadWindow } from '../../services/window.service';
import { getPageX } from 'common/utils/utils';
import { isSupportedLang } from 'common/languages';
import { appVersion } from 'version';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { SwUpdateService } from 'app/external-app-services/sw-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './application.container.html',
  styleUrls: ['./application.container.scss']
})
export class ApplicationContainer extends BaseComponent implements OnInit {
    private reloadPopupShown = false;
    private touchStartOpen: {time: number, x: number};
    private touchStartClose: {time: number, x: number};
    private lastMaintenanceModeCheck;

    recentProjects$ = this.applicationSandbox.recentProjects$;
    recentOrganizations$ = this.applicationSandbox.recentOrganizations$;
    account$ = this.applicationSandbox.account$;
    projects$ = this.applicationSandbox.projects$;
    settings$ = this.applicationSandbox.settings$;
    orderCount$ = this.applicationSandbox.orderCount$;
    projectFeatures$ = this.applicationSandbox.projectFeatures$;
    initApp = false;
    isMobile = false;
    sideNavCollapsed = false;
    enableAnimation = false;
    maintenanceMode = false;
    feedbackDialogRef: MatDialogRef<FeedbackDialog>;

    @ViewChild(MatSidenav) matSideNav: MatSidenav;
    @ViewChild(MatSidenavContainer) matSideNavContainer: MatSidenavContainer;

    constructor(private location: Location,
                private translate: TranslateService,
                private applicationSandbox: ApplicationSandbox,
                private popupCtrl: PopupsController,
                private storage: Storage,
                private router: Router,
                private swUpdate: SwUpdate,
                private platform: Platform,
                private adapter: DateAdapter<any>,
                private dialog: MatDialog,
                private swUpdateService: SwUpdateService) {
        super();
        this.isMobile = !this.platform.isDesktop();
        this.applicationSandbox.setIsBrowserSupportedToStore();
    }

    ngOnInit(): void {
        this.swUpdateService.checkForUpdates();
        this.redirect();
    }

    private redirect() {
        if (environment.production && window.location.href.indexOf('myassetplanner.tvh.com') !== -1) {
            this.applicationSandbox.getIPInfo()
                .subscribe((country: string) => {
                    if (country.toUpperCase() === 'BE') {
                        window.location.href = 'https://myassetplanner.tvhequipment.com';
                    } else {
                        window.location.href = 'https://myassetplanner.mateco.eu';
                    }
                });
        } else {
            this.translate.setDefaultLang('en');
            this.setAppVersionOnAppRoot();
            this.handleSideNavCollapsedState();
            this.loadFirstPage();
            this.checkForMaintenanceModeOnNavigation();
            this.setupGoogleAnalytics();
        }
    }

    private setAppVersionOnAppRoot() {
        const appRoots = document.getElementsByTagName('app-root');
        if (appRoots && appRoots.length > 0) {
            appRoots[0].setAttribute('app-version', appVersion);
        }
    }

    private loadFirstPage() {
        let firstRoute = null;
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                take(1),
                map((event: NavigationStart) => event.url),
                tap((url: string) => firstRoute = url),
                mergeMap((url: string) => iif(
                    () => url.includes('/user', 0),
                    throwError(new Error('loadLoginScreen')),
                    this.applicationSandbox.loginUserWithToken()
                ))
            )
            .subscribe(
                () => this.loadAppData(),
                () => this.loadLoginScreen(firstRoute)
            );
    }

    private handleSideNavCollapsedState() {
        this.sideNavCollapsed = !this.isMobile ? this.storage.getItem('sideNavPosition') === 'collapsed' : true;
        this.applicationSandbox.setSideNavState(this.sideNavCollapsed);
        this.applicationSandbox
            .sideNavCollapsed$
            .pipe(skip(1))
            .subscribe((isCollapsed) => {
                this.sideNavCollapsed = isCollapsed;
                if (!this.isMobile) {
                    this.storage.setItem('sideNavPosition', isCollapsed ? 'collapsed' : 'expanded');
                } else {
                    if (isCollapsed) {
                        this.matSideNav.close();
                    } else {
                        this.matSideNav.open();
                    }
                }
            });
    }

    sideNavClosed() {
        this.applicationSandbox.setSideNavState(true);
    }

    openFeedbackDialog() {
        if (!this.feedbackDialogRef) {
            this.applicationSandbox.account$
                .pipe(take(1))
                .subscribe((account: Account) => {
                    this.feedbackDialogRef = this.dialog.open(FeedbackDialog, getDialogConfig({
                        width: '400px',
                        data: {email: account.email }
                    }));
                    this.feedbackDialogRef.afterClosed()
                        .pipe(take(1))
                        .subscribe(() => {
                            this.feedbackDialogRef = null;
                        });
                    this.feedbackDialogRef
                        .backdropClick()
                        .pipe(takeUntil(this.feedbackDialogRef.afterClosed()))
                        .subscribe(() => {
                            this.location.back();
                        });
                });
        }
    }

    private loadAppData(): void {
        forkJoin(
            this.applicationSandbox.loadOrganizationToStore().pipe(take(1)),
            this.applicationSandbox.loadCurrentUserInfoToStore().pipe(take(1)),
            this.loadLanguage()
        )
        .subscribe((data: [Organization, any, any]) => {
            const organization = data[0];
            this.registerSubscribers();
            this.loadProjectsForOrganization(organization);
        }, (error) => {
            console.error('organization', error);
            this.preloadFailed();
        });
    }

    private loadProjectsForOrganization(organization: Organization) {
        if (organization != null) {
            this.applicationSandbox.account$
                .pipe(
                    mergeMap((account: Account) => this.loadProjectsToStore(account, organization)),
                    take(1)
                )
                .subscribe(() => {
                    this.preloadFinished();
                }, (err) => {
                    console.log(err);
                    this.preloadFailed();
                });
        } else {
            this.preloadFinished();
        }
    }

    private preloadFinished(removeSplash = true) {
        this.initApp = true;
        this.applicationSandbox.setAppReady();
        if (removeSplash) {
            this.applicationSandbox.removeSplashScreen();
        }
    }

    private preloadFailed() {
        this.initApp = true;
        this.applicationSandbox.removeSplashScreen();
        this.showReloadPopup();
    }

    private loadProjectsToStore(account: Account, organization: Organization): Observable<Project[]> {
        return this.hasSubcontractorRole(account)
            ? this.applicationSandbox.loadProjectsToStore('SUBCONTRACTOR')
            : this.loadOrganizationProjectsToStore(organization);
    }

    private hasSubcontractorRole(account: Account): boolean {
        return account && account.authorities
            && (account.authorities.includes('ROLE_SUBCONTRACTOR_ADMIN')
                || account.authorities.includes('ROLE_SUBCONTRACTOR_USER'));
    }

    private loadOrganizationProjectsToStore(organization: Organization): Observable<Project[]> {
        return this.applicationSandbox
            .loadProjectsToStore()
            .pipe(
                tap((projects: Project[]) => {
                    if (organization.sector === 'INDUSTRY' && projects && projects.length > 0) {
                        this.applicationSandbox.projectSelected(projects[0]);
                    }
                })
            );
    }

    private loadLoginScreen(firstRoute: string): void {
        this.registerSubscribers();
        this.applicationSandbox.setAppReady();
        this.loadLanguage().subscribe(() => {
            if (firstRoute == null || !this.isUnauthenticatedRoute(firstRoute)) {
                this.router.navigate(['/user/auth'])
                    .then(() => this.preloadFinished(false));
            } else {
                this.preloadFinished(false);
            }
        });
    }

    private isUnauthenticatedRoute(route: string): boolean {
        return route.includes('/user/', 0)
            || route.includes('/redirect/', 0);
    }

    private registerSubscribers() {
        this.applicationSandbox
            .watchProjects()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {});
        this.applicationSandbox
            .loadCategoriesConditionally()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {}, (error) => {
                console.error('categories', error);
                this.showReloadPopup();
            });
        this.applicationSandbox
            .loadThemeColors()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {});
    }

    private loadLanguage(): Observable<void> {
        const userLang = this.storage.getItem('language');
        const lang = userLang && isSupportedLang(userLang) ? userLang : 'en';
        return this.translate
            .use(lang)
            .pipe(
                take(1),
                tap(() =>  {
                    moment.locale(lang);
                    this.adapter.setLocale(lang);
                }),
                debounceTime(1)
            );
    }

    private showReloadPopup(): void {
        if (!this.reloadPopupShown) {
            this.reloadPopupShown = true;
            setTimeout(() => {
                this.popupCtrl.create({
                    title: 'POPUPS.TECHNICAL_ERROR',
                    message: 'POPUPS.SOMETHING_WENT_WRONG_PLEASE_RELOAD',
                    okText: 'POPUPS.RELOAD',
                    width: 300,
                    disableClose: true,
                    handleOk: () => {
                        reloadWindow();
                    }
                });
            });
        }
    }

    public showNavBar(): boolean {
        const route = this.location.path();
        return !this.isUnauthenticatedRoute(route) && this.applicationSandbox.browserSupported;
    }

    projectSelected(project: Project): void {
        this.applicationSandbox.projectSelected(project);
    }

    organizationSelected(organization: Organization): void {
        this.applicationSandbox.organizationSelected(organization);
        this.router.navigate(['/organization/projects']);
    }

    logout(): void {
        this.applicationSandbox.logout();
        this.router.navigate(['/user/auth']);
    }

    toggleSideNav() {
        this.enableAnimation = true;
        this.applicationSandbox.toggleSideNavState();
    }

    private checkForMaintenanceModeOnNavigation() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                mergeMap(() => this.checkMaintenanceMode()),
                takeUntil(this.destroyed$)
            )
            .subscribe((maintenanceMode: boolean) => {
                if (!this.maintenanceMode && maintenanceMode) {
                    this.enableMaintenanceMode();
                }
            });
    }

    private checkMaintenanceMode(force = false) {
        return force || this.lastMaintenanceModeCheck == null || moment(this.lastMaintenanceModeCheck).add(1, 'minute').isBefore()
            ? this.applicationSandbox
                .findMaintenanceModeEnabled()
                .pipe(
                    tap(() => this.lastMaintenanceModeCheck = moment()),
                    catchError(() => of(this.maintenanceMode))
                )
            : of(this.maintenanceMode);
    }

    private enableMaintenanceMode() {
        this.maintenanceMode = true;
        const disableMaintenanceMode$ = new ReplaySubject<void>();
        this.checkMaintenanceModeWithInterval(disableMaintenanceMode$);
    }

    private checkMaintenanceModeWithInterval(disableMaintenanceMode$: Subject<void>) {
        interval(30000).pipe(
            takeUntil(disableMaintenanceMode$),
            mergeMap(() => this.checkMaintenanceMode(true))
        ).subscribe((maintenanceMode: boolean) => {
            if (!maintenanceMode) {
                disableMaintenanceMode$.next();
                this.maintenanceMode = false;
            }
        });
    }

    private setupGoogleAnalytics() {
        if (!this.location.path(true).includes('#no-analytics')) {
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    takeUntil(this.destroyed$)
                )
                .subscribe((event: NavigationEnd) => {
                    googleAnalytics('set', 'page', event.urlAfterRedirects);
                    googleAnalytics('send', 'pageview');
                });
        }
    }

    @HostListener('document:touchstart', ['$event'])
    touchStartSidenav(event) {
        if (this.showNavBar()) {
            const pageX = getPageX(event);
            if (!this.matSideNav.opened) {
                if (pageX < 25) {
                    this.touchStartOpen = {
                        time: new Date().getTime(),
                        x: pageX
                    };
                }
            } else {
                this.touchStartClose = {
                    time: new Date().getTime(),
                    x: pageX
                };
            }
        }
    }

    @HostListener('document:touchmove', ['$event'])
    touchMove(event) {
        if (this.touchStartOpen) {
            if (getPageX(event) - this.touchStartOpen.x > 100
                && new Date().getTime() - this.touchStartOpen.time < 300) {
                this.applicationSandbox.setSideNavState(false);
            }
        } else if (this.touchStartClose
            && this.touchStartClose.x - getPageX(event) > 100
            && new Date().getTime() - this.touchStartClose.time < 300) {
            this.applicationSandbox.setSideNavState(true);
        }
    }

    @HostListener('document:touchend', ['$event'])
    @HostListener('document:touchcancel', ['$event'])
    touchEnd() {
        this.touchStartOpen = null;
        this.touchStartClose = null;
    }
}
