import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ms } from 'common/microservices.enum';
import { AuthHttp } from 'common/services/auth-http.service';

@Injectable()
export class UserService {
    constructor(private http: AuthHttp) {}

    findCurrentUserInfo(): Observable<any> {
        return this.http.get(Ms.Organizations, '/user/info');
    }
}
