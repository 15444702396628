import * as projects from '../../actions/data/projects';
import { Project } from 'common/types/project';

export function projectsReducer(state: Project[] = [], action: projects.Actions): Project[] {
    switch (action.type) {
        case projects.ActionTypes.PROJECTS_SET_ALL:
            return sortProjects([ ...action.payload.projects ]);
        case projects.ActionTypes.PROJECTS_UPDATE:
            const updatedProjects = state
                .map((project) => project.id === action.payload.id ? Object.assign({}, project, action.payload.project) : project);
            return sortProjects(updatedProjects);
        default:
            return state;
    }
}

export function sortProjects(projectList) {
    return projectList.sort((p1, p2) => {
        return p1.name.toLowerCase() === p2.name.toLowerCase() ? 0 : (p1.name.toLowerCase() < p2.name.toLowerCase() ? -1 : 1);
    });
}
