import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationResult } from '../../user/types/AuthenticationResult';
import { Observable } from 'rxjs';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

    constructor(private router: Router,
                private store: Store<ApplicationState>) {
    }

    canActivate(): Observable<boolean> {
        return this.store.select((state) => state.settings.ready)
            .pipe(
                filter((ready) => ready),
                mergeMap(() => this.store.select((state) => state.data.authentication)
                    .pipe(map((authRes: AuthenticationResult) => {
                        if (authRes && authRes.account) {
                            return true;
                        } else {
                            this.router.navigate(['/user/auth']);
                            return false;
                        }
                    })))
            );
    }
}
