import * as bufferLib from 'buffer';
const Buffer = bufferLib.Buffer;

export class Base64Url {

    static encode(input: string | Buffer, encoding: any = 'utf8'): string {
        if (Buffer.isBuffer(input)) {
            return this.fromBase64(input.toString('base64'));
        }
        return this.fromBase64(Buffer.from(input as string, encoding).toString('base64'));
    }

    static decode(input: string, encoding: any = 'utf8'): string {
        return Buffer.from(this.toBase64(input), 'base64').toString(encoding);
    }

    static toBase64(input: string | Buffer): string {
        // We this to be a string so we can do .replace on it. If it's
        // already a string, this is a noop.
        input = input.toString();
        return this.padString(input)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
    }

    static fromBase64(base64: string): string {
        return base64
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }

    static toBuffer(input: string): Buffer {
        return Buffer.from(this.toBase64(input), 'base64');
    }

    static padString(input: string): string {
        const segmentLength = 4;
        const stringLength = input.length;
        const diff = stringLength % segmentLength;

        if (!diff) {
            return input;
        }

        let position = stringLength;
        let padLength = segmentLength - diff;
        const paddedStringLength = stringLength + padLength;
        const buffer = Buffer.alloc(paddedStringLength);

        buffer.write(input);

        while (padLength--) {
            buffer.write('=', position++);
        }

        return buffer.toString();
    }
}
