import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ms } from 'common/microservices.enum';
import { AuthHttp } from 'common/services/auth-http.service';
import { Member } from 'common/types/types';
import { cloneAndRemoveNulls } from 'common/utils/utils';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as jsonpatch from 'fast-json-patch';

@Injectable({providedIn: 'root'})
export class MemberService {

    private contentType = 'application/vnd.tvh.members.v1+json';

    constructor(protected http: AuthHttp) {}

    findAll(organizationId: string = null, includeSubcontractors = false): Observable<Member[]> {
        const headers = new HttpHeaders({
            Accept: this.contentType
        });

        const path = this.getBasePath(organizationId);
        const queryParams = includeSubcontractors ? '?includeSubcontractors=true' : '';
        return this.http.get(Ms.Organizations, path + queryParams, {headers}, !organizationId ? 'user' : null)
            .pipe(
                map((res: any) => res.members)
            );
    }

    findOne(memberId: string, organizationId: string = null): Observable<Member> {
        const headers = this.getHeaders();
        const path = this.getBasePath(organizationId) + '/' + memberId;
        return this.http.get(Ms.Organizations, path, {headers}, organizationId == null ? 'user' : null);
    }

    create(member: Member, organizationId = null): Observable<string> {
        const headers = this.getHeaders();
        const path = this.getBasePath(organizationId);
        return this.http.post(Ms.Organizations, path, cloneAndRemoveNulls(member),
            {headers, observe: 'response'}, !organizationId ? 'user' : null)
            .pipe(
                map((res) => {
                    const location = res.headers.get('Location');
                    return location && location.substring(location.lastIndexOf('/') + 1);
                })
            );
    }

    update(memberId: string, organizationId: string = null, oldMemberDetails: Member, newMemberDetails: Member): Observable<any> {
        const operations = jsonpatch.compare(cloneAndRemoveNulls(oldMemberDetails), cloneAndRemoveNulls(newMemberDetails));
        const headers = this.getHeaders();
        const path = this.getBasePath(organizationId) + '/' + memberId;
        return operations.length > 0
            ? this.http.patch(Ms.Organizations, path, operations, {headers}, organizationId == null ? 'user' : null)
            : of(false);
    }

    resendInvite(memberId: string, organizationId: string = null): Observable<void> {
        const headers = this.getHeaders();
        const path = this.getBasePath(organizationId) + '/' + memberId + '/invite';
        return this.http.post(Ms.Organizations, path, null,
            {headers}, !organizationId ? 'user' : null);
    }

    deactivate(memberId: string, organizationId: string = null): Observable<void> {
        const headers = this.getHeaders();
        const path = this.getBasePath(organizationId) + '/' + memberId + '/deactivate';
        return this.http.post(Ms.Organizations, path, null,
            {headers}, !organizationId ? 'user' : null);
    }

    activate(memberId: string, organizationId: string = null): Observable<void> {
        const headers = this.getHeaders();
        const path = this.getBasePath(organizationId) + '/' + memberId + '/activate';
        return this.http.post(Ms.Organizations, path, null,
            {headers}, !organizationId ? 'user' : null);
    }

    impersonate(email: string): Observable<any> {
        const headers = this.getHeaders();
        return this.http.post(Ms.Organizations, '/admin/impersonate', {username: email},
            {headers}, null);
    }

    private getBasePath(organizationId = null) {
        return (organizationId ? '/organizations/' + organizationId : '') + '/members';
    }

    private getHeaders(contentType: string = 'application/vnd.tvh.members.v1+json'): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': contentType
        });
    }
}
