import { NgModule } from '@angular/core';
import { DashPipe } from './dash.pipe';
import { DatePipe } from './date.pipe';
import { EmailPipe } from './email.pipe';
import { FillPipe } from './fill.pipe';
import { ZonePipe } from './zone.pipe';
import { StagePipe } from './stage.pipe';
import { CapacityPipe } from './capacity.pipe';
import { PhoneLinkPipe } from './phone-link.pipe';
import { PhonePipe } from './phone.pipe';
import { MeterPipe } from './meter.pipe';
import { TranslateOpCoPipe } from './translate-opco.pipe';
import { TranslateSupplierPipe } from './translate-supplier.pipe';
import { NamePipe } from './name.pipe';
import { NotEmptyPipe } from './not-empty.pipe';
import { ActivityPipe } from './activity.pipe';
import { ColorPipe } from './color.pipe';
import { IconPipe } from './icon.pipe';
import { ParenthesesPipe } from './parentheses.pipe';
import { RoleTextPipe } from './role-text.pipe';
import { AreaTypePipe } from './area-type.pipe';
import { DateTimePipe } from './date-time.pipe';
import { SpecListPipe } from './spec-list.pipe';

@NgModule({
    declarations: [
        AreaTypePipe,
        DashPipe,
        PhonePipe,
        PhoneLinkPipe,
        DatePipe,
        DateTimePipe,
        EmailPipe,
        FillPipe,
        ZonePipe,
        StagePipe,
        CapacityPipe,
        MeterPipe,
        TranslateOpCoPipe,
        TranslateSupplierPipe,
        NamePipe,
        NotEmptyPipe,
        ActivityPipe,
        ColorPipe,
        IconPipe,
        ParenthesesPipe,
        RoleTextPipe,
        SpecListPipe
    ],
    exports: [
        AreaTypePipe,
        DashPipe,
        PhonePipe,
        PhoneLinkPipe,
        DatePipe,
        DateTimePipe,
        EmailPipe,
        FillPipe,
        ZonePipe,
        StagePipe,
        CapacityPipe,
        MeterPipe,
        TranslateOpCoPipe,
        TranslateSupplierPipe,
        NamePipe,
        NotEmptyPipe,
        ActivityPipe,
        ColorPipe,
        IconPipe,
        ParenthesesPipe,
        RoleTextPipe,
        SpecListPipe
    ]
})
export class CommonPipesModule {
}
