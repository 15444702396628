import { Injectable } from '@angular/core';
import { AuthHttp } from 'common/services/auth-http.service';
import { SubcontractorService } from '../data/subcontractor.service';
import { Subcontractor } from 'common/types/types';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { AddSubcontractor, SetAllSubcontractors, UpdateSubcontractor } from 'statemanagement/actions/data/subcontractor';
import { Store, select } from '@ngrx/store';
import { Observable, merge, throwError, of } from 'rxjs';
import { filter, mergeMap, catchError, take, tap, share } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class SubcontractorCacheService extends SubcontractorService {
    private lastCacheMoment;

    constructor(protected http: AuthHttp,
                private store: Store<ApplicationState>) {
        super(http);
    }

    findAll(forceReload = false): Observable<Subcontractor[]> {
        const cachedSubcontractors$ = this.store.pipe(select((state) => state.data.subcontractors));
        const loadNewSubcontractors$ = cachedSubcontractors$.pipe(
            take(1),
            filter((cachedSubcontractors: Subcontractor[]) => forceReload || this.shouldReloadSubcontractor(cachedSubcontractors)),
            mergeMap((cachedSubcontractors: Subcontractor[]) => super.findAll()
                .pipe(
                    tap((subcontractors: Subcontractor[]) => this.store.dispatch(new SetAllSubcontractors(subcontractors))),
                    tap(() => this.lastCacheMoment = moment()),
                    catchError((err) => cachedSubcontractors == null ? throwError(err) : of(cachedSubcontractors)),
                    share()
                )
            )
        );
        return merge(
            cachedSubcontractors$.pipe(filter((subcontractors: Subcontractor[]) => subcontractors != null)),
            loadNewSubcontractors$
        );
    }

    private shouldReloadSubcontractor(subcontractors: Subcontractor[]): boolean {
        return subcontractors == null
            || this.lastCacheMoment == null
            || this.lastCacheMoment.isBefore(moment().subtract(5, 'minutes'));
    }

    create(subcontractor: Subcontractor): Observable<string> {
        return super.create(subcontractor)
            .pipe(tap((subcontractorId: string) => this.store.dispatch(new AddSubcontractor({...subcontractor, id: subcontractorId}))));
    }

    update(subcontractorId: string, oldSubcontractorDetails: Subcontractor, newSubcontractorDetails: Subcontractor): Observable<Subcontractor> {
       return super.update(subcontractorId, oldSubcontractorDetails, newSubcontractorDetails)
            .pipe(tap((subcontractor: Subcontractor) => subcontractor
                ? this.store.dispatch(new UpdateSubcontractor(subcontractorId, newSubcontractorDetails))
                : null));
    }
}
