<ng-container *ngIf="showContent">
    <header>
        <i class="fa fa-arrow-left" (click)="close()"></i>
        <h1>{{ 'LABELS.HELP_FEEDBACK' | translate }}</h1>
    </header>

    <form>
        <div class="loader-overlay" *ngIf="loading">
            <loader></loader>
        </div>
        <div class="dialog-content">
            <ul>
                <li>
                    <a href="mailto:ecommerce@mateco.eu">
                        <span class="icon"><i class="fa fa-envelope-o"></i></span>
                        <span class="text">
                        <span class="title">{{ 'LABELS.CONTACT' | translate }}</span>
                        <span class="subtitle">{{ 'LABELS.GET_IN_TOUCH' | translate }}</span>
                    </span>
                    </a>
                </li>
            </ul>
        </div>
    </form>
</ng-container>
