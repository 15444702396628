import { RouterModule } from '@angular/router';
import { AppCustomPreloader } from './custom-preloader';
import { AuthenticatedGuard } from 'common/guards/authenticated.guard';
import { ProjectSelectedGuard } from 'common/guards/project-selected.guard';
import { OrganizationSelectedGuard } from 'common/guards/organization-selected.guard';
import { UserOrganizationSelectedGuard } from '../common/guards/user-organization-selected.guard';
import { RoleGuard } from '../common/guards/role.guard';

import { RootContainer } from './containers/root/root.container';
import { RedirectContainer } from './containers/redirect/redirect.container';
import { BrowserSupportedGuard } from '../common/guards/browser-supported.guard';

export const routes = [
    {
        path: '',
        component: RootContainer,
        canActivate: [AuthenticatedGuard, BrowserSupportedGuard]
    },
    {
        path: 'redirect/:data',
        component: RedirectContainer
    },
    {
        path: 'organizations',
        loadChildren: () => import('organizations/index').then(m => m.OrganizationsModule),
        canActivate: [AuthenticatedGuard, RoleGuard, BrowserSupportedGuard],
        data: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ORGANIZATION_ADMIN']}
    },
    {
        path: 'organization',
        loadChildren: () => import('organization/index').then(m => m.OrganizationModule),
        canActivate: [AuthenticatedGuard, RoleGuard, OrganizationSelectedGuard, BrowserSupportedGuard],
        data: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ORGANIZATION_ADMIN']}
    },
    {
        path: 'project',
        loadChildren: () => import('project/index').then(m => m.ProjectModule),
        canActivate: [AuthenticatedGuard, RoleGuard, OrganizationSelectedGuard, ProjectSelectedGuard, BrowserSupportedGuard],
        data: {roles: ['ROLE_SUPER_ADMIN', 'ROLE_ORGANIZATION_ADMIN']}
    },
    {
        path: 'project-leader',
        loadChildren: () => import('project-leader/index').then(m => m.ProjectLeaderModule),
        canActivate: [AuthenticatedGuard, RoleGuard, UserOrganizationSelectedGuard, BrowserSupportedGuard],
        data: {roles: ['ROLE_PROJECT_LEADER']}
    },
    {
        path: 'subcontractor',
        loadChildren: () => import('subcontractor/index').then(m => m.SubcontractorModule),
        canActivate: [AuthenticatedGuard, RoleGuard, UserOrganizationSelectedGuard, BrowserSupportedGuard],
        data: {roles: ['ROLE_SUBCONTRACTOR_ADMIN', 'ROLE_SUBCONTRACTOR_USER']}
    },
    {
        path: 'profile',
        loadChildren: () => import('user-profile/index').then(m => m.UserProfileModule),
        canActivate: [AuthenticatedGuard, BrowserSupportedGuard]
    },
    {
        path: 'user',
        loadChildren: () => import('user/index').then(m => m.UserModule)
    },
    {
        path: 'browser-not-supported',
        loadChildren: () => import('browser-not-supported/index').then(m => m.BrowserNotSupportedModule)
    },
    {
        path: '**',
        redirectTo: ''
    }
];
export const routing = RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader });
