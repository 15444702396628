import { ApplicationState } from '../state/ApplicationState';
import { Project, Organization } from 'common/types/types';
import { createSelector, select } from '@ngrx/store';
import { map } from 'rxjs/operators';

export const getUserOrganizationSector = createSelector(
    select((state: ApplicationState) => state.settings.userOrganization),
    map((organization: Organization) => organization && organization.sector)
);

export const getActiveProjectFeatures = createSelector(
    select((state: ApplicationState) => state.settings.activeProject),
    map((project: Project) => project ? project.features : null)
);

export const getActiveOrganizationSector = createSelector(
    select((state: ApplicationState) => state.settings.activeOrganization),
    map((organization: Organization) => organization && organization.sector)
);
