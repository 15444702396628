import {Injectable} from '@angular/core';

declare var getPlatform;

@Injectable()
export class Platform {
    private platform: string;

    getPlatform(): string {
        if (!this.platform) {
            this.platform = getPlatform();
        }
        return this.platform;
    }

    isDesktop(): boolean {
        return this.getPlatform() === 'platform-desktop';
    }

    isTablet(): boolean {
        return this.getPlatform() === 'platform-tablet';
    }

    isMobile(): boolean {
        return this.getPlatform() === 'platform-mobile';
    }
}
