import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from './storage';
import { Organization } from '../types/organization';
import { BaseComponent } from '../base.component';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

@Injectable()
export class RecentOrganizationsService extends BaseComponent implements OnDestroy {
    private recentOrganizations: Organization[] = [];
    private recentSubject$ = new Subject<Organization[]>();

    recentOrganizations$: Observable<Organization[]> = this.recentSubject$.pipe(shareReplay(1));

    constructor(private storage: Storage) {
        super();
        this.recentOrganizations$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {});
        this.loadFromStorage();
    }

    loadFromStorage() {
        this.recentOrganizations = [];
        const userId = this.getCurrentUserId();
        const recentData = this.storage.getItem('recentOrganizations');
        if (userId && recentData) {
            const recentObj = JSON.parse(recentData);
            const userOrganizations = recentObj.find((obj: any) => obj.userId === userId);
            this.recentOrganizations = userOrganizations && userOrganizations.organizations ? userOrganizations.organizations : [];
        }
        this.recentSubject$.next(this.recentOrganizations);
    }

    add(organization: Organization) {
        this.recentOrganizations = this.recentOrganizations.filter((org: Organization) => org.id !== organization.id);
        this.recentOrganizations.unshift(cloneDeep(organization));
        this.recentOrganizations = this.recentOrganizations.slice(0, 4);
        this.recentSubject$.next(this.recentOrganizations);
        this.saveToStorage();
    }

    remove(id: string) {
        this.recentOrganizations = this.recentOrganizations.filter((organization: Organization) => organization.id !== id);
        this.recentSubject$.next(this.recentOrganizations);
        this.saveToStorage();
    }

    private saveToStorage() {
        const userId = this.getCurrentUserId();
        if (userId) {
            const recentData = this.storage.getItem('recentOrganizations');
            let recentObj = [];
            if (recentData) {
                recentObj = JSON.parse(recentData);
            }
            const recentForUser = recentObj.find((obj: any) => obj.userId === userId);
            if (recentForUser) {
                Object.assign(recentForUser, {organizations: this.recentOrganizations});
            } else {
                recentObj.push({userId: userId, organizations: this.recentOrganizations});
            }
            this.storage.setItem('recentOrganizations', JSON.stringify(recentObj));
        }
    }

    getMostRecent(): Organization {
        const userId = this.getCurrentUserId();
        const recentData = this.storage.getItem('recentOrganizations');
        if (userId && recentData) {
            const recentObj = JSON.parse(recentData);
            const userOrganizations = recentObj.find((obj: any) => obj.userId === userId);
            return userOrganizations && userOrganizations.organizations && userOrganizations.organizations.length > 0
                ? <Organization>userOrganizations.organizations[0]
                : null;
        }
        return null;
    }

    private getCurrentUserId(): string {
        return this.storage.getItem('userId');
    }
}
