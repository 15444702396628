import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationSandbox } from '../../application.sandbox';
import { Project } from 'common/types/types';
import { Account } from 'user/types/Account';
import { Base64Url } from 'common/utils/base64url';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'redirect',
    template: '<div><loader [useActionColor]="false" size="large"></loader></div>',
    styles: [
        `
            :host, div {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
            }
        `
    ]
})
export class RedirectContainer implements OnInit {
    private redirectBase64: string;

    constructor(private applicationSandbox: ApplicationSandbox,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        this.redirectBase64 = this.route.snapshot.params.data;

        forkJoin(
            this.applicationSandbox.account$.pipe(take(1)),
            this.applicationSandbox.projects$.pipe(take(1))
        ).subscribe(([account, projects]: [Account, Project[]]) => {
            if (account != null) {
                this.loadAllDataAndRedirect(projects);
            } else {
                this.router.navigate(['/user/auth', this.redirectBase64]);
            }
        });
    }

    private loadAllDataAndRedirect(projects: Project[]): void {
        const redirectData = Base64Url.decode(this.redirectBase64);
        try {
            const redirectJson = JSON.parse(redirectData);
            let navigateTo = this.getRouterPath(redirectJson.page);
            if (redirectJson.projectId) {
                const project = projects.find((p: Project) => p.id === redirectJson.projectId);
                if (project) {
                    this.applicationSandbox.projectSelected(project);
                } else {
                    navigateTo = ['/'];
                }
            }
            this.router.navigate(navigateTo);
        } catch (e) {
            this.router.navigate(['/']);
        }
    }

    private getRouterPath(key: string): string[] {
        switch (key) {
            case 'SUBCONTRACTOR_REQUEST_LIST':
                return ['/subcontractor/project/requests'];
            case 'ORDER_LIST':
                return ['/project/order'];
            default:
                return ['/'];
        }
    }
}
