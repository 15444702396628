import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { Member } from 'common/types/types';

export const ActionTypes = {
    MEMBERS_SET_ALL: type<'DATA_MEMBERS_SET_ALL'>('DATA_MEMBERS_SET_ALL'),
    MEMBERS_ADD: type<'DATA_MEMBERS_ADD'>('DATA_MEMBERS_ADD'),
    MEMBERS_UPDATE: type<'DATA_MEMBERS_UPDATE'>('DATA_MEMBERS_UPDATE')
};

export class SetAllMembers implements Action {
    type = ActionTypes.MEMBERS_SET_ALL;
    payload: {members: any[]};

    constructor(members: any[]) {
        this.payload = {members};
    }
}

export class AddMember implements Action {
    type = ActionTypes.MEMBERS_ADD;
    payload: {id: string, member: Member};

    constructor(id: string, member: Member) {
        this.payload = {id, member: {...member}};
    }
}

export class UpdateMember implements Action {
    type = ActionTypes.MEMBERS_UPDATE;
    payload: {id: string, member: Member};

    constructor(id: string, member: Member) {
        this.payload = {id, member: {...member}};
    }
}

export type Actions =
    SetAllMembers
    | AddMember
    | UpdateMember;
