import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Color, Organization, Project, Settings } from 'common/types/types';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Storage } from 'common/services/storage';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import {
    isLoginView, isOrganizationView, isProjectLeaderView, isSubcontractorOrganizationView, isSuperAdminView
} from '../view.utils';
import { mapColorToString } from 'common/utils/utils';

@Injectable({ providedIn: 'root' })
export class ColorService {
    constructor(private store: Store<ApplicationState>,
                private router: Router,
                private storage: Storage) {}

    loadThemeColors(): Observable<boolean> {
        const sector$ = this.store
            .pipe(
                select((state) => state.settings),
                map((settings: Settings) => settings.activeOrganization ? settings.activeOrganization.sector : 'EVENTS'),
                distinctUntilChanged()
            );
        return this.router.events
            .pipe(
                startWith(new NavigationEnd(0, this.router.url, this.router.url)),
                filter((event) => event instanceof NavigationEnd),
                mergeMap((navEnd: NavigationEnd) => sector$.pipe(map((sector) => ({url: navEnd.url, sector})))),
                switchMap((event) => {
                    if (event.url === '/profile' || event.url === '/') {
                        return this.loadColorsFromStorage();
                    } else if (isSuperAdminView(event.url) || isLoginView(event.url)) {
                        return this.loadSuperAdminColors();
                    } else if (isOrganizationView(event.url, event.sector)
                        || isSubcontractorOrganizationView(event.url)
                        || isProjectLeaderView(event.url)) {
                        return this.loadOrganizationColors();
                    } else {
                        return this.loadProjectColors();
                    }
                })
            );
    }

    private loadSuperAdminColors(): Observable<boolean> {
        this.setColors({r: 228, g: 0, b: 43},  {r: 255, g: 255, b: 255});
        return of(true);
    }

    private loadOrganizationColors(): Observable<boolean> {
        return this.store.pipe(
            select((state) => state.settings.userOrganization),
            tap((organization: Organization) => organization
                ? this.setColors(organization.actionColor, organization.actionTextColor)
                : null
            ),
            map(() => true)
        );
    }

    private loadColorsFromStorage(): Observable<boolean> {
        const actionColor = this.storage.getParsedItem<Color>('actionColor');
        const actionTextColor = this.storage.getParsedItem<Color>('actionTextColor');
        this.setColors(actionColor, actionTextColor);
        return of(true);
    }

    private loadProjectColors(): Observable<boolean> {
        return this.store
            .pipe(
                select((state) => state.settings.activeOrganization),
                mergeMap((organization: Organization) => organization && organization.sector === 'INDUSTRY'
                    ? of({actionColor: organization.actionColor, actionTextColor: organization.actionTextColor})
                    : this.store.pipe(
                        select((state) => state.settings.activeProject),
                        filter((project: Project) => project != null),
                        map((project: Project) => ({actionColor: project.actionColor, actionTextColor: project.actionTextColor}))
                    )
                ),
                tap((colors: {actionColor: Color, actionTextColor: Color}) => colors != null
                    ? this.setColors(colors.actionColor, colors.actionTextColor)
                    : null
                ),
                map(() => true)
            );
    }

    private setColors(actionColor: Color, actionTextColor: Color): void {
        const colors = {
            actionColor: actionColor ? actionColor : {r: 228, g: 0, b: 43},
            actionColorHover: actionColor
                ? {r: this.makeDarker(actionColor.r), g: this.makeDarker(actionColor.g), b: this.makeDarker(actionColor.b)}
                : {r: 230, g: 0, b: 34},
            actionTextColor: actionTextColor ? actionTextColor : {r: 255, g: 255, b: 255}
        };
        this.setRootStyleProperties(colors);
        this.saveColorsToStorage(colors);
    }

    private makeDarker(value: number): number {
        return Math.round(value * 0.8);
    }

    private setRootStyleProperties(colors: any): void {
        const root = document.documentElement;
        root.style.setProperty('--action-color', mapColorToString(colors.actionColor));
        root.style.setProperty('--action-hover-color', mapColorToString(colors.actionColorHover));
        root.style.setProperty('--action-text-color', mapColorToString(colors.actionTextColor));
    }

    private saveColorsToStorage(colors: any): void {
        this.storage.setItemStringified('actionColor', colors.actionColor);
        this.storage.setItemStringified('actionTextColor', colors.actionTextColor);
    }
}
