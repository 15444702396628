import { type } from '../util/util';
import { Action } from '@ngrx/store';

export const ActionTypes = {
    SIDENAV_SET_COLLAPSED: type<'CONTAINER_SIDENAV_SET_COLLAPSED'>('CONTAINER_SIDENAV_SET_COLLAPSED'),
    SIDENAV_TOGGLE_COLLAPSED: type<'CONTAINER_SIDENAV_TOGGLE_COLLAPSED'>('CONTAINER_SIDENAV_TOGGLE_COLLAPSED')
};

export class SetSideNavCollapsed implements Action {
    type = ActionTypes.SIDENAV_SET_COLLAPSED;
    payload: {
        collapsed: boolean;
    };

    public constructor(collapsed: boolean) {
        this.payload = {collapsed};
    }
}

export class ToggleSideNavCollapsed implements Action {
    type = ActionTypes.SIDENAV_TOGGLE_COLLAPSED;
}

export type Actions
    = SetSideNavCollapsed
    | ToggleSideNavCollapsed;
