import { Option } from './types/option';

export const LANGUAGES = [
    { value: 'de', label: 'GERMAN'},
    { value: 'en', label: 'ENGLISH'},
    { value: 'fr', label: 'FRENCH'},
    { value: 'hu', label: 'HUNGARIAN'},
    { value: 'nl_BE', label: 'DUTCH_BE'},
    { value: 'nl', label: 'DUTCH_NL'}
];

export function isSupportedLang(language: string): boolean {
    return LANGUAGES.find((lang: Option) => lang.value === language) != null;
}
