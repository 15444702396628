import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Storage } from 'common/services/storage';
import { BaseSidenav } from '../base-sidenav/base-sidenav.component';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

interface MenuItem {
    icon: string;
    label: string;
    routerLink: string;
    active?: boolean;
}

@Component({
    selector: 'project-leader-sidenav',
    templateUrl: './project-leader-sidenav.component.html',
    styleUrls: ['../base-sidenav/base-sidenav.component.scss']
})
export class ProjectLeaderSidenavComponent extends BaseSidenav {

    private projectMenu: MenuItem[] = [
        {icon: 'fa fa-clipboard', label: 'LABELS.REQUESTS', routerLink: '/project-leader/requests'},
        {icon: 'fa fa-map-marker', label: 'LABELS.LOCATIONS', routerLink: '/project-leader/locations'}
    ];

    constructor(protected router: Router,
                protected storage: Storage) {
        super(router, storage);
    }

    protected initMenuLoader() {
        this.router.events
            .pipe(
                startWith(new NavigationEnd(0, this.router.url, null)),
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects ? event.urlAfterRedirects : event.url),
                takeUntil(this.destroyed$)
            )
            .subscribe((url: string) => {
                this.setView(url);
                this.setMenuItems();
                this.setActiveMenuItem(url);
            });
    }

    private setView(url: string): void {
        if (url !== '/profile') {
            this.view = 'project-leader';
            this.storage.setItem('view', this.view);
        } else {
            this.view = this.storage.getItem('view');
        }
    }

    private setMenuItems(): void {
        switch (this.view) {
            case 'project-leader':
                this.menuItems = this.projectMenu;
                this.addPlanningMenuItem();
                break;
        }
    }

    private addPlanningMenuItem() {
        if (this.hasRole(this.account, 'ROLE_PLANNING_VIEWER')
            || this.hasRole(this.account, 'ROLE_PLANNING_VIEWER_OWN_SITES')) {
            this.menuItems = cloneDeep(this.menuItems);
            this.menuItems.push({
                icon: 'fa fa-calendar',
                label: 'LABELS.PLANNING',
                routerLink: '/project-leader/planning'
            });
        }
    }
}
