import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';

import { routing } from './routes';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangHttpInterceptor } from './lang.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { MyDateAdapter } from './date.adapter';
import { StoreModule } from '@ngrx/store';

import { rootReducer, getReducers } from 'statemanagement/rootReducer';
import { RootContainer } from './containers/root/root.container';
import { RedirectContainer } from './containers/redirect/redirect.container';
import { ApplicationContainer } from './containers/application/application.container';
import { SubcontractorSidenavComponent } from './components/sidenav/subcontractor-sidenav/subcontractor-sidenav.component';
import { ProjectLeaderSidenavComponent } from './components/sidenav/project-leader-sidenav/project-leader-sidenav.component';
import { OrganizationSidenavComponent } from './components/sidenav/organization-sidenav/organization-sidenav.component';
import { OrganizationSelectComponent } from './components/organization-select/organization-select.component';
import { ProjectSelectComponent } from './components/project-select/project-select.component';

import { ConfirmDialog } from './controllers/popups/confirm.dialog';
import { ApplicationSandbox } from './application.sandbox';
import { AssetCategoryService } from './services/data/assetcategory.service';
import { RecentProjectsService } from 'common/services/recent-projects.service';
import { RecentOrganizationsService } from 'common/services/recent-organizations.service';
import { OrganizationService } from './services/data/organization.service';
import { ActivityService } from './services/data/activity.service';
import { UserService } from './services/user.service';
import { TempStorage } from 'common/services/temp-storage.service';
import { CommonLogicModule } from '../common';

import { DateAdapter } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { OrderCountService } from './services/order-count.service';
import { TodoCountService } from './services/todo-count.service';
import { ConfigInitializer } from './services/config/config.factory';

import { environment } from '../environments/environment';
import { RentalValidationsService } from './services/rental-validations.service';
import { FeedbackDialogModule } from '../shared/dialogs/feedback';
import { IPService } from './services/ip.service';
import { SwUpdateService } from 'app/external-app-services/sw-update.service';

@NgModule({
    declarations: [
        ApplicationContainer,
        RootContainer,
        RedirectContainer,
        OrganizationSelectComponent,
        ProjectSelectComponent,
        ConfirmDialog,
        OrganizationSidenavComponent,
        SubcontractorSidenavComponent,
        ProjectLeaderSidenavComponent
    ],
    imports: [
        routing,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        CommonLogicModule,
        MatSidenavModule,
        MatMomentDateModule,
        MatSnackBarModule,
        FeedbackDialogModule,
        StoreModule.forRoot(rootReducer),
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        ApplicationSandbox,
        AssetCategoryService,
        ActivityService,
        UserService,
        OrganizationService,
        RecentProjectsService,
        OrderCountService,
        RentalValidationsService,
        TodoCountService,
        RecentOrganizationsService,
        ConfigInitializer.init(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LangHttpInterceptor,
            multi: true
        },
        {
            provide: rootReducer,
            useFactory: getReducers
        },
        {
            provide: DateAdapter,
            useClass: MyDateAdapter
        },
        TempStorage,
        IPService,
        SwUpdateService
    ],
    bootstrap: [ApplicationContainer]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
