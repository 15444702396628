import { Component, Input } from '@angular/core';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @Input() useActionColor = true;
    @Input() size: 'small' | 'large' = 'small';
}
