import { NgModule } from '@angular/core';

import { AuthenticatedGuard } from './authenticated.guard';
import { ProjectSelectedGuard } from './project-selected.guard';
import { ProjectClosedGuard } from './project-closed.guard';
import { OrganizationSelectedGuard } from './organization-selected.guard';
import { UserOrganizationSelectedGuard } from './user-organization-selected.guard';
import { RoleGuard } from './role.guard';
import { BrowserSupportedGuard } from './browser-supported.guard';

@NgModule({
    providers: [
        AuthenticatedGuard,
        ProjectSelectedGuard,
        RoleGuard,
        OrganizationSelectedGuard,
        UserOrganizationSelectedGuard,
        ProjectClosedGuard,
        BrowserSupportedGuard
    ]
})
export class CommonGuardsModule {
}
