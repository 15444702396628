import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { Project } from 'common/types/project';

export const ActionTypes = {
    PROJECTS_SET_ALL: type<'DATA_PROJECTS_SET_ALL'>('DATA_PROJECTS_SET_ALL'),
    PROJECTS_UPDATE: type<'DATA_PROJECTS_UPDATE'>('DATA_PROJECTS_UPDATE')
};

export class SetAllProjects implements Action {
    type = ActionTypes.PROJECTS_SET_ALL;
    payload: {projects: any[]};

    constructor(projects: any[]) {
        this.payload = {projects};
    }
}

export class UpdateProject implements Action {
    type = ActionTypes.PROJECTS_UPDATE;
    payload: {id: string, project: Project};

    constructor(id: string, project: Project) {
        this.payload = {id, project};
    }
}

export type Actions =
    SetAllProjects
    | UpdateProject;

