import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from './storage';
import { Observable, Subject } from 'rxjs';
import { PlanningState } from '../types/planning-state';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

@Injectable()
export class RecentProjectsService extends BaseComponent implements OnDestroy {
    private recentProjects: PlanningState[] = [];
    private recentSubject$ = new Subject<PlanningState[]>();

    recentProjects$: Observable<PlanningState[]> = this.recentSubject$.pipe(shareReplay(1));

    constructor(private storage: Storage) {
        super();
        this.recentProjects$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {});
        this.loadFromStorage();
    }

    loadFromStorage() {
        this.recentProjects = [];
        const userId = this.getCurrentUserId();
        const recentData = this.storage.getItem('recentProjects');
        if (userId && recentData) {
            const recentObj = JSON.parse(recentData);
            const userProjects = recentObj.find((obj: any) => obj.userId === userId);
            this.recentProjects = userProjects && userProjects.projects ? userProjects.projects : [];
        }
        this.recentSubject$.next(this.recentProjects);
    }

    add(id: string) {
        const previousState = this.getPlanningState(id);
        this.recentProjects = this.recentProjects.filter((project: PlanningState) => project.id !== id);
        this.recentProjects.unshift(previousState ? previousState : {id: id, viewType: null, date: null});
        this.recentProjects = this.recentProjects.slice(0, 4);
        this.recentSubject$.next(this.recentProjects);
        this.saveToStorage();
    }

    savePlanningState(projectId: string, viewType: string, date: string): void {
        const project = this.recentProjects.find((p: PlanningState) => p.id === projectId);
        if (project) {
            Object.assign(project, {viewType, date});
        }
        this.saveToStorage();
    }

    remove(id: string) {
        this.recentProjects = this.recentProjects.filter((project: PlanningState) => project.id !== id);
        this.recentProjects = this.recentProjects.slice(0, 4);
        this.recentSubject$.next(this.recentProjects);
        this.saveToStorage();
    }

    private saveToStorage() {
        const userId = this.getCurrentUserId();
        if (userId) {
            const recentData = this.storage.getItem('recentProjects');
            let recentObj = [];
            if (recentData) {
                recentObj = JSON.parse(recentData);
            }
            const recentForUser = recentObj.find((obj: any) => obj.userId === userId);
            if (recentForUser) {
                Object.assign(recentForUser, {projects: this.recentProjects});
            } else {
                recentObj.push({userId: userId, projects: this.recentProjects});
            }
            this.storage.setItem('recentProjects', JSON.stringify(recentObj));
        }
    }

    getMostRecent(): string {
        const userId = this.getCurrentUserId();
        const recentData = this.storage.getItem('recentProjects');
        if (userId && recentData) {
            const recentObj = JSON.parse(recentData);
            const userProjects = recentObj.find((obj: any) => obj.userId === userId);
            return userProjects && userProjects.projects && userProjects.projects.length > 0 ? userProjects.projects[0].id : null;
        }
        return null;
    }

    getPlanningState(projectId: string): PlanningState {
        const userId = this.getCurrentUserId();
        const recentData = this.storage.getItem('recentProjects');
        if (userId && recentData) {
            const recentObj = JSON.parse(recentData);
            const userProjects = recentObj.find((obj: any) => obj.userId === userId);
            return userProjects && userProjects.projects
                ? userProjects.projects.find((p: PlanningState) => p.id === projectId)
                : null;
        }
        return null;
    }

    private getCurrentUserId(): string {
        return this.storage.getItem('userId');
    }
}
