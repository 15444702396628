import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject, merge, timer } from 'rxjs';
import { reloadWindow } from 'app/services/window.service';
import { PopupsController } from 'app/controllers/popups/popups.controller';
@Injectable()
export class SwUpdateService {
    readonly REFRESH_TIMER: number = 3.6e+6;
    constructor(private updates: SwUpdate,
                private appRef: ApplicationRef,
                private router: Router,
                private popupCtrl: PopupsController) {
    }
    public checkForUpdates() {
        if (this.appRef.isStable && environment.production) {
            const refreshTimer$ = new BehaviorSubject(this.REFRESH_TIMER);
            refreshTimer$.pipe(
                distinctUntilChanged(),
                switchMap(refreshTimer =>
                    merge(
                        timer(0, refreshTimer),
                        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
                    )
                )
            ).subscribe({
                next: () => {
                    refreshTimer$.next(this.REFRESH_TIMER);
                    this.updates.checkForUpdate()
                        .then(updateAvailable => updateAvailable ? this.showNewVersionPopup() : null);
                }
            });
        }
    }
    private showNewVersionPopup() {
        this.popupCtrl.create({
            title: 'POPUPS.NEW_VERSION',
            message: 'POPUPS.NEW_VERSION_AVAILABLE_RELOAD_PAGE',
            okText: 'POPUPS.RELOAD',
            width: 300,
            disableClose: true,
            handleOk: () => {
                this.updates.activateUpdate().then(updateActivated => {
                    if (updateActivated) {
                        console.log('New version found and installed by ngsw');
                        reloadWindow();
                    } else {
                        console.log('Could not activate new version');
                    }
                });
            }
        });
    }
}
