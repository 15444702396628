import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonDirectivesModule } from '../directives';
import { CommonPipesModule } from '../pipes';
import { MaterialComponentsModule } from './material-components.module';
import { RouterModule } from '@angular/router';

import { LoaderComponent } from './loader/loader.component';
import { OrderLineLocationMapComponent } from './location/orderline-location-map/orderline-location-map.component';
import { AssetStatusComponent } from './asset-status/asset-status.component';
import { LocationSearchMapComponent } from './location/location-search-map/location-search-map.component';
import { LocationSearchInputComponent } from './location/location-search-input/location-search-input.component';
import { TitleBarComponent } from './title-bar/title-bar/title-bar.component';
import { TitleBarContentComponent } from './title-bar/title-bar-content/title-bar-content.component';
import { TitleBarContainerComponent } from './title-bar/title-bar-container/title-bar-container.component';
import { ListSearchComponent } from './list-search/list-search.component';
import { ListItemComponent } from './list-item/list-item.component';
import { SlideUpDownAnimationWrapperComponent } from './slide-up-down-animation-wrapper/slide-up-down-animation-wrapper.component';
import { LocationPageComponent } from './location/location-page/location-page.component';
import { LocationDetailsPopupComponent } from './location/location-details-popup/location-details-popup.component';
import { ButtonRowComponent } from './button-row/button-row.component';
import { EllipsisMenuComponent } from './ellipsis-menu/ellipsis-menu.component';
import { LocationMapComponent } from './location/location-map/location-map.component';
import { CommonFormComponentsModule } from './form';
import { IconUploadComponent } from './icon-upload/icon-upload.component';
import { AdvancedFilterComponent } from './advanced-filter/advanced-filter.component';
import { SearchTagsBarComponent } from './search-tags-bar/search-tags-bar.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconOrBubbleModule } from 'shared/icon-or-bubble';
import { ContactPersonComponent } from './contact-person/contact-person.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        CommonFormComponentsModule,
        MaterialComponentsModule,
        CommonDirectivesModule,
        CommonPipesModule,
        ScrollingModule,
        IconOrBubbleModule
    ],
    declarations: [
        LoaderComponent,
        OrderLineLocationMapComponent,
        AdvancedFilterComponent,
        SearchTagsBarComponent,
        FilterSearchComponent,
        AssetStatusComponent,
        LocationSearchMapComponent,
        LocationSearchInputComponent,
        LocationDetailsPopupComponent,
        LocationPageComponent,
        TitleBarComponent,
        TitleBarContentComponent,
        TitleBarContainerComponent,
        ListSearchComponent,
        ListItemComponent,
        SlideUpDownAnimationWrapperComponent,
        ButtonRowComponent,
        EllipsisMenuComponent,
        LocationMapComponent,
        IconUploadComponent,
        ContactPersonComponent
    ],
    exports: [
        MaterialComponentsModule,
        CommonFormComponentsModule,
        LoaderComponent,
        OrderLineLocationMapComponent,
        AdvancedFilterComponent,
        SearchTagsBarComponent,
        FilterSearchComponent,
        AssetStatusComponent,
        LocationSearchMapComponent,
        LocationDetailsPopupComponent,
        LocationSearchInputComponent,
        LocationPageComponent,
        TitleBarComponent,
        TitleBarContentComponent,
        TitleBarContainerComponent,
        ListSearchComponent,
        ListItemComponent,
        SlideUpDownAnimationWrapperComponent,
        ButtonRowComponent,
        EllipsisMenuComponent,
        LocationMapComponent,
        IconUploadComponent,
        ContactPersonComponent
    ]
})
export class CommonComponentsModule {
}
