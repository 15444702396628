import * as supplier from '../../actions/data/supplier';
import { Supplier } from 'common/types/supplier';

export function suppliersReducer(state: Supplier[] = [], action: supplier.Actions): Supplier[] {
    if (action.type === supplier.ActionTypes.SUPPLIERS_SET_ALL) {
        return [...action.payload.suppliers];
    } else {
        return state;
    }
}
