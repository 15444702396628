import { Injectable } from '@angular/core';
import { AuthHttp } from 'common/services/auth-http.service';
import { HttpHeaders } from '@angular/common/http';
import { Subcontractor } from 'common/types/subcontractor';
import { Ms } from 'common/microservices.enum';
import { Observable, of } from 'rxjs';
import { cloneAndRemoveNulls } from 'common/utils/utils';
import { map } from 'rxjs/operators';
import * as jsonpatch from 'fast-json-patch';

@Injectable({providedIn: 'root'})
export class SubcontractorService {

    constructor(protected http: AuthHttp) {}

    findAll(): Observable<Subcontractor[]> {
        const headers = new HttpHeaders({
            Accept: 'application/vnd.tvh.subcontractors.v1+json'
        });
        return this.http.get(Ms.Organizations, '/subcontractors', {headers}, 'active')
            .pipe(
                map((res: any) => res && res.subcontractors ? res.subcontractors : [])
            );
    }

    create(subcontractor: Subcontractor): Observable<string> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.subcontractors.v1+json'
        });
        const body = this.mapObject(subcontractor);
        return this.http.post(Ms.Organizations, '/subcontractors', body, {headers, observe: 'response'}, 'active')
            .pipe(map((res) => {
                const location = res.headers.get('Location');
                return location && location.substring(location.lastIndexOf('/') + 1);
            }));
    }

    findOne(id: string): Observable<Subcontractor> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.subcontractors.v1+json'
        });
        return this.http.get(Ms.Organizations, '/subcontractors/' + id, {headers}, 'active');
    }

    update(id, oldSubcontractorDetails: Subcontractor, newSubcontractorDetails: Subcontractor): Observable<any> {
        const operations = jsonpatch.compare(
            this.mapObject(oldSubcontractorDetails),
            this.mapObject(newSubcontractorDetails)
        );
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.subcontractors.v1+json'
        });
        return operations.length > 0
            ? this.http.patch(Ms.Organizations, '/subcontractors/' + id, operations, {headers}, 'active')
            : of(false);
    }

    private mapObject(subcontractor: Subcontractor): Subcontractor {
        const mapped = cloneAndRemoveNulls(subcontractor);
        if (mapped.vatNumber) {
            mapped.vatNumber = mapped.vatNumber.replace(/\./g, '');
        }
        return mapped;
    }
}
