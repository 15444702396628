import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import {
    AssetAccessory, AssetCategory, AssetSpecification, AssetSubCategory, CodeDescription, Organization, Project, Supplier
} from 'common/types/types';
import { Account } from 'user/types/Account';

import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { SetAllAssetCategories } from 'statemanagement/actions/data/assetcategory';
import { SetAllSuppliers } from '../statemanagement/actions/data/supplier';
import { SetAllZones } from 'statemanagement/actions/data/zone';
import { SetAppReady, SetBrowserSupported, UpdateSettings } from 'statemanagement/actions/settings';
import { SetPlanning } from 'statemanagement/actions/data/planning';
import { SetAllProjects } from 'statemanagement/actions/data/projects';
import { ClearAuthentication, SetAccount } from 'statemanagement/actions/data/autentication';
import { SetSideNavCollapsed, ToggleSideNavCollapsed } from 'statemanagement/actions/sidenav';

import { MyTranslateService } from '../common/services/translate.service';
import { OrderCountService } from './services/order-count.service';
import { AssetCategoryService } from './services/data/assetcategory.service';
import { ProjectService } from './services/data/project.service';
import { RecentOrganizationsService } from 'common/services/recent-organizations.service';
import { RecentProjectsService } from 'common/services/recent-projects.service';
import { AuthService } from 'common/services/auth.service';
import { AuthenticationResult } from 'user/types/AuthenticationResult';
import { OrganizationService } from './services/data/organization.service';
import { TempStorage } from 'common/services/temp-storage.service';
import { UserService } from './services/user.service';
import { Storage } from 'common/services/storage';
import { ColorService } from './services/color.service';
import { CacheService } from './services/cache/cache.service';
import { getActiveProjectFeatures } from '../statemanagement/selectors/settings.selectors';
import { loadCodeLabelMapping } from '../common/utils/search.utils';
import { catchError, distinctUntilChanged, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { MaintenanceService } from './services/maintenance.service';
import * as Bowser from 'bowser';
import { IPService } from './services/ip.service';

@Injectable()
export class ApplicationSandbox {
    private isAuthenticated$ = this.store.pipe(
        select((state) => state.data.authentication),
        map((auth) => auth && auth.isAuthenticated),
        shareReplay(1)
    );

    account$ = this.store.pipe(
        select((state) => state.data.authentication),
        map((auth) => auth && auth.account ? auth.account : null),
        shareReplay(1)
    );
    settings$ = this.store.pipe(select((state) => state.settings));
    projects$ = this.store.pipe(select((state) => state.data.projects));
    sideNavCollapsed$ = this.store.pipe(select((state) => state.sideNav.collapsed));
    recentOrganizations$ = this.recentOrganizationsService.recentOrganizations$;
    recentProjects$ = this.recentProjectsService.recentProjects$;
    orderCount$ = this.orderCountService.orderCount$;
    projectFeatures$ = this.store.pipe(getActiveProjectFeatures);
    browserSupported = true;

    constructor(private store: Store<ApplicationState>,
                private assetCategoryService: AssetCategoryService,
                private storage: Storage,
                private translate: MyTranslateService,
                private projectService: ProjectService,
                private recentProjectsService: RecentProjectsService,
                private recentOrganizationsService: RecentOrganizationsService,
                private authService: AuthService,
                private userService: UserService,
                private organizationService: OrganizationService,
                private tempStorage: TempStorage,
                private orderCountService: OrderCountService,
                private colorService: ColorService,
                private cacheService: CacheService,
                private maintenanceService: MaintenanceService,
                private ipService: IPService
    ) {
    }

    getIPInfo(): Observable<string> {
        return this.ipService.getIPInfo();
    }

    loadRecentProjects() {
        this.recentProjectsService.loadFromStorage();
    }

    loadOrganizationToStore(): Observable<Organization> {
        return this.account$.pipe(
            take(1),
            mergeMap((account: Account) => {
                if (account) {
                    const organizationId = this.getOrganizationId(account);
                    const isSubcontractor = this.hasRole(account, ['ROLE_SUBCONTRACTOR_ADMIN', 'ROLE_SUBCONTRACTOR_USER']);
                    if (organizationId) {
                        return this.organizationService
                            .findOne(organizationId)
                            .pipe(
                                tap((org: Organization) => {
                                    this.recentOrganizationsService.add(org);
                                    this.store.dispatch(new UpdateSettings({userOrganization: org}));
                                    if (!isSubcontractor) {
                                        this.store.dispatch(new UpdateSettings({activeOrganization: org}));
                                    }
                                }),
                                catchError((err) => {
                                    this.recentOrganizationsService.remove(organizationId);
                                    this.store.dispatch(new UpdateSettings({
                                        userOrganization: null,
                                        activeOrganization: null
                                    }));
                                    return throwError(err);
                                })
                            );
                    }
                }
                this.store.dispatch(new UpdateSettings({userOrganization: null, activeOrganization: null}));
                return of(null);
            })
        );
    }

    private getOrganizationId(account): string {
        const organization = this.recentOrganizationsService.getMostRecent();
        if (!organization && account.organization && account.organization.organizationId) {
            return account.organization.organizationId;
        }
        return organization?.id;
    }

    private hasRole(account: Account, roles: string[]): boolean {
        return account && account.authorities
            && account.authorities.find((role: string) => roles.indexOf(role) !== -1) != null;
    }

    loadCurrentUserInfoToStore() {
        return this.userService
            .findCurrentUserInfo()
            .pipe(
                tap((account) => this.store.dispatch(new SetAccount(account)))
            );
    }

    setAppReady() {
        this.store.dispatch(new SetAppReady());
    }

    watchProjects(): Observable<Project> {
        return this.store
            .pipe(
                select((state) => state.data.projects),
                mergeMap((projects: Project[]) => this.updateActiveProject(projects))
            );
    }

    private updateActiveProject(projects: Project[]): Observable<Project> {
        return this.store
            .pipe(
                select((state) => state.settings.activeProject),
                take(1),
                tap((project: Project) => this.updateCurrentProjectIfChanged(projects, project))
            );
    }

    private updateCurrentProjectIfChanged(projects: Project[], project: Project) {
        if (projects != null && project != null) {
            const current = projects.find((p: Project) => p.id === project.id);
            if (current != null && !isEqual(project, current)) {
                this.store.dispatch(new UpdateSettings({activeProject: current}));
            }
        }
    }

    loadCategoriesConditionally(): Observable<boolean> {
        return this.isAuthenticated$
            .pipe(
                switchMap((auth: boolean) => {
                    if (auth) {
                        return this.store
                            .pipe(
                                select((state) => state.settings.activeOrganization),
                                tap((org: Organization) => this.tempStorage.setItem('sector', org ? org.sector : null)),
                                map((org: Organization) => org ? org.id : null),
                                distinctUntilChanged(),
                                switchMap((orgId: string) => orgId ? this.loadAssetCategoriesToStore() : of(false))
                            );
                    } else {
                        return of(false);
                    }
                })
            );
    }

    loadProjectsToStore(type?: 'ORGANIZATION' | 'SUBCONTRACTOR'): Observable<Project[]> {
        return this.projectService
            .findAll(type)
            .pipe(tap((projects: Project[]) => this.saveProjectsToState(projects)));
    }

    private saveProjectsToState(projects: Project[]) {
        this.store.dispatch(new SetAllProjects(projects));
        const currentProjectId = this.recentProjectsService.getMostRecent();
        const currentProject = projects.find((proj: Project) => proj.id === currentProjectId);
        this.store.dispatch(new UpdateSettings({activeProject: currentProject}));
        this.projectSelected(currentProject);
        if (!currentProject) {
            this.recentProjectsService.remove(currentProjectId);
        }
    }

    setIsBrowserSupportedToStore() {
        const browser = Bowser.getParser(window.navigator.userAgent);
        if (browser.satisfies({
            chrome: '>=60',
            chromium: '>=60',
            firefox: '>=58',
            edge: '>=16',
            safari: '>=10'
        })) {
            this.store.dispatch(new SetBrowserSupported(true));
            this.browserSupported = true;
        } else {
            this.store.dispatch(new SetBrowserSupported(false));
            this.browserSupported = false;
        }
    }

    loginUserWithToken(): Observable<AuthenticationResult> {
        if (this.authService.isRefreshTokenExpired()) {
            this.logout();
            return throwError(new Error('Refresh token expired'));
        } else {
            return this.authService.loginWithRefreshToken(true);
        }
    }

    loadAssetCategoriesToStore(): Observable<boolean> {
        return this.assetCategoryService
            .findAll()
            .pipe(
                tap((data: { suppliers: Supplier[], categories: AssetCategory[] }) => {
                    loadCodeLabelMapping(data.categories);
                    this.store.dispatch(new SetAllAssetCategories(data.categories));
                    this.loadCategoryTranslations(data.categories);
                }),
                mergeMap((data: { suppliers: Supplier[], categories: AssetCategory[] }) => this.translateOpCoNames(data.suppliers)),
                map(() => true)
            );
    }

    private translateOpCoNames(suppliers: Supplier[]): Observable<Supplier[]> {
        const opCoTranslateLabels = this.getOpCoTranslateLabels(suppliers);
        if (opCoTranslateLabels.length > 0) {
            return this.translate
                .getOnce(opCoTranslateLabels)
                .pipe(
                    map((translations) => {
                        const translatedSuppliers = suppliers.map((supplier: Supplier) => this.mapOpCoName(supplier, translations));
                        this.store.dispatch(new SetAllSuppliers(translatedSuppliers));
                        return translatedSuppliers;
                    })
                );
        } else {
            this.store.dispatch(new SetAllSuppliers(suppliers));
            return of(suppliers);
        }
    }

    private getOpCoTranslateLabels(suppliers: Supplier[]): string[] {
        const opCoTranslateLabels = [];
        suppliers
            .filter((supplier: Supplier) => supplier.type === 'OPCO')
            .forEach((supplier: Supplier) => {
                opCoTranslateLabels.push('OPCOS.' + supplier.value);
                opCoTranslateLabels.push('OPCOS.SHORT.' + supplier.value);
            });
        return opCoTranslateLabels;
    }

    private mapOpCoName(supplier: Supplier, translations): Supplier {
        return supplier.type === 'OPCO'
            ? Object.assign({}, supplier, {
                name: translations['OPCOS.' + supplier.value],
                shortName: translations['OPCOS.SHORT.' + supplier.value]
            })
            : supplier;
    }

    private loadCategoryTranslations(categories: AssetCategory[]): void {
        const translated = {
            CATEGORIES: {},
            ENGINETYPES: {},
            MASTTYPES: {},
            OPERATOR_POSITIONS: {},
            INDOOR_OUTDOOR_USE: {},
            OUTRIGGERS: {},
            OPTIONS: {},
            ACCESSORIES: {}
        };
        categories.forEach((category: AssetCategory) => {
            translated.CATEGORIES[category.code] = category.name;
            if (category.subcategories != null) {
                category.subcategories.forEach((subcat: AssetSubCategory) => {
                    translated.CATEGORIES[subcat.code] = subcat.name;
                    if (subcat.specifications) {
                        subcat.specifications.forEach((specs: AssetSpecification) => {
                            if (specs.engineType) {
                                translated.ENGINETYPES[specs.engineType.code] = specs.engineType.description;
                            }
                            if (specs.mastType) {
                                translated.MASTTYPES[specs.mastType.code] = specs.mastType.description;
                            }
                            if (specs.operatorPosition) {
                                translated.OPERATOR_POSITIONS[specs.operatorPosition.code] = specs.operatorPosition.description;
                            }
                            if (specs.indoorOutdoorUse) {
                                translated.INDOOR_OUTDOOR_USE[specs.indoorOutdoorUse.code] = specs.indoorOutdoorUse.description;
                            }
                            if (specs.outriggers) {
                                translated.OUTRIGGERS[specs.outriggers.code] = specs.outriggers.description;
                            }
                            this.addOptions(specs, translated);
                            this.addAccessories(specs, translated);
                        });
                    }
                });
            }
        });
        const userLang = this.storage.getItem('language');
        const lang = userLang ? userLang : 'en';
        this.translate.setTranslation(lang, translated);
    }

    private addOptions(object, translated): void {
        if (object.options) {
            object.options.forEach((option: CodeDescription) => {
                translated.OPTIONS[option.code] = option.description;
            });
        }
    }

    private addAccessories(object, translated): void {
        if (object.accessories) {
            object.accessories.forEach((accessory: AssetAccessory) => {
                translated.ACCESSORIES[accessory.code] = accessory.description;
            });
        }
    }

    projectSelected(project: Project): void {
        const newSettings: any = {
            activeProject: project
        };
        if (project != null) {
            this.recentProjectsService.add(project.id);
            if (project.organization != null) {
                newSettings.activeOrganization = {...project.organization, id: project.organization.organizationId};
            }
        }
        this.cacheService.resetProjectCaches();
        this.store.dispatch(new SetPlanning(null));
        this.store.dispatch(new UpdateSettings(newSettings));
    }

    organizationSelected(organization: Organization): void {
        this.recentOrganizationsService.add(organization);
        this.cacheService.resetCaches();
        this.store.dispatch(new SetPlanning(null));
        this.store.dispatch(new SetAllProjects([]));
        this.store.dispatch(new UpdateSettings({
            userOrganization: organization,
            activeOrganization: organization,
            activeProject: null
        }));
    }

    setSideNavState(isCollapsed: boolean): void {
        this.store.dispatch(new SetSideNavCollapsed(isCollapsed));
    }

    toggleSideNavState(): void {
        this.store.dispatch(new ToggleSideNavCollapsed());
    }

    logout(): void {
        this.store.dispatch(new ClearAuthentication());
        this.store.dispatch(new UpdateSettings({
            userOrganization: null,
            activeOrganization: null,
            activeProject: null
        }));
        this.store.dispatch(new SetAllProjects([]));
        this.store.dispatch(new SetAllZones([]));
        this.store.dispatch(new SetAllAssetCategories([]));
        this.store.dispatch(new SetPlanning(null));
        this.cacheService.resetCaches();
        this.storage.removeItem('token');
        this.storage.removeItem('refreshToken');
        this.storage.removeItem('userId');
        sessionStorage.clear();
    }

    loadThemeColors(): Observable<boolean> {
        return this.colorService.loadThemeColors();
    }

    findMaintenanceModeEnabled(): Observable<boolean> {
        return this.maintenanceService.findMaintenanceModeEnabled();
    }

    removeSplashScreen(timeout = 0) {
        const splashElement = document.getElementById('splash');
        if (splashElement) {
            splashElement.parentElement.removeChild(splashElement);
        }

        setTimeout(() => {
            if (document.body) {
                document.body.style.backgroundImage = 'none';
            }
        }, timeout);
    }
}
