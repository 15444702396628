import { AssetCategory, AssetSpecification, AssetSubCategory, PlanningOrderLine, Zone } from '../types/types';
import { RequesterRequest } from 'shared/requester-requests/types/requester-request';
import { RequesterBooking } from 'shared/requester-requests/types/requester-booking';
import { SubcontractorAsset } from '../../subcontractor/types/subcontractor-asset';
import { formatCapacity } from './utils';
import { deburr } from 'lodash-es';

const codeLabelMapping = {
    category: {},
    subcategory: {},
    engineType: {},
    liftingHeight: {},
    workingHeight: {},
    liftingCapacity: {},
    platformWidth: {},
    wheelCount: {},
    mastType: {},
    operatorPosition: {},
    indoorOutdoorUse: {},
    outriggers: {},
    options: {},
    accessories: {},
    filterStatus: {},
    requestStatus: {}
};

export function planningOrderLineWithBookingsMatchesSearchTerms(orderLine: PlanningOrderLine, searchTerms: string[]): boolean {
    if (searchTerms) {
        for (const searchTerm of searchTerms) {
            if (!planningOrderLineWithBookingsMatchesSearchTerm(orderLine, searchTerm)) {
                return false;
            }
        }
    }
    return true;
}

function planningOrderLineWithBookingsMatchesSearchTerm(orderLine: PlanningOrderLine, searchTerm: string): boolean {
    for (const prop of Object.keys(orderLine)) {
        if (planningOrderLineWithBookingsPropertyMatchesSearchTerm(orderLine, searchTerm, prop)) {
            return true;
        }
    }
    return false;
}

function planningOrderLineWithBookingsPropertyMatchesSearchTerm(orderLine: PlanningOrderLine, searchTerm: string, prop: string): boolean {
    return assetDetailsMatchesSearchTerm(orderLine, searchTerm, prop)
        || planningOrderLineMatchesSearchTerm(orderLine, searchTerm, prop)
        || assetNameIdMatchesSearchTerm(orderLine, searchTerm, prop)
        || requestMatchesSearchTerm(orderLine, searchTerm, prop)
        || requesterRequestDetailsMatchesSearchTerm(orderLine, searchTerm, prop)
        || (orderLine.bookings && orderLine.bookings.length > 0 && bookingsMatchesSearchTerm(orderLine.bookings, searchTerm));
}

export function requesterAssetMatchesSearchTerm(asset: SubcontractorAsset, searchTerm: string): boolean {
    if (searchTerm != null && searchTerm.length > 0) {
        for (const prop of Object.keys(asset)) {
            if (requesterAssetPropertyMatchesSearchTerm(asset, searchTerm, prop)) {
                return true;
            }
        }
        return false;
    } else {
        return true;
    }
}

function requesterAssetPropertyMatchesSearchTerm(asset: SubcontractorAsset, searchTerm: string, prop: string): boolean {
    return assetDetailsMatchesSearchTerm(asset, searchTerm, prop)
        || assetNameIdMatchesSearchTerm(asset, searchTerm, prop)
        || requestMatchesSearchTerm(asset, searchTerm, prop);
}

export function requesterRequestMatchesSearchTerms(request: Request|RequesterRequest, searchTerms: string[]): boolean {
    if (searchTerms) {
        for (const searchTerm of searchTerms) {
            if (!requesterRequestMatchesSearchTerm(request, searchTerm)) {
                return false;
            }
        }
    }
    return true;
}

function requesterRequestMatchesSearchTerm(request: Request|RequesterRequest, searchTerm: string): boolean {
    for (const prop of Object.keys(request)) {
        if (requesterRequestPropertyMatchesSearchTerm(request, searchTerm, prop)) {
            return true;
        }
    }
    if (request.hasOwnProperty('booking')) {
        request = request as RequesterRequest;
        for (const prop of Object.keys(request.booking)) {
            if (requesterBookingPropertyMatchesSearchTerm(request.booking, searchTerm, prop)) {
                return true;
            }
        }
    }
    return false;
}

function requesterRequestPropertyMatchesSearchTerm(request: Request|RequesterRequest, searchTerm: string, prop: string): boolean {
    return assetDetailsMatchesSearchTerm(request, searchTerm, prop)
        || requesterRequestDetailsMatchesSearchTerm(request, searchTerm, prop)
        || requestMatchesSearchTerm(request, searchTerm, prop);
}

function requesterBookingPropertyMatchesSearchTerm(booking: RequesterBooking, searchTerm: string, prop: string): boolean {
    return assetNameIdMatchesSearchTerm(booking, searchTerm, prop)
        || assetDetailsMatchesSearchTerm(booking, searchTerm, prop);
}

export function assetMatchesSearchTerms(asset, searchTerms: string[]): boolean {
    if (searchTerms) {
        for (const searchTerm of searchTerms) {
            if (!assetMatchesSearchTerm(asset, searchTerm)) {
                return false;
            }
        }
    }
    return true;
}

function assetMatchesSearchTerm(asset, searchTerm: string): boolean {
    for (const prop of Object.keys(asset)) {
        if (assetPropertyMatchesSearchTerm(asset, searchTerm, prop)
            || requestMatchesSearchTerm(asset, searchTerm, prop)) {
            return true;
        }
    }
    return false;
}

function assetPropertyMatchesSearchTerm(asset, searchTerm: string, prop: string): boolean {
    return assetDetailsMatchesSearchTerm(asset, searchTerm, prop)
        || assetNameIdMatchesSearchTerm(asset, searchTerm, prop);
}

function assetDetailsMatchesSearchTerm(assetDetails, searchTerm, prop): boolean {
    let value = assetDetails[prop];
    switch (prop) {
        case 'category':
        case 'subcategory':
            value = codeLabelMapping[prop] && codeLabelMapping[prop][assetDetails[prop]];
            return containsLowerCase(value, searchTerm);
        case 'specification':
            for (const spec of Object.keys(assetDetails[prop])) {
                const specValue = codeLabelMapping[spec] && codeLabelMapping[spec][value[spec]];
                if (containsLowerCase(specValue, searchTerm)) {
                    return true;
                }
            }
            return false;
        case 'options':
        case 'accessories':
            return value
                && value.find((option: string) => containsLowerCase(codeLabelMapping[prop][option], searchTerm)) != null;
        default:
            return false;
    }
}

function planningOrderLineMatchesSearchTerm(planningOrderLine: PlanningOrderLine, searchTerm: string, prop: string): boolean {
    let value = planningOrderLine[prop];
    switch (prop) {
        case 'supplierAssetId':
        case 'supplierContractId':
        case 'comments':
            return containsLowerCase(value, searchTerm);
        case 'filterStatus':
            value = codeLabelMapping[prop][planningOrderLine[prop]];
            return containsLowerCase(value, searchTerm);
        default:
            return false;
    }
}

function assetNameIdMatchesSearchTerm(asset, searchTerm: string, prop: string): boolean {
    const value = asset[prop];
    switch (prop) {
        case 'assetName':
        case 'customerAssetId':
        case 'supplierAssetId':
            return containsLowerCase(value, searchTerm);
        default:
            return false;
    }
}

function bookingsMatchesSearchTerm(bookings: any[], searchTerm: string): boolean {
    for (const booking of bookings) {
        if (booking.subcontractor != null && containsLowerCase(booking.subcontractor.name, searchTerm)
            || booking.comments != null && containsLowerCase(booking.comments, searchTerm)
            || booking.activity != null && codeOrName(booking.activity, searchTerm)
            || booking.zone != null && (codeOrName(booking.zone, searchTerm)
            || booking.zone.projectLeader != null && containsLowerCase(booking.zone.projectLeader.firstName + ' ' + booking.zone.projectLeader.lastName, searchTerm))
            || booking.stage != null && codeOrName(booking.stage, searchTerm)) {
            return true;
        }
    }
    return false;
}

function requestMatchesSearchTerm(request, searchTerm: string, prop: string): boolean {
    const value = request[prop];
    switch (prop) {
        case 'zone':
        case 'stage':
        case 'activity':
            return codeOrName(value, searchTerm);
        default:
            return false;
    }
}

function requesterRequestDetailsMatchesSearchTerm(requestRequest, searchTerm: string, prop: string): boolean {
    let value = requestRequest[prop];
    switch (prop) {
        case 'requester':
            return firstOrLastName(value, searchTerm);
        case 'status':
            value = codeLabelMapping.requestStatus[requestRequest[prop]];
            return containsLowerCase(value, searchTerm);
        case 'subcontractor':
            return containsLowerCase(value ? value.name : null, searchTerm);
        default:
            return false;
    }
}

export function zoneMatchesSearchTerms(zone: Zone, searchTerms: string[]): boolean {
    if (searchTerms) {
        for (const searchTerm of searchTerms) {
            if (!zoneMatchesSearchTerm(zone, searchTerm)) {
                return false;
            }
        }
    }
    return true;
}

export function zoneMatchesSearchTerm(zone: Zone, searchTerm: string): boolean {
    searchTerm = toSearchString(searchTerm);
    return toSearchString(zone.name).includes(searchTerm)
        || (zone.code && toSearchString(zone.code).includes(searchTerm))
        || (zone.projectLeader
            && toSearchString(zone.projectLeader.firstName + ' ' + zone.projectLeader.lastName).includes(searchTerm))
        || (zone.siteLeader
            && toSearchString(zone.siteLeader.firstName + ' ' + zone.siteLeader.lastName).includes(searchTerm));
}

function codeOrName(searchObject, searchTerm: string): boolean {
    return searchObject
        && (containsLowerCase(searchObject.name, searchTerm)
        || containsLowerCase(searchObject.code, searchTerm));
}

function firstOrLastName(searchObject, searchTerm: string): boolean {
    return searchObject
        && containsLowerCase(searchObject.firstName + ' ' + searchObject.lastName, searchTerm);
}


function containsLowerCase(value: string, term: string): boolean {
    return value != null && toSearchString(value).includes(toSearchString(term));
}

export function loadCodeLabelMapping(assetCategories: AssetCategory[]): void {
    assetCategories.forEach((assetCategory: AssetCategory) => {
        codeLabelMapping.category[assetCategory.code] = assetCategory.name;
        if (assetCategory.subcategories) {
            assetCategory.subcategories.forEach((subcategory: AssetSubCategory) => {
                codeLabelMapping.subcategory[subcategory.code] = subcategory.name;
                if (subcategory.specifications) {
                    subcategory.specifications.forEach((specs: AssetSpecification) => {
                        if (specs.engineType) {
                            codeLabelMapping.engineType[specs.engineType.code] = specs.engineType.description;
                        }
                        if (specs.liftingHeight) {
                            codeLabelMapping.liftingHeight[specs.liftingHeight] = (specs.liftingHeight / 100) + 'm';
                        }
                        if (specs.workingHeight) {
                            codeLabelMapping.workingHeight[specs.workingHeight] = (specs.workingHeight / 100) + 'm';
                        }
                        if (specs.liftingCapacity) {
                            codeLabelMapping.liftingCapacity[specs.liftingCapacity] = formatCapacity(specs.liftingCapacity);
                        }
                        if (specs.platformWidth) {
                            codeLabelMapping.platformWidth[specs.platformWidth] = (specs.platformWidth / 100) + 'm';
                        }
                        if (specs.wheelCount) {
                            codeLabelMapping.wheelCount[specs.wheelCount] = specs.wheelCount;
                        }
                        if (specs.mastType) {
                            codeLabelMapping.mastType[specs.mastType.code] = specs.mastType.description;
                        }
                        if (specs.operatorPosition) {
                            codeLabelMapping.operatorPosition[specs.operatorPosition.code] = specs.operatorPosition.description;
                        }
                        if (specs.options) {
                            specs.options.forEach((option) => {
                                codeLabelMapping.options[option.code] = option.description;
                            });
                        }
                        if (specs.accessories) {
                            specs.accessories.forEach((accessory) => {
                                codeLabelMapping.accessories[accessory.code] = accessory.description;
                            });
                        }
                    });
                }
            });
        }
    });
}

export function setFilterStatusMapping(statuses) {
    for (const status of Object.keys(statuses)) {
        codeLabelMapping.filterStatus[status.replace('LABELS.', '')] = statuses[status];
    }
}

export function setRequestStatusMapping(statuses) {
    for (const status of Object.keys(statuses)) {
        codeLabelMapping.requestStatus[status.replace('LABELS.', '')] = statuses[status];
    }
}

export function toSearchString(value: string): string {
    if (value != null) {
        value = value
            .toString()
            .toLowerCase()
            .replace(new RegExp('\\s', 'g'), '');
        return deburr(value)
            .replace(new RegExp('\\W', 'g'), '');
    } else {
        return '';
    }
}
