import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TodoCountService {
    toDoCount$ = new ReplaySubject<Observable<number>>();

    setCountObservables(counts$: Array<Observable<number>>) {
        counts$ = counts$ ? counts$.filter((count$) => count$ != null) : [];
        if (counts$.length > 0) {
            const toDoCount$ = combineLatest(counts$)
                .pipe(
                    map((counts: number[]) => counts.reduce((a, b) => a + b, 0))
                );
            this.toDoCount$.next(toDoCount$);
        } else {
            this.toDoCount$.next(of(0));
        }
    }
}
