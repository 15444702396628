import { Injectable } from '@angular/core';
import { AuthHttp } from 'common/services/auth-http.service';
import { HttpHeaders } from '@angular/common/http';
import { RentalValidations } from 'common/types/rental-validations.interface';
import { Ms } from 'common/microservices.enum';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class RentalValidationsService {

    constructor(protected http: AuthHttp) {}

    findAll(): Observable<RentalValidations> {
        const headers = new HttpHeaders({
            Accept: 'application/vnd.tvh.validations.v1+json'
        });
        return this.http.get(Ms.MyAssetPlanner, '/rental/validations', {headers}, 'active', true, 'headers');
    }
}
