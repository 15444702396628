import { Injectable } from '@angular/core';
import { TempStorage } from './temp-storage.service';

@Injectable()
export class Storage {
    private storageImpl;

    setItem(key: string, value) {
        this.get().setItem(key, value);
    }

    setItemStringified(key: string, value) {
        this.get().setItem(key, JSON.stringify(value));
    }

    getItem(key: string): string {
        return this.get().getItem(key);
    }

    getParsedItem<T>(key: string): T {
        const item = this.getItem(key);
        try {
            return JSON.parse(item);
        } catch (e) {
            return null;
        }
    }

    removeItem(key: string) {
        this.get().removeItem(key);
    }

    private get() {
        if (!this.storageImpl) {
            try {
                localStorage.setItem('storage', '');
                localStorage.removeItem('storage');
                this.storageImpl = localStorage;
            } catch (err) {
                this.storageImpl = new TempStorage();
            }
        }
        return this.storageImpl;
    }
}
