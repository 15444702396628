import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Storage } from './services/storage';
import { Platform } from './services/platform';
import { AuthHttp } from './services/auth-http.service';
import { AuthService } from './services/auth.service';

import { CommonPipesModule } from './pipes';
import { CommonGuardsModule } from './guards';
import { CommonDirectivesModule } from './directives';
import { CommonComponentsModule } from './components';
import { MyTranslateService } from './services/translate.service';
import { CommonDialogsModule } from './dialogs';
import { IconOrBubbleModule } from '../shared/icon-or-bubble';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        CommonPipesModule,
        CommonGuardsModule,
        CommonDirectivesModule,
        CommonComponentsModule,
        CommonDialogsModule,
        IconOrBubbleModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        CommonPipesModule,
        CommonDirectivesModule,
        CommonComponentsModule,
        IconOrBubbleModule
    ],
    providers: [
        Storage,
        Platform,
        AuthHttp,
        AuthService,
        MyTranslateService
    ]
})
export class CommonLogicModule {}
