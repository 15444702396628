import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonDirectivesModule } from '../../directives';
import { CommonPipesModule } from '../../pipes';
import { RouterModule } from '@angular/router';
import { CommonSelectBoxComponentsModule } from './select-boxes';

import { DatePickerComponent } from './date/date-picker/date-picker.component';
import { TimePickerComponent } from './date/time-picker/time-picker.component';
import { NumberPickerComponent } from './number-picker/number-picker.component';
import { FormPartComponent } from './formpart/formpart.component';
import { TagBoxComponent } from './tagbox/tagbox.component';
import { TextBoxComponent } from './textbox/textbox.component';
import { PhoneBoxComponent } from './phone-box/phone-box.component';
import { DateTimePickerComponent } from './date/datetime-picker/datetime-picker.component';
import { TimePickerDropDownComponent } from './date/time-picker/dropdown.component';
import { BrandModelSelectorComponent } from './brand-model-selector/brand-model-selector.component';
import { MaterialComponentsModule } from '../material-components.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        CommonDirectivesModule,
        CommonPipesModule,
        CommonSelectBoxComponentsModule,
        MaterialComponentsModule
    ],
    declarations: [
        NumberPickerComponent,
        DatePickerComponent,
        TimePickerComponent,
        TimePickerDropDownComponent,
        DateTimePickerComponent,
        FormPartComponent,
        TextBoxComponent,
        TagBoxComponent,
        PhoneBoxComponent,
        BrandModelSelectorComponent
    ],
    exports: [
        CommonSelectBoxComponentsModule,
        NumberPickerComponent,
        DatePickerComponent,
        TimePickerComponent,
        DateTimePickerComponent,
        FormPartComponent,
        TextBoxComponent,
        TagBoxComponent,
        PhoneBoxComponent,
        BrandModelSelectorComponent
    ]
})
export class CommonFormComponentsModule {}
