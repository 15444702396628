import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Organization } from 'common/types/organization';
import { Observable } from 'rxjs';

@Component({
    selector: 'organization-select',
    templateUrl: 'organization-select.component.html',
    styleUrls: ['organization-select.component.scss']
})
export class OrganizationSelectComponent {
    @Input() organization: Organization;
    @Input() recentOrganizations$: Observable<Organization>;
    @Input() superAdmin = false;
    @Input() view: string;

    @HostBinding('class.mobile')
    @Input() mobile = false;

    @Output() organizationSelected = new EventEmitter<Organization>();

    organizationComparator(o1: Organization, o2: Organization): boolean {
        return o1 != null && o2 != null && o1.id === o2.id;
    }
}
