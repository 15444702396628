import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'text-bubble',
    template: `{{letter}}`,
    styleUrls: ['./text-bubble.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextBubbleComponent {

    @Input() letter;

    @HostBinding('style.height.px')
    height = 40;
    @HostBinding('style.width.px')
    width = 40;
    @HostBinding('style.line-height.px')
    @HostBinding('style.font-size.px')
    fontSize = 20;
    @HostBinding('style.padding-top.px')
    paddingTop = 10;
    @HostBinding('style.border-radius.%')
    borderRadius = 100;
    @HostBinding('class.border')
    @Input() border = false;
    @HostBinding('style.color')
    @HostBinding('style.border-color')
    @Input() fontColor;
    @HostBinding('style.background-color')
    @Input() bgColor;
    @HostBinding('style.max-width.px')
    @Input()
    set maxWidth(width: number) {
        this.width = width;
        this.height = width;
        this.fontSize = Math.ceil(width / 2);
        this.paddingTop = Math.ceil(width / 4) - (this.border ? 2 : 0) + (this.width <= 30 ? 1 : 0);
    }
}
