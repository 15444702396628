import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

declare var window;

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm.dialog.html',
    styleUrls: ['./confirm.dialog.scss']
})
export class ConfirmDialog implements OnInit, OnDestroy {
    private goBack = false;
    private skipPop = false;

    mobile = window.innerWidth < 700;

    constructor(private dialogRef: MatDialogRef<ConfirmDialog>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private router: Router,
                private location: Location) {
    }

    ngOnInit() {
        window.history.pushState(null, 'ConfirmDialog', this.router.url);
    }

    @HostListener('window:resize')
    onResize(): void {
        this.mobile = window.innerWidth < 700;
    }

    close(button: string) {
        this.goBack = true;
        this.dialogRef.close(button);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event): void {
        if (!this.skipPop && !this.data.disableClose && this.isTopDialog()) {
            this.dialogRef.close('CANCEL');
        }
    }

    private isTopDialog() {
        return this.data.dialogs.length <= 1
            || this.data.dialogs[this.data.dialogs.length - 1].id === this.dialogRef.id;
    }

    ngOnDestroy() {
        this.skipPop = true;
        if (this.goBack) {
            this.location.back();
        }
    }
}
