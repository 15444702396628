import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Settings, Color } from 'common/types/types';
import { BaseComponent } from 'common/base.component';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { IconOrBubbleDetails } from '../../interfaces/icon-or-bubble.interface';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'icon-or-bubble',
    styleUrls: ['icon-or-bubble.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <img *ngIf="showImage"
            [src]="imageSource"
            [style.max-width.px]="maxWidth"
            [style.border-radius.%]="100"
        />
        <text-bubble *ngIf="showBubble"
             [letter]="name"
             [border]="border"
             [maxWidth]="maxWidth"
             [fontColor]="actionTextColor | color"
             [bgColor]="actionColor | color"
        ></text-bubble>
    `
})
export class IconOrBubbleComponent extends BaseComponent implements OnChanges {
    imageSource: string;
    showImage = false;
    showBubble = false;
    name: string;

    @Input() maxWidth = 40;
    @Input() border = false;
    @Input() noReset = false;
    @Input() actionTextColor: Color;
    @Input() actionColor: Color;
    @Input() details: IconOrBubbleDetails;

    constructor(private store: Store<ApplicationState>,
                private cd: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['details'] && this.details) {
            this.reset();
            this.loadDetails(this.details);
        }
    }

    private reset() {
        if (!this.noReset) {
            this.showBubble = false;
            this.showImage = false;
        }
    }

    private loadDetails(details: any) {
        if (details.lastName && details.firstName) {
            this.name = details.firstName.substring(0, 1) + this.getLastLetterOfLastName(details.lastName);
        } else {
            this.name = details.name ? details.name.substring(0, 1) : '';
        }
        this.showIconOrBubble(details);
    }

    private getLastLetterOfLastName(lastName: string): string {
        const lastNames = lastName.split(' ');
        return lastNames.length > 0 ? lastNames[lastNames.length - 1].substring(0, 1) : lastName.substring(0, 1);
    }

    private loadThemeColors() {
        if (this.details && this.details.actionColor && this.details.actionTextColor) {
            this.actionColor = this.details.actionColor;
            this.actionTextColor = this.details.actionTextColor;
        } else {
            this.store
                .pipe(
                    select((state) => state.settings),
                    takeUntil(this.destroyed$)
                )
                .subscribe((settings: Settings) => {
                    this.actionColor = settings.actionColor;
                    this.actionTextColor = settings.actionTextColor;
                });
        }
    }

    private showIconOrBubble(details: IconOrBubbleDetails) {
        if (details && details.icon && details.icon.length > 0) {
            this.checkImage(details.icon);
        } else {
            this.loadThemeColors();
            this.showImage = false;
            this.showBubble = true;
        }
    }

    private checkImage(imageSource: string): void {
        const img = new Image();
        img.onload = () => {
            this.showImage = true;
            this.showBubble = false;
            this.imageSource = imageSource;
            this.cd.markForCheck();
        };
        img.onerror = () => {
            this.loadThemeColors();
            this.showImage = false;
            this.showBubble = true;
            this.cd.markForCheck();
        };
        img.src = imageSource;
    }
}
