import { Injectable } from '@angular/core';
import { AuthHttp } from 'common/services/auth-http.service';
import { MemberService } from '../data/member.service';
import { Member } from 'common/types/types';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { AddMember, SetAllMembers, UpdateMember } from 'statemanagement/actions/data/members';
import { SetAllZones } from 'statemanagement/actions/data/zone';
import { Store, select } from '@ngrx/store';
import { Observable, merge, throwError, of } from 'rxjs';
import { filter, mergeMap, catchError, take, tap, share } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class MemberCacheService extends MemberService {
    private lastCacheMoment;

    constructor(protected http: AuthHttp, private store: Store<ApplicationState>) {
        super(http);
    }

    findAll(organizationId: string = null, forceReload = false): Observable<Member[]> {
        return organizationId == null
            ? this.findAllAndCache(forceReload)
            : super.findAll(organizationId);
    }

    private findAllAndCache(forceReload = false): Observable<Member[]> {
        const cachedMembers$ = this.store.pipe(select((state) => state.data.members));
        const loadNewMembers$ = cachedMembers$.pipe(
            take(1),
            filter((cachedMembers: Member[]) => forceReload || this.shouldReloadMember(cachedMembers)),
            mergeMap((cachedMembers: Member[]) => super.findAll()
                .pipe(
                    tap((members: Member[]) => this.store.dispatch(new SetAllMembers(members))),
                    tap(() => this.lastCacheMoment = moment()),
                    catchError((err) => cachedMembers == null ? throwError(err) : of(cachedMembers)),
                    share()
                )
            )
        );
        return merge(
            cachedMembers$.pipe(filter((members: Member[]) => members != null)),
            loadNewMembers$
        );
    }

    private shouldReloadMember(members: Member[]): boolean {
        return members == null
            || this.lastCacheMoment == null
            || this.lastCacheMoment.isBefore(moment().subtract(5, 'minutes'));
    }

    create(member: Member, organizationId = null): Observable<string> {
        return super.create(member, organizationId)
            .pipe(tap((memberId: string) => !organizationId
                ? this.store.dispatch(new AddMember(memberId, member))
                : null));
    }

    update(memberId: string, organizationId: string = null, oldMemberDetails: Member, newMemberDetails: Member): Observable<Member> {
       return super.update(memberId, organizationId, oldMemberDetails, newMemberDetails)
            .pipe(tap((member: Member) => member && !organizationId
                ? this.store.dispatch(new UpdateMember(memberId, newMemberDetails))
                : null));
    }

    deactivate(memberId: string, organizationId: string = null): Observable<void> {
        return super.deactivate(memberId, organizationId)
            .pipe(
                tap(() => this.store.dispatch(new UpdateMember(memberId, {status: 'INACTIVE', role: null} as Member))),
                tap(() => this.store.dispatch(new SetAllZones(null)))
            );
    }

    activate(memberId: string, organizationId: string = null): Observable<void> {
        return super.activate(memberId, organizationId)
            .pipe(
                tap(() => this.store.dispatch(new UpdateMember(memberId, {status: 'ACTIVE'} as Member))),
                tap(() => this.store.dispatch(new SetAllZones(null)))
            );
    }
}
