import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconOrBubbleComponent } from './components/icon-or-bubble/icon-or-bubble.component';
import { TextBubbleComponent } from './components/text-bubble/text-bubble.component';
import { CommonPipesModule } from 'common/pipes';
import { IconOrBubbleDetails } from './interfaces/icon-or-bubble.interface';

@NgModule({
    imports: [
        CommonModule,
        CommonPipesModule
    ],
    declarations: [
        IconOrBubbleComponent,
        TextBubbleComponent
    ],
    exports: [
        IconOrBubbleComponent,
        TextBubbleComponent
    ]
})
export class IconOrBubbleModule {}

export { IconOrBubbleDetails } from './interfaces/icon-or-bubble.interface';
