import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ConfigService {

    private static config: any;
    static configLoaded$ = new ReplaySubject(1);

    constructor(private http: HttpClient) {}

    static get(key: string): any {
        return ConfigService.config
            && ConfigService.config[key];
    }

    load() {
       return new Promise((resolve, reject) => {
            this.http.get('./assets/env.json')
                .subscribe(
                    (config) => {
                        ConfigService.config = config;
                        ConfigService.configLoaded$.next(true);
                        resolve(config);
                    },
                    () => {
                        reject();
                    }
                );
        });
    }
}
