import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonDirectivesModule } from '../../../directives';
import { CommonPipesModule } from '../../../pipes';

import { SelectBoxComponent } from './select-box/select-box.component';
import { SearchSelectBoxComponent } from './search-select-box/search-select-box.component';
import { SelectZoneComponent } from './select-zone/select-zone.component';
import { SelectStageComponent } from './select-stage/select-stage.component';
import { SelectActivityComponent } from './select-activity/select-activity.component';
import { SelectColorComponent } from './select-color/select-color.component';
import { SelectMemberComponent } from './select-member/select-member.component';
import { SelectSubcontractorComponent } from './select-subcontractor/select-subcontractor.component';
import { MaterialComponentsModule } from '../../material-components.module';
import { SelectOrganizationMemberComponent } from './select-org-member/select-org-member.component';
import { IconOrBubbleModule } from 'shared/icon-or-bubble';
import { SelectSupplierComponent } from './select-supplier/select-supplier.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        CommonDirectivesModule,
        CommonPipesModule,
        MaterialComponentsModule,
        IconOrBubbleModule
    ],
    declarations: [
        SelectBoxComponent,
        SearchSelectBoxComponent,
        SelectZoneComponent,
        SelectStageComponent,
        SelectActivityComponent,
        SelectColorComponent,
        SelectMemberComponent,
        SelectSubcontractorComponent,
        SelectOrganizationMemberComponent,
        SelectSupplierComponent
    ],
    exports: [
        SelectBoxComponent,
        SearchSelectBoxComponent,
        SelectZoneComponent,
        SelectStageComponent,
        SelectActivityComponent,
        SelectColorComponent,
        SelectMemberComponent,
        SelectSubcontractorComponent,
        SelectOrganizationMemberComponent,
        SelectSupplierComponent
    ]
})
export class CommonSelectBoxComponentsModule {}
