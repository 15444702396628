import * as subcontractor from '../../actions/data/subcontractor';
import { Subcontractor } from 'common/types/types';
import { sortBy } from 'lodash-es';

export function subcontractorsReducer(state: Subcontractor[] = null, action: subcontractor.Actions): Subcontractor[] {
    switch (action.type) {
        case subcontractor.ActionTypes.SUBCONTRACTORS_SET_ALL:
            return action.payload.subcontractors && sortSubcontractors([...action.payload.subcontractors]);
        case subcontractor.ActionTypes.SUBCONTRACTORS_ADD:
            return  state ? sortSubcontractors([...state, action.payload.subcontractor ]) : [action.payload.subcontractor];
        case subcontractor.ActionTypes.SUBCONTRACTORS_UPDATE_ONE:
            const updatedSubcontractors = state.map((s: Subcontractor) => s.id === action.payload.id
                ? Object.assign({id: action.payload.id}, action.payload.subcontractor)
                : s);
            return sortSubcontractors(updatedSubcontractors);
        default:
            return state;
    }
}

function sortSubcontractors(subcontractors: Subcontractor[]) {
    return subcontractors ? sortBy(subcontractors, [(s) => ('' + s.name).toLowerCase()]) : subcontractors;
}
