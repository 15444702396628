import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Organization } from '../types/types';
import { AuthenticationResult } from '../../user/types/AuthenticationResult';
import { Observable, of } from 'rxjs';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../base.component';
import { filter, map, mergeMap, take } from 'rxjs/operators';

@Injectable()
export class OrganizationSelectedGuard extends BaseComponent implements CanActivate {

    constructor(private router: Router,
                private store: Store<ApplicationState>) {
        super();
    }

    canActivate(): Observable<boolean> {
        return this.store.select((state) => state.settings.ready)
            .pipe(
                filter((ready) => ready),
                mergeMap(() => this.store.select((state) => state.settings.activeOrganization)),
                mergeMap((organization: Organization) => {
                    if (organization) {
                        return of(true);
                    } else {
                        return this.store.select((state) => state.data.authentication)
                            .pipe(
                                take(1),
                                map((authRes: AuthenticationResult) => {
                                    const account = authRes ? authRes.account : null;
                                    const isSuperAdmin = account && account.authorities
                                        && account.authorities.indexOf('ROLE_SUPER_ADMIN') !== -1;
                                    this.router.navigate([isSuperAdmin ? '/organizations' : '/user/auth']);
                                    return false;
                                })
                            );
                    }})
            );
    }
}
