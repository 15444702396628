export function isLoginView(url: string): boolean {
    return url !== '/' && url.includes('/user/', 0);
}

export function isSuperAdminView(url: string): boolean {
    return url === '/'
        || url.includes('/organizations', 0) && url !== '/organizations/edit' && url !== '/organizations/settings';
}

export function isOrganizationView(url: string, sector: string): boolean {
    return url !== '/'
        && !url.includes('/subcontractor/', 0)
        && url.includes('/organization/', 0)
        && url !== '/organization/projects/edit'
        && (sector === 'EVENTS' || !url.includes('/organization/members', 0))
        || url === '/organizations/edit';
}

export function isProjectView(url: string, sector: string): boolean {
    return url !== '/'
        && (url.includes('/project/', 0) || sector === 'INDUSTRY' && url.includes('/organization/members', 0))
        || url === '/organizations/settings'
        || url === '/organization/projects/edit';
}

export function isSubcontractorOrganizationView(url: string): boolean {
    return url !== '/' &&
        (url === '/subcontractor'
            || url === '/subcontractor/projects'
            || url.includes('/subcontractor/members', 0)
            || url.includes('/subcontractor/members/edit/', 0)
            || url.includes('/subcontractor/organization/edit', 0));
}

export function isSubcontractorProjectView(url: string): boolean {
    return url !== '/' &&
        (url.includes('/subcontractor/project/', 0)
            || url.includes('/subcontractor/organization/settings', 0));
}

export function isProjectLeaderView(url: string): boolean {
    return url !== '/' &&
        url.includes('/project-leader', 0);
}
