<h1 [routerLink]="view === 'project' ? ['/organization/projects'] : ['/subcontractor/projects']" class="pointer" *ngIf="!superAdmin">{{ organization?.name }}</h1>

<ng-container *ngIf="superAdmin">
    <div class="organization-dropdown" *ngVar="recentOrganizations$ | async; let recentOrganizations" (click)="organizationSelect.open()">
        <mat-select [value]="organization" (selectionChange)="organizationSelected.emit($event)" [compareWith]="organizationComparator" #organizationSelect>
            <mat-option *ngFor="let organization of recentOrganizations" [value]="organization" class="has-icon">
                <icon-or-bubble
                    [details]="organization"
                    maxWidth="20"
                ></icon-or-bubble>
                <label>{{organization.name}}</label>
            </mat-option>
            <mat-option value="all" class="all">{{ 'LABELS.VIEW_ALL_ORGANIZATIONS' | translate }}</mat-option>
        </mat-select>
        <h1>{{organization.name}}</h1>
    </div>
</ng-container>
