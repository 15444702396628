import * as zone from '../../actions/data/zone';
import { Zone } from 'common/types/types';
import { cloneDeep } from 'lodash-es';
import { localeOrderBy } from 'common/utils/utils';

export function zonesReducer(state: Zone[] = null, action: zone.Actions): Zone[] {
    switch (action.type) {
        case zone.ActionTypes.ZONES_SET_ALL:
            return action.payload.zones && sortZones([...action.payload.zones]);
        case zone.ActionTypes.ZONES_ADD:
            return  state ? sortZones([...state, action.payload.zone ]) : [action.payload.zone];
        case zone.ActionTypes.ZONES_UPDATE_LIST:
            let updatedZoneList = state;
            action.payload.zones.forEach((newZone: Zone) => {
                if (updatedZoneList.find((z: Zone) => z.id === newZone.id) != null) {
                    updatedZoneList = updatedZoneList.map((z: Zone) => z.id === newZone.id ? cloneDeep(newZone) : z);
                } else {
                    updatedZoneList.push(cloneDeep(newZone));
                }
            });
            return sortZones(updatedZoneList);
        case zone.ActionTypes.ZONES_UPDATE_ONE:
            if (state && state.find((z: Zone) => z.id === action.payload.id)) {
                const updatedZones = state && state.map((z: Zone) => z.id === action.payload.id
                    ? Object.assign({id: action.payload.id}, action.payload.zone)
                    : z);
                return sortZones(updatedZones);
            } else {
                return  state ? sortZones([...state, action.payload.zone ]) : [action.payload.zone];
            }
        default:
            return state;
    }
}

function sortZones(zones: Zone[]) {
    return zones ? localeOrderBy(zones, 'name', 'asc') : zones;
}
