import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Project } from 'common/types/project';
import { Observable } from 'rxjs';

@Component({
    selector: 'project-select',
    templateUrl: 'project-select.component.html',
    styleUrls: ['project-select.component.scss']
})
export class ProjectSelectComponent {
    @Input() project: Project;
    @Input() recentProjects$: Observable<Project>;

    @HostBinding('class.mobile')
    @Input() mobile = false;

    @Output() projectSelected = new EventEmitter<Project>();

    projectComparator(p1: Project, p2: Project): boolean {
        return p1 != null && p2 != null && p1.id === p2.id;
    }
}
