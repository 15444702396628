<div class="header">
    <ng-container *ngIf="(userOrganization$ | async) as organization">
        <icon-or-bubble
            [details]="organization"
            [maxWidth]="collapsed || mobile ? 32 : 100"
            [border]="true"
        ></icon-or-bubble>
        <h1>{{ organization?.name }}</h1>
    </ng-container>
</div>

<ul class="scroll">
    <li *ngFor="let item of menuItems"
        [class.active]="item.active"
        [matTooltip]="item.label | translate"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate([item.routerLink])"
    ><span class="icon"><i [class]="item.icon"></i></span><span class="text">{{ item.label | translate }}</span></li>
</ul>

<ul class="fixed" *ngIf="(settings$ | async) as settings">
    <li *ngIf="account"
        class="account"
        [class.active]="isActive('/profile')"
        [matTooltip]="account.firstName + ' ' + account.lastName"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate(['/profile'])"
    ><span class="icon"><icon-or-bubble
        [details]="account"
        [maxWidth]="30"
    ></icon-or-bubble></span><span class="text">{{ account.firstName }} {{ account.lastName }}</span></li>
    <li  [matTooltip]="'LABELS.FEEDBACK' | translate"
         [matTooltipDisabled]="!collapsed"
         matTooltipPosition="right"
         matTooltipClass="tooltip-sidenav"
         (click)="clickFeedback()">
        <span class="icon"><i class="fa fa-comments-o"></i></span><span class="text">{{ 'LABELS.HELP_FEEDBACK' | translate }}</span>
    </li>
</ul>

<i class="toggle-collapse fa fa-angle-double-left" (click)="emitToggleSideNav()" *ngIf="!mobile"></i>
