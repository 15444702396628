import { Pipe, PipeTransform } from '@angular/core';
import { Color } from '../types/color';

@Pipe({ name: 'color' })
export class ColorPipe implements PipeTransform {
    transform(color: Color, alpha = true): string {
        if (color && alpha) {
            return 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + (color.a ? color.a : 1) + ')';
        } else if (color) {
            return 'rgb(' + color.r + ',' + color.g + ',' + color.b  + ')';
        } else {
            return null;
        }
    }
}
