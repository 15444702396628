import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Ms } from 'common/microservices.enum';
import { Observable, of } from 'rxjs';
import { Project } from 'common/types/types';
import { AuthHttp } from 'common/services/auth-http.service';
import { cloneAndRemoveNulls } from 'common/utils/utils';
import { map } from 'rxjs/operators';
import * as jsonpatch from 'fast-json-patch';

@Injectable({providedIn: 'root'})
export class ProjectService {

    constructor(private http: AuthHttp) {}

    findAll(type?: 'ORGANIZATION' | 'SUBCONTRACTOR'): Observable<Project[]> {
        const headers = new HttpHeaders({
            Accept: 'application/vnd.tvh.projects.v1+json'
        });
        const path = type === 'SUBCONTRACTOR' ? '/subcontractor/projects' : '/projects';
        return this.http.get(Ms.MyAssetPlanner, path, {headers}, type === 'SUBCONTRACTOR' ? undefined : 'active')
            .pipe(map((res: any) => res && res.projects ? res.projects : []));
    }

    findOne(id: string): Observable<Project> {
        const headers = new HttpHeaders({
            'Accept': 'application/vnd.tvh.projectinfo.v1+json'
        });
        return this.http.get(Ms.MyAssetPlanner, '/projects/' + id, {headers}, 'active');
    }

    create(project: Project, organizationId?: string): Observable<string> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.projects.v1+json',
        });
        const body = cloneAndRemoveNulls(project);
        const path = organizationId != null ? ('/organizations/' + organizationId + '/projects') : '/projects';
        return this.http.post(Ms.MyAssetPlanner, path, body, {headers, observe: 'response'}, organizationId != null ? undefined : 'active')
            .pipe(map((res) => {
                const location = res.headers.get('Location');
                return location && location.substring(location.lastIndexOf('/') + 1);
            }));
    }

    update(projectId: string, oldDetails: Project, newDetails: Project): Observable<Project> {
        const operations = jsonpatch.compare(cloneAndRemoveNulls(oldDetails), cloneAndRemoveNulls(newDetails));
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.project.v1+json'
        });
        return operations.length > 0
            ? this.http.patch(Ms.MyAssetPlanner, '/projects/' + projectId, operations, {headers}, 'active')
            : of(false);
    }

    close(id: string): Observable<void> {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/vnd.tvh.project.v1+json');
        headers.set('Content-Type', 'application/vnd.tvh.project.v1+json');
        return this.http.post(Ms.MyAssetPlanner, '/projects/' + id + '/close', {}, {headers}, 'active');
    }

    reopen(id: string): Observable<void> {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/vnd.tvh.project.v1+json');
        headers.set('Content-Type', 'application/vnd.tvh.project.v1+json');
        return this.http.post(Ms.MyAssetPlanner, '/projects/' + id + '/reopen', {}, {headers}, 'active');
    }

    duplicate(project: Project, id: string): Observable<void> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/vnd.tvh.projects.v1+json',
        });
        return this.http.post(Ms.MyAssetPlanner, '/projects/' + id + '/duplicate', project, {headers}, 'active');
    }
}
