import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Organization } from '../types/types';
import { Observable } from 'rxjs';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class UserOrganizationSelectedGuard implements CanActivate {

    constructor(private router: Router, private store: Store<ApplicationState>) {}

    canActivate(): Observable<boolean> {
        return this.store.select((state) => state.settings.ready)
            .pipe(
                filter((ready) => ready),
                mergeMap(() => this.store.select((state) => state.settings.userOrganization)),
                map((organization: Organization) => {
                    if (organization) {
                        return true;
                    } else {
                        this.router.navigate(['/user/auth']);
                        return false;
                    }
                })
            );
    }
}
