import { APP_INITIALIZER } from '@angular/core';
import { ConfigService } from './config.service';

export function ConfigFactory(config: ConfigService) {
    return () => config.load();
}

export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [ConfigService],
        multi: true
    };
}

export const ConfigInitializer = {
    init
};
