import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { InjectionToken } from '@angular/core';

import { ApplicationState } from './state/ApplicationState';
import { authenticationReducer } from './reducers/data/authentication.reducer';
import { planningReducer } from './reducers/data/planning.reducer';
import { projectsReducer } from './reducers/data/projects.reducer';
import { settingsReducer } from './reducers/settings.reducer';
import { assetCategoriesReducer } from './reducers/data/assetcategories.reducer';
import { suppliersReducer } from './reducers/data/suppliers.reducer';
import { zonesReducer } from './reducers/data/zones.reducer';
import { activitiesReducer } from './reducers/data/activities.reducer';
import { membersReducer } from './reducers/data/members.reducer';
import { subcontractorsReducer } from './reducers/data/subcontractors.reducer';
import { sideNavReducer } from './reducers/sidenav.reducer';

const dataReducers = combineReducers({
    authentication: authenticationReducer,
    planning: planningReducer,
    projects: projectsReducer,
    assetCategories: assetCategoriesReducer,
    suppliers: suppliersReducer,
    zones: zonesReducer,
    activities: activitiesReducer,
    members: membersReducer,
    subcontractors: subcontractorsReducer
});

export const rootReducer = new InjectionToken<ActionReducerMap<ApplicationState>>('Reducers');

export function getReducers() {
    return {
        settings: settingsReducer,
        sideNav: sideNavReducer,
        data: dataReducers
    };
}
