<div class="header" *ngVar="isSuperAdmin() as superAdmin">
    <ng-container *ngIf="view === 'superadmin'">
        <div class="logo-tvh" [class.collapsed]="collapsed">
            <i class="ic ic-tvh-logo"></i>
        </div>
        <h1>MyAssetPlanner</h1>
    </ng-container>
    <ng-container *ngIf="(userOrganization$ | async) as organization">
        <ng-container *ngIf="view === 'organization' || view === 'project' && sector === 'INDUSTRY'">
            <icon-or-bubble
                [details]="organization"
                [maxWidth]="collapsed || mobile ? 32 : 100"
                [border]="true"
                (click)="superAdmin ? navigate(['/organizations']) : null"
                [style.cursor]="superAdmin ? 'pointer' : null"
            ></icon-or-bubble>
            <organization-select
                [organization]="organization"
                [superAdmin]="superAdmin"
                [mobile]="mobile"
                [recentOrganizations$]="recentOrganizations$"
                (organizationSelected)="organizationSelectionChange($event)"
            ></organization-select>
        </ng-container>
        <ng-container *ngIf="view === 'project' && sector === 'EVENTS'">
            <ng-container *ngVar="(currentProject$ | async) as project">
                <icon-or-bubble
                    [details]="project"
                    [border]="true"
                    [maxWidth]="collapsed || mobile ? 32 : 100"
                    (click)="navigate(['/organization/projects'])"
                    style="cursor: pointer"
                ></icon-or-bubble>

                <organization-select
                    *ngIf="!mobile"
                    [organization]="organization"
                    [superAdmin]="superAdmin"
                    [recentOrganizations$]="recentOrganizations$"
                    [view]="view"
                    (organizationSelected)="organizationSelectionChange($event)"
                ></organization-select>

                <project-select
                    [project]="project"
                    [mobile]="mobile"
                    [recentProjects$]="recentList$"
                    (projectSelected)="projectSelectionChange($event)"
                ></project-select>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ul class="scroll">
    <li *ngFor="let item of menuItems"
        [class.active]="item.active"
        [matTooltip]="item.label | areaType | translate"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate([item.routerLink])"
    >
        <span class="icon">
            <i [class]="item.icon"></i>
        </span>
        <span class="text">
            {{ item.label | areaType | translate }}
        </span>
        <ng-container *ngIf="(item.count$ | async) as count">
            <a class="round action primary no-hover" *ngIf="count > 0">
                <p>{{ count }}</p>
            </a>
        </ng-container>
    </li>
</ul>

<ul class="fixed" *ngIf="(settings$ | async) as settings">
    <li *ngIf="view === 'project' && sector === 'INDUSTRY' || view === 'organization'"
        [class.active]="isActive('/organizations/edit') || isActive('/organizations/settings')"
        [matTooltip]="'LABELS.ORGANIZATION_SETTINGS' | translate"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate([view === 'organization' ? '/organizations/edit' : '/organizations/settings'])"
    >
        <span class="icon"><i class="fa fa-cog"></i></span><span class="text">{{ 'LABELS.ORGANIZATION_SETTINGS' | translate }}</span>
    </li>
    <li
        class="settings"
        *ngIf="view === 'project' && sector === 'EVENTS'"
        [class.active]="isActive('/organizations/settings') || isActive('/organization/projects/edit')"
        (mouseenter)="hoveredSettings = true"
        (mouseleave)="hoveredSettings = false"
    >
        <div (click)="toggleSettings()"><span class="icon"><i class="fa fa-cog"></i></span><span class="text">{{ 'LABELS.SETTINGS' | translate }}</span></div>
        <div class="settings-menu" [class.show]="showSettings" [class.hovered]="hoveredSettings" (click)="hoveredSettings = false">
            <ul>
                <li (click)="navigate(['/organizations/settings'])" [class.active]="isActive('/organizations/settings')"><span class="text">{{ 'LABELS.ORGANIZATION_SETTINGS' | translate }}</span></li>
                <li (click)="navigate(['/organization/projects/edit'])" [class.active]="isActive('/organization/projects/edit')"><span class="text">{{ 'LABELS.PROJECT_SETTINGS' | translate }}</span></li>
            </ul>
        </div>
    </li>
    <li *ngIf="account"
        class="account"
        [class.active]="isActive('/profile')"
        [matTooltip]="account.firstName + ' ' + account.lastName"
        [matTooltipDisabled]="!collapsed"
        matTooltipPosition="right"
        matTooltipClass="tooltip-sidenav"
        (click)="navigate(['/profile'])"
    ><span class="icon"><icon-or-bubble
        [details]="account"
        [maxWidth]="30"
    ></icon-or-bubble></span><span class="text">{{ account.firstName }} {{ account.lastName }}</span></li>
    <li  [matTooltip]="'LABELS.FEEDBACK' | translate"
         [matTooltipDisabled]="!collapsed"
         matTooltipPosition="right"
         matTooltipClass="tooltip-sidenav"
         (click)="clickFeedback()">
        <span class="icon"><i class="fa fa-comments-o"></i></span><span class="text">{{ 'LABELS.HELP_FEEDBACK' | translate }}</span>
    </li>
</ul>

<i class="toggle-collapse fa fa-angle-double-left" (click)="emitToggleSideNav()" *ngIf="!mobile"></i>
