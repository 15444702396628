import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

@Injectable()
export class MyDateAdapter extends MomentDateAdapter {

    getFirstDayOfWeek(): number {
        return 1;
    }

}
