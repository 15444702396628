import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Project, ProjectFeatures } from '../types/types';
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Pipe({ name: 'areaType', pure: false })
export class AreaTypePipe implements PipeTransform {
    private key: string;
    private originalKey: string;

    constructor(private store: Store<ApplicationState>) {}

    transform(value: string): string {
        if (this.originalKey !== value) {
            this.originalKey = value;
            this.store
                .pipe(
                    select((state) => state.settings.activeProject),
                    map((project: Project) => project ? project.features : null),
                    take(1)
                )
                .subscribe((features: ProjectFeatures) => {
                    if (features != null && features.sites) {
                        this.key = value.replace('.AREA_TYPE.', '.SITES.');
                    } else if (features != null && features.docks) {
                        this.key = value.replace('.AREA_TYPE.', '.DOCKS.');
                    } else {
                        this.key = value.replace('.AREA_TYPE.', '.ZONES.');
                    }
                });
        }
        return this.key;
    }
}
