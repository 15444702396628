import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonLogicModule } from 'common/index';
import { FeedbackDialog } from './dialog/feedback.dialog';

@NgModule({
    imports: [
        CommonModule,
        CommonLogicModule
    ],
    declarations: [
        FeedbackDialog
    ],
    exports: [
        FeedbackDialog
    ],
    providers: []
})
export class FeedbackDialogModule {}
