import { SettingsState } from '../state/SettingsState';
import * as settings from '../actions/settings';

const initialState: SettingsState = {
    userOrganization: null,
    activeOrganization: null,
    activeProject: null,
    ready: false,
    browserSupported: true
};

export function settingsReducer(state: SettingsState = initialState,
                                   action: settings.Actions): SettingsState {
    switch (action.type) {
        case settings.ActionTypes.SETTINGS_UPDATE:
            return Object.assign({}, state, action.payload);
        case settings.ActionTypes.SET_APP_READY:
            return Object.assign({}, state, {ready: true});
        case settings.ActionTypes.SET_BROWSER_SUPPORTED:
            return Object.assign({}, state, {browserSupported: action.payload});
        default:
            return state;
    }
}
