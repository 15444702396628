import {type} from '../util/util';
import {Action} from '@ngrx/store';
import { Organization, Project } from 'common/types/types';

export const ActionTypes = {
    SETTINGS_UPDATE: type<'CONTAINER_SETTINGS_UDPATE'>('CONTAINER_SETTINGS_UDPATE'),
    SET_APP_READY: type<'CONTAINER_SET_APP_READY'>('CONTAINER_SET_APP_READY'),
    SET_BROWSER_SUPPORTED: type<'CONTAINER_SET_BROWSER_SUPPORTED'>('CONTAINER_SET_BROWSER_SUPPORTED')
};

export class UpdateSettings implements Action {
    type = ActionTypes.SETTINGS_UPDATE;
    payload: {
        organization?: Organization;
        project?: Project;
    };

    public constructor(settings: any) {
        this.payload = settings;
    }
}

export class SetAppReady implements Action {
    type = ActionTypes.SET_APP_READY;
}

export class SetBrowserSupported implements Action {
    type = ActionTypes.SET_BROWSER_SUPPORTED;
    payload: boolean;

    public constructor(browserSupported: boolean) {
        this.payload = browserSupported;
    }
}

export type Actions
    = UpdateSettings
    | SetAppReady
    | SetBrowserSupported;
