import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationResult } from '../../user/types/AuthenticationResult';
import { Observable } from 'rxjs';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { select, Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private router: Router,
                private store: Store<ApplicationState>) {
    }

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
        const roles = route.data['roles'] as string[];

        return this.store.pipe(
                select((state) => state.settings.ready),
                filter((ready) => ready),
                mergeMap(() => this.store.select((state) => state.data.authentication)
                    .pipe(map((authRes: AuthenticationResult) => {
                        const hasRole = authRes && authRes.account && authRes.account.authorities
                            && (authRes.account.authorities.indexOf('ROLE_SUPER_ADMIN') !== -1
                                || authRes.account.authorities.find((role: string) => roles.indexOf(role) !== -1));
                        if (hasRole) {
                            return true;
                        } else {
                            this.router.navigate(['']);
                            return false;
                        }
                    })))
            );
    }
}
