import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { Activity } from 'common/types/activity';

export const ActionTypes = {
    ACTIVITIES_SET_ALL: type<'DATA_ACTIVITIES_SET_ALL'>('DATA_ACTIVITIES_SET_ALL'),
};

export class SetAllActivities implements Action {
    type = ActionTypes.ACTIVITIES_SET_ALL;
    payload: { activities: Activity[] };

    constructor(activities: Activity[]) {
        this.payload = { activities };
    }
}

export type Actions =
    SetAllActivities;
