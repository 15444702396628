import * as planning from '../../actions/data/planning';
import { PlanningOrderLine, Request } from 'common/types/types';

export function planningReducer(state: PlanningOrderLine[] = null, action: planning.Actions): PlanningOrderLine[] {
    if (state != null || action.type === planning.ActionTypes.PLANNING_SET_ALL) {
        switch (action.type) {
            case planning.ActionTypes.PLANNING_SET_ALL:
                return action.payload.planningItems ? [...action.payload.planningItems] : null;
            case planning.ActionTypes.PLANNING_ADD:
                return [...state, action.payload.planningItem];
            case planning.ActionTypes.PLANNING_UPDATE:
                return state.map((planningItem: PlanningOrderLine) => planningItem.id === action.payload.id
                    ? Object.assign({}, action.payload.planningItem)
                    : planningItem
                );
            case planning.ActionTypes.PLANNING_UPDATE_REQUEST:
                return state.map((planningItem: PlanningOrderLine) => {
                    if (planningItem.id === action.payload.planningItemId && planningItem.bookings) {
                        const bookings = planningItem.bookings.map((request: Request) => request.id === action.payload.request.id ? Object
                            .assign({}, request, action.payload.request) : request);
                        return Object.assign({}, planningItem, {bookings: bookings});
                    } else {
                        return planningItem;
                    }
                });
            case planning.ActionTypes.PLANNING_DELETE:
                return state.filter((planningItem: PlanningOrderLine) => planningItem.id !== action.payload.id);
            case planning.ActionTypes.PLANNING_DELETE_REQUEST:
                return state.map((planningItem: PlanningOrderLine) => {
                    if (planningItem.id === action.payload.planningItemId && planningItem.bookings) {
                        const bookings = planningItem.bookings.filter((request: Request) => request.id !== action.payload.requestId);
                        return Object.assign({}, planningItem, {bookings: bookings});
                    } else {
                        return planningItem;
                    }
                });
        }
    }
    return state;
}
