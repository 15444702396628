import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Ms } from 'common/microservices.enum';
import { AuthHttp } from 'common/services/auth-http.service';
import { ApplicationState } from '../../statemanagement/state/ApplicationState';
import { Project } from '../../common/types/types';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, shareReplay, startWith } from 'rxjs/operators';

@Injectable()
export class OrderCountService {
    updateCount$ = new Subject<number>();
    orderCount$: Observable<number>;

    constructor(private http: AuthHttp, private store: Store<ApplicationState>) {
        const activeProject$ = this.store.pipe(
            select(((state) => state.settings.activeProject)),
            map((project: Project) => project && project.id),
            distinctUntilChanged()
        );

        this.orderCount$ = activeProject$
            .pipe(
                switchMap((id: string) => of(null, id)),
                switchMap((id: string) => this.updateCount$.pipe(startWith(null), map((count) => [id, count]))),
                switchMap(([id, count]: [string, number]) =>
                    id != null && count == null
                        ? this.findOrderCount()
                        : of(count != null ? count : 0)),
                shareReplay(1)
            );
    }

    private findOrderCount(): Observable<number> {
        const headers = new HttpHeaders({
            'Accept': 'application/vnd.tvh.shoppingcart.v1+json'
        });

        return this.http.get(Ms.MyAssetPlanner, '/shoppingcart/count', {headers}, 'active', true);
    }

    updateCount(count?: number): void {
        this.updateCount$.next(count);
    }
}
