import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../statemanagement/state/ApplicationState';
import { map, take } from 'rxjs/operators';

@Injectable()
export class BrowserSupportedGuard implements CanActivate {

    constructor(private router: Router,
                private store: Store<ApplicationState>) {}

    canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
        return this.store.select((state) => state.settings.browserSupported)
            .pipe(
                take(1),
                map((browserSupported: boolean) => {
                    if (browserSupported) {
                        return true;
                    } else {
                        this.router.navigate(['/browser-not-supported']);
                        return false;
                    }
                })
            );
    }
}
