import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformDirective } from './platform.directive';
import { DefaultCursorWhenProjectClosedDirective, HideWhenProjectClosedDirective } from './project/project-status.directive';
import { RepeatViewDirective } from './repeat-view.directive';
import { VarDirective } from './var.directive';
import { HasRoleDirective } from './has-role.directive';
import { HideWhenSectorDirective } from './hide-when-sector.directive';
import { HideWhenOpCoDirective } from './hide-when-opco.directive';
import { HideWhenProjectFeatureDirective } from './project/hide-when-project-feature.directive';
import { ShowWhenProjectFeatureDirective } from './project/show-when-project-feature.directive';
import { ShowWhenOpCoDirective } from './show-when-opco.directive';
import { UppercaseDirective } from 'common/directives/uppercase.directive';
import { ReadonlyDirective } from './readonly.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PlatformDirective,
        HideWhenProjectClosedDirective,
        DefaultCursorWhenProjectClosedDirective,
        HideWhenSectorDirective,
        HideWhenOpCoDirective,
        RepeatViewDirective,
        VarDirective,
        HasRoleDirective,
        HideWhenProjectFeatureDirective,
        ShowWhenProjectFeatureDirective,
        ShowWhenOpCoDirective,
        UppercaseDirective,
        ReadonlyDirective
    ],
    exports: [
        PlatformDirective,
        HideWhenProjectClosedDirective,
        DefaultCursorWhenProjectClosedDirective,
        HideWhenSectorDirective,
        HideWhenOpCoDirective,
        RepeatViewDirective,
        VarDirective,
        HasRoleDirective,
        HideWhenProjectFeatureDirective,
        ShowWhenProjectFeatureDirective,
        ShowWhenOpCoDirective,
        UppercaseDirective,
        ReadonlyDirective
    ]
})
export class CommonDirectivesModule {
}
