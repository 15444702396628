import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseSidenav, MenuItem } from '../base-sidenav/base-sidenav.component';
import { Storage } from 'common/services/storage';
import { TodoCountService } from '../../../services/todo-count.service';
import { Organization, ProjectFeatures } from 'common/types/types';
import { isOrganizationView, isProjectView, isSuperAdminView } from '../../../view.utils';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { gaSendEvent } from '../../../services/window.service';

@Component({
    selector: 'organization-sidenav',
    templateUrl: './organization-sidenav.component.html',
    styleUrls: ['../base-sidenav/base-sidenav.component.scss']
})
export class OrganizationSidenavComponent extends BaseSidenav {
    private superAdminMenu: MenuItem[] = [
        {icon: 'fa fa-building', label: 'LABELS.ORGANIZATIONS', routerLink: '/organizations'}
    ];
    private organizationMenuEvents: MenuItem[] = [
        {icon: 'fa fa-star', label: 'LABELS.PROJECTS', routerLink: '/organization/projects'},
        {icon: 'fa fa-users', label: 'LABELS.MEMBERS', routerLink: '/organization/members'},
        {icon: 'fa fa-address-book', label: 'LABELS.SUBCONTRACTORS', routerLink: '/organization/subcontractors'}
    ];
    private organizationMenuIndustry: MenuItem[] = [
        {icon: 'fa fa-star', label: 'LABELS.PROJECTS', routerLink: '/organization/projects'}
    ];
    private projectMenuEvents: MenuItem[] = [
        {icon: 'fa fa-calendar', label: 'LABELS.PLANNING', routerLink: '/project/planning'},
        {icon: 'fa fa-map-marker', label: 'LABELS.LOCATIONS', routerLink: '/project/locations'},
        {icon: 'fa fa-map-o', label: 'LABELS.AREA_TYPE.TITLE', routerLink: '/project/zones'}
    ];
    private projectMenuIndustry: MenuItem[] = [
        {icon: 'fa fa-calendar', label: 'LABELS.PLANNING', routerLink: '/project/planning'},
        {icon: 'fa fa-map-marker', label: 'LABELS.LOCATIONS', routerLink: '/project/locations'},
        {icon: 'fa fa-map-o', label: 'LABELS.AREA_TYPE.TITLE', routerLink: '/project/zones'},
        {icon: 'fa fa-users', label: 'LABELS.MEMBERS', routerLink: '/organization/members'}
    ];

    @Input() orderCount$: Observable<number>;

    constructor(protected router: Router,
                protected storage: Storage,
                private todoCountService: TodoCountService) {
        super(router, storage);
    }

    protected initMenuLoader() {
        const navigationEnds$ = this.router.events
            .pipe(
                startWith(new NavigationEnd(0, this.router.url, null)),
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects ? event.urlAfterRedirects : event.url)
            );

        combineLatest(
            navigationEnds$,
            this.currentOrganization$,
            this.projectFeatures$
        )
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([url, currentOrganization, projectFeatures]: [string, Organization, ProjectFeatures]) => {
                const sector = currentOrganization && currentOrganization.sector
                    ? currentOrganization.sector
                    : 'EVENTS';
                const opCo = currentOrganization && currentOrganization.opCo
                    ? currentOrganization.opCo
                    : null;
                this.sector = sector;
                this.setView(url, sector);
                this.setMenuItems(sector, opCo, projectFeatures);
                this.setActiveMenuItem(url);
                this.setTodoCount();
            });
    }

    protected setView(url: string, sector: string): void {
        if (url !== '/profile') {
            if (isSuperAdminView(url)) {
                this.view = 'superadmin';
            } else if (isOrganizationView(url, sector)) {
                this.view = 'organization';
            } else if (isProjectView(url, sector)) {
                this.view = 'project';
            } else {
                this.view = '';
            }
            this.storage.setItem('view', this.view);
        } else {
            this.view = this.storage.getItem('view');
        }
    }

    protected setMenuItems(sector: string, opCo: string, projectFeatures: ProjectFeatures): void {
        switch (this.view) {
            case 'superadmin':
                this.menuItems = [...this.superAdminMenu];
                break;
            case 'organization':
                this.menuItems = sector === 'INDUSTRY'
                    ? [...this.organizationMenuIndustry]
                    : [...this.organizationMenuEvents];
                break;
            case 'project':
                this.menuItems = sector === 'INDUSTRY'
                    ? [...this.projectMenuIndustry]
                    : [...this.projectMenuEvents];
                this.addOrderMenuItem(opCo);
                this.addReportingMenuItem(projectFeatures);
                break;
            default:
                this.menuItems = [];
                break;
        }
    }

    private addOrderMenuItem(opCo: string): void {
        if (opCo != null && opCo !== 'NA') {
            this.menuItems.push({
                icon: 'ic ic-tvh-logo',
                label: 'LABELS.TO_ORDER',
                routerLink: '/project/order',
                count$: this.orderCount$
            });
        }
    }

    private addReportingMenuItem(projectFeatures: ProjectFeatures): void {
        if (projectFeatures && projectFeatures.reporting) {
            this.menuItems.push({
                icon: 'fa fa-line-chart',
                label: 'LABELS.REPORTING',
                routerLink: '/project/reporting'
            });
        }
    }

    private setTodoCount() {
        const counts$ = this.menuItems.map((item) => item.count$);
        this.todoCountService.setCountObservables(counts$);
    }

    isSuperAdmin() {
        return this.hasRole(this.account, 'ROLE_SUPER_ADMIN');
    }

    projectSelectionChange(event) {
        this.emitCloseSideNav();
        if (event.value === 'all') {
            this.router.navigate(['/organization/projects']);
        } else {
            this.projectSelected.emit(event.value);
            this.router.navigate(['/project/planning']);
        }
        gaSendEvent('SideNav', 'switch-project');
    }

    organizationSelectionChange(event) {
        this.emitCloseSideNav();
        if (event.value === 'all') {
            this.router.navigate(['/organizations']);
        } else {
            this.organizationSelected.emit(event.value);
        }
    }

    toggleSettings() {
        this.showSettings = !this.collapsed && !this.showSettings;
    }
}
