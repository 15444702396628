import { SideNavState } from '../state/SideNavState';
import * as sideNav from '../actions/sidenav';

const initialState: SideNavState = {
    collapsed: false
};

export function sideNavReducer(state: SideNavState = initialState,
                                   action: sideNav.Actions): SideNavState {
    switch (action.type) {
        case sideNav.ActionTypes.SIDENAV_SET_COLLAPSED:
            return Object.assign({}, state, action.payload);
        case sideNav.ActionTypes.SIDENAV_TOGGLE_COLLAPSED:
            return Object.assign({}, state, {collapsed: !state.collapsed});
        default:
            return state;
    }
}
