import { Activity } from 'common/types/types';
import * as activity from '../../actions/data/activity';

export function activitiesReducer(state: Activity[] = null, action: activity.Actions): Activity[] {
    if (action.type === activity.ActionTypes.ACTIVITIES_SET_ALL) {
        return action.payload.activities && [...action.payload.activities];
    } else {
        return state;
    }
}
