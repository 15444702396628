<div class="project-dropdown" *ngVar="recentProjects$ | async; let recentProjects" (click)="projectSelect.open()">
    <mat-select [value]="project" (selectionChange)="projectSelected.emit($event)" [compareWith]="projectComparator" #projectSelect>
        <mat-option *ngFor="let project of recentProjects" [value]="project" class="has-icon">
            <icon-or-bubble
                [details]="project"
                maxWidth="20"
            ></icon-or-bubble>
            <label>{{project?.name}}</label>
        </mat-option>
        <mat-option value="all" class="all">{{ 'LABELS.VIEW_ALL_PROJECTS' | translate }}</mat-option>
    </mat-select>
    <h2>{{project?.name}}</h2>
</div>
