import { type } from '../../util/util';
import { Action } from '@ngrx/store';
import { Supplier } from 'common/types/supplier';

export const ActionTypes = {
    SUPPLIERS_SET_ALL: type<'DATA_SUPPLIERS_SET_ALL'>('DATA_SUPPLIERS_SET_ALL')
};

export class SetAllSuppliers implements Action {
    type = ActionTypes.SUPPLIERS_SET_ALL;
    payload: { suppliers: Supplier[] };

    constructor(suppliers: Supplier[]) {
        this.payload = {suppliers};
    }
}

export type Actions =
    SetAllSuppliers;
