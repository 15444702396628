import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MaintenanceService {
    constructor(private http: HttpClient) {}

    findMaintenanceModeEnabled(): Observable<boolean> {
        return this.http.get('/maintenance-mode')
            .pipe(map((res: any) => res && res.enabled));
    }
}
