import { Input, Output, EventEmitter, OnInit, HostBinding, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '../../../../user/types/Account';
import { Project, Organization, Settings, PlanningState, ProjectFeatures } from 'common/types/types';
import { Storage } from 'common/services/storage';
import { BaseComponent } from 'common/base.component';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { gaSendEvent } from '../../../services/window.service';

export interface MenuItem {
    icon: string;
    label: string;
    routerLink: string;
    active?: boolean;
    count$?: Observable<number>;
}

@Directive()
export abstract class BaseSidenav extends BaseComponent implements OnInit {
    view = '';
    menuItems: MenuItem[] = [];
    showSettings = false;
    hoveredSettings = false;

    sector = '';
    showProjectsMenu = false;

    userOrganization$: Observable<Organization>;
    currentOrganization$: Observable<Organization>;
    currentProject$: Observable<Project>;
    recentList$: Observable<Project[]>;

    @HostBinding('class.collapsed')
    @Input() collapsed = false;
    @HostBinding('class.mobile')
    @Input() mobile = false;
    @Input() account: Account;
    @Input() projects$: Observable<Project[]>;
    @Input() recentProjects$: Observable<PlanningState[]>;
    @Input() recentOrganizations$: Observable<Organization[]>;
    @Input() settings$: Observable<Settings>;
    @Input() projectFeatures$: Observable<ProjectFeatures>;

    @Output() projectSelected = new EventEmitter<Project>();
    @Output() organizationSelected = new EventEmitter<Organization>();
    @Output() toggleSideNav = new EventEmitter<void>();
    @Output() closeSideNav = new EventEmitter<void>();
    @Output() openFeedbackDialog = new EventEmitter<void>();

    constructor(protected router: Router,
                protected storage: Storage) {
        super();
    }

    ngOnInit() {
        this.userOrganization$ = this.settings$
            .pipe(map((settings: Settings) => settings ? settings.userOrganization : null));
        this.currentOrganization$ = this.settings$
            .pipe(
                map((settings: Settings) => settings ? settings.activeOrganization : null),
                distinctUntilChanged()
            );
        this.currentProject$ = this.settings$
            .pipe(map((settings: Settings) => settings ? settings.activeProject : null));
        const recentProjectIds$ = this.recentProjects$
            .pipe(map((projects) => projects.map((projectState) => projectState.id)));
        this.recentList$ = combineLatest(
            this.projects$,
            recentProjectIds$,
            (projects: Project[], recentProjectIds: string[]) =>
                recentProjectIds
                    .map((id: string) => projects.find((proj: Project) => proj.id === id))
                    .filter((proj: Project) => proj != null)
        ).pipe(debounceTime(500));
        this.initMenuLoader();
    }

    protected initMenuLoader() {
    }

    protected setActiveMenuItem(url: string) {
        this.menuItems.forEach((item: MenuItem) => Object.assign(item, {active: url.indexOf(item.routerLink) === 0}));
    }

    navigate(path) {
        this.router.navigate(path);
        this.emitCloseSideNav();
    }

    emitCloseSideNav() {
        if (this.mobile) {
            this.closeSideNav.emit();
        }
    }

    isActive(routerLink: string): boolean {
        return this.router.url.indexOf(routerLink) === 0;
    }

    protected hasRole(account: Account, role: string): boolean {
        return account && account.authorities && account.authorities.includes(role);
    }

    emitToggleSideNav() {
        gaSendEvent('SideNav', 'toggle', this.collapsed ? 'expand' : 'collapse');
        this.toggleSideNav.emit();
    }

    clickFeedback() {
        this.openFeedbackDialog.emit();
    }
}
