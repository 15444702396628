import * as members from '../../actions/data/members';
import { Member } from 'common/types/member';

export function membersReducer(state: Member[] = null, action: members.Actions): Member[] {
    switch (action.type) {
        case members.ActionTypes.MEMBERS_SET_ALL:
            return action.payload.members && sortMembers([...action.payload.members]);
        case members.ActionTypes.MEMBERS_ADD:
            return state && sortMembers([ ...state, {...action.payload.member, id: action.payload.id}]);
        case members.ActionTypes.MEMBERS_UPDATE:
            const updatedMembers = state && state
                .map((member) => member.id === action.payload.id ? Object.assign({}, member, action.payload.member) : member);
            return sortMembers(updatedMembers);
        default:
            return state;
    }
}

export function sortMembers(memberList: Member[]): Member[] {
    return memberList && memberList.sort((m1, m2) => {
        const name1 = m1.firstName + ' ' + m1.lastName;
        const name2 = m2.firstName + ' ' + m2.lastName;
        return name1.toLowerCase() === name2.toLowerCase() ? 0 : (name1.toLowerCase() < name2.toLowerCase() ? -1 : 1);
    });
}
