import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Platform } from 'common/services/platform';
import { RecentProjectsService } from 'common/services/recent-projects.service';
import { ApplicationState } from 'statemanagement/state/ApplicationState';
import { Account } from 'user/types/Account';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-root-container',
  template: ''
})
export class RootContainer  {
    constructor(private platform: Platform,
                private router: Router,
                private location: Location,
                private recentProjectsService: RecentProjectsService,
                private store: Store<ApplicationState>) {
        this.getNavigateToRoute()
            .pipe(take(1))
            .subscribe((route: string) => {
                this.router.navigate([route]);
                this.location.replaceState(route);
            });
    }

    private getNavigateToRoute(): Observable<string> {
        return this.store
            .select((state) => state.settings.ready)
            .pipe(
                filter((ready: boolean) => ready),
                mergeMap(() =>
                    this.store
                        .select((state) => state.data.authentication.account)
                        .pipe(
                            map((account: Account) => {
                                if (this.hasRole(account, ['ROLE_SUPER_ADMIN'])) {
                                    return '/organizations';
                                } else if (this.hasRole(account, ['ROLE_SUBCONTRACTOR_ADMIN', 'ROLE_SUBCONTRACTOR_USER'])) {
                                    return '/subcontractor';
                                } else if (this.hasRole(account, ['ROLE_PROJECT_LEADER'])) {
                                    return '/project-leader/requests';
                                } else if (this.recentProjectsService.getMostRecent()) {
                                    return '/project/planning';
                                } else {
                                    return 'organization/projects';
                                }
                            })
                        )
                )
            );
    }

    private hasRole(account: Account, roles: string[]): boolean {
        return account && account.authorities
            && account.authorities.find((role: string) => roles.indexOf(role) !== -1) != null;
    }
}
